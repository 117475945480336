import React, { useState, useEffect } from "react";
import MapLayer from "./map";
import RetrofitMapLayer from "./services/retrofit/map-layer";
import _ from "lodash";
// import Panels from "./panels";
import Retrofit from "./services/retrofit/retrofit";
import { panelsStore, controlStore, servicesStore } from "../../../store/store";
// import { dataSplitbyGeom, expandSublayers } from "../helpers";

export default function index({ setLayer }) {
  //------------------ LOCAL STATES ------------------
  const [retrofitLayer, setRetrofitLayer] = useState([]);
  const [eplusToolLayer, setEplusToolLayer] = useState([]);
  const [dataLayer, setDataLayer] = useState([]);

  //------------------ SHARED STATES ------------------
  const activeServices = panelsStore((state) => state.activeServices);
  const layersShown = controlStore((state) => state.layersShown);
 
  //------------------ FUNCTIONS ------------------

  //------------------ SIDE EFFECTS ------------------
  useEffect(() => {
    // update layers on any change
    let layers = { ...retrofitLayer, ...dataLayer, ...eplusToolLayer };
    setLayer(_.values(layers));
  }, [retrofitLayer, dataLayer, eplusToolLayer]);



  return (
    <>
      <MapLayer setLayer={setDataLayer} />
      {layersShown["services"] &&
        layersShown["built_buildingsretrofit"] &&
        activeServices["retrofitAnalysis"] && (
          <RetrofitMapLayer setLayer={setRetrofitLayer} />
        )}
    </>
  );
}

import { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { systemStore } from "../../../../store/store";
import { gql } from "@apollo/client";
import * as gqlBuilder from "gql-query-builder";
import axios from "axios";

export const useLoadByGQLQuery = (props) => {
  const { queryOptions } = props;
  const { query, variables } = gqlBuilder.query(queryOptions);
  const res = useQuery(gql(query));

  //------------------ LOCAL STATES ------------------
  const [data, setData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(false);

  const setIsLoading = systemStore((state) => state.setIsLoading);
  useEffect(() => {
    setIsLoading("main", true);
  }, []);

  //------------------ Effects ------------------
  if (res && !res.loading && res.data && !dataLoaded) {
    var dataObj = {};
    Object.keys(res.data).forEach((key) => {
      const val = res.data[key];
      dataObj[key] = {
        point: val
          .filter(
            (d) => d.geom.type === "Point" || d.geom.type === "MultiPoint"
          )
          .map((d) =>
            d.geom.type === "MultiPoint"
              ? {
                  ...d,
                  geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
                }
              : d
          ),
        line: val
          .filter(
            (d) =>
              d.geom.type === "LineString" || d.geom.type === "MultiLineString"
          )
          .map((d) =>
            d.geom.type === "MultiLineString"
              ? {
                  ...d,
                  geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
                }
              : d
          ),
        Polygon: val
          .filter(
            (d) => d.geom.type === "Polygon" || d.geom.type === "MultiPolygon"
          )
          .map((d) =>
            d.geom.type === "MultiPolygon"
              ? {
                  ...d,
                  geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
                }
              : d
          ),
      };
    });
    setData(dataObj);
    setDataLoaded(true);
    setIsLoading("main", false);
  }

  return { data: data };
};

export const useLoadByREST = (props) => {
  axios.defaults.baseURL = props.baseURL;

  //------------------ LOCAL STATES ------------------
  const [data, setData] = useState(undefined);
  const setIsLoading = systemStore((state) => state.setIsLoading);

  //------------------ FUNCTiONS ------------------

  const fetchData = useCallback(() => {
    var url = `/datastore_search?resource_id=${props.resource_id}`;
    url += props.limit ? `&limit=` + props.limit : `&limit=` + 9e10;
    console.log(url);
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.result);
        setIsLoading("main", false);
        setData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //------------------ EFFECTS ------------------

  useEffect(() => {
    setIsLoading("main", true);
    fetchData();
  }, []);

  useEffect(() => {
    console.log('bhbhj', data)
  }, [data]);

  return { data: data };
};

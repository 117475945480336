export const shader = {
  "vs:#decl": `\
      uniform float trailLength;
      uniform float currentTime;
      attribute vec3 instancePositionsNext;
      attribute vec3 instancePositionsNext64Low;
      attribute float instanceTimestamps;
      attribute float instanceNextTimestamps;
      varying float vTime;
      uniform bool billboard;
      `,
      // Timestamp of the vertex
  "vs:#main-end": `\
      vec3 tempPosition = vec3(mix(instancePositions, instancePositionsNext, currentTime));
      vec3 tempPosition64Low = vec3(mix(instancePositions64Low, instancePositionsNext64Low, currentTime));
      
      if (billboard) {
        gl_Position = project_position_to_clipspace(tempPosition, tempPosition64Low, vec3(0.0), geometry.position);
        vec3 offset = positions * outerRadiusPixels;
        DECKGL_FILTER_SIZE(offset, geometry);
        gl_Position.xy += project_pixel_size_to_clipspace(offset.xy);
      } else {
        vec3 offset = positions * project_pixel_size(outerRadiusPixels);
        DECKGL_FILTER_SIZE(offset, geometry);
        gl_Position = project_position_to_clipspace(tempPosition, tempPosition64Low, offset, geometry.position);
      }
      `,
  "vs:DECKGL_FILTER_SIZE": `
      if (isVertexPicked(geometry.pickingColor)) {
        size *= 1.3;
      }
`,
}
import {
  ApolloClient,
  split,
  HttpLink,
  ApolloLink,
  concat,
  InMemoryCache,
  DefaultOptions
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

const HASURA_SECRET = `${process.env.REACT_APP_HASURA_SECRET}`
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_HASURA_HTTP_ENDPOINT_URL}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-hasura-admin-secret": HASURA_SECRET,
    },
  }));
  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_HASURA_WS_ENDPOINT_URL}`,
  
  
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      // const token = await getToken();
      return {
        headers: {
          "x-hasura-admin-secret": HASURA_SECRET,
        },
      }
    },
  },

});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);



const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  subscription: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
}

export const Client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, splitLink),
  defaultOptions: defaultOptions,
});



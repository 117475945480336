import React, { useState } from "react";
import styles from "./metrics.module.less";
import { TreeSelect } from "antd";

const { TreeNode, SHOW_PARENT } = TreeSelect;

export default function metrics(props) {


  return (
    <div className={styles.wrapper}>
      <TreeSelect
        showSearch
        size='small'
        value={props.treeValue}
        placeholder='select metric'
        treeCheckable='true'
        showCheckedStrategy={SHOW_PARENT}
        treeLine={true}
        onChange={value=>props.setTreeValue(value)}
      >
        <TreeNode
          value='pol'
          title='Pollution'
          checkable={false}
          disableCheckbox
        >
          <TreeNode value='vemis_co2' title='CO2' />
          <TreeNode value='vemis_co' title='CO' />
          <TreeNode value='vemis_nox' title='NOX' />
          <TreeNode value='vemis_pm25' title='PM 2.5' />
        </TreeNode>
        <TreeNode value='ener' title='Energy' checkable={false} disableCheckbox>
          <TreeNode value='vemis_fuel' title='Fuel' />
        </TreeNode>
      </TreeSelect>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import styles from "./toolbox.module.less";
import { PathStyleExtension } from "@deck.gl/extensions";
import { Tooltip, Button, Space } from "antd";
import {
  BiPointer,
  BiMapPin,
  BiShapePolygon,
  BiShapeSquare,
  BiCircle,
  BiRuler,
  BiTrash,
  BiExport,
  BiImport,
  BiCheckCircle,
  BiXCircle,
} from "react-icons/bi";
import { MdOutlineAutoGraph } from "react-icons/md";
import {
  EditableGeoJsonLayer,
  SelectionLayer,
  ModifyMode,
  ResizeCircleMode,
  TranslateMode,
  TransformMode,
  ScaleMode,
  RotateMode,
  DuplicateMode,
  ExtendLineStringMode,
  SplitPolygonMode,
  ExtrudeMode,
  ElevationMode,
  DrawPointMode,
  DrawLineStringMode,
  DrawPolygonMode,
  DrawRectangleMode,
  DrawSquareMode,
  DrawRectangleFromCenterMode,
  DrawSquareFromCenterMode,
  DrawCircleByDiameterMode,
  DrawCircleFromCenterMode,
  DrawEllipseByBoundingBoxMode,
  DrawEllipseUsingThreePointsMode,
  DrawRectangleUsingThreePointsMode,
  Draw90DegreePolygonMode,
  DrawPolygonByDraggingMode,
  MeasureDistanceMode,
  MeasureAreaMode,
  MeasureAngleMode,
  ViewMode,
  CompositeMode,
  SnappableMode,
  ElevatedEditHandleLayer,
  PathMarkerLayer,
  SELECTION_TYPE,
  GeoJsonEditMode,
} from "nebula.gl";
import { toolboxStore } from "../../../store/store";

const UNSELECTED_FILL_COLOR = [255, 0, 0, 111];
const SELECTED_FILL_COLOR = [0, 0, 255, 33];
const UNSELECTED_LINE_COLOR = [255, 111, 111, 155];
const SELECTED_LINE_COLOR = [111, 111, 255, 155];

const initFeatures = {
  type: "FeatureCollection",
  features: [],
};

const MOUSEENTERDELAY = 0.3;
const MOUSELEAVEDELAY = 0.0;

export default function Toolbox({setter}) {
  //------------------ LOCAL STATES ------------------
  const [highlight, setHighlight] = useState(0);
  const [multiDraw, setMultiDraw] = useState(false);
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([]);
  const [features, setFeatures] = useState(initFeatures);
  const [mode, setMode] = useState(() => GeoJsonEditMode);
  const [modeConfig, setModeConfig] = useState({});

  //------------------ SHARED STATES ------------------
  // const setToolboxLayer = toolboxStore((state) => state.setToolboxLayer);
  const toolboxGeoJson = toolboxStore((state) => state.toolboxGeoJson);
  const setToolboxGeoJson = toolboxStore((state) => state.setToolboxGeoJson);
  const toolboxSelectApply = toolboxStore((state) => state.toolboxSelectApply);
  const setToolboxSelectApply = toolboxStore(
    (state) => state.setToolboxSelectApply
  );
  const toolboxShowGraph = toolboxStore((state) => state.toolboxShowGraph);
  const setToolboxShowGraph = toolboxStore(
    (state) => state.setToolboxShowGraph
  );
  const toolboxCalcGraph = toolboxStore((state) => state.toolboxCalcGraph);
  const setToolboxCalcGraph = toolboxStore(
    (state) => state.setToolboxCalcGraph
  );

  //------------------ FUNCTIONS ------------------
  const handleMultiSelect = useCallback((features, isMulti) => {
    if (!isMulti) {
      let numFeatures = features.features.length;
      if (numFeatures > 1) {
        return {
          type: "FeatureCollection",
          features: [features.features[numFeatures - 1]],
        };
      }
    }
    return features;
  }, []);

  //------------------ HOOKS ------------------

  //------------------ SIDE EFFECTS ------------------

  // useEffect(() => {
  //   let numFeatures = features.features.length;
  //   if (!multiDraw && numFeatures <= 1) {
  //     setToolboxGeoJson(features);
  //   }
  // }, [features, multiDraw]);

  useEffect(() => {
    if (toolboxSelectApply && features.features.length >= 1) {
      setToolboxGeoJson(features);
    } else {
      setToolboxGeoJson(initFeatures);
    }
  }, [toolboxSelectApply]);

  useEffect(() => {
    // to remove last drawn shape if user draws a new one
    setFeatures(handleMultiSelect(features, multiDraw));
    console.log(features)
    console.log(mode)
    console.log(selectedFeatureIndexes)
    // setToolboxGeoJson(features);
    const newLayer = new EditableGeoJsonLayer({
      id: "geojson-layer",
      data: features,
      mode: mode,
      selectedFeatureIndexes,
      onEdit: ({ updatedData }) => {
        setFeatures(updatedData);
      },
      _subLayerProps: {
        tooltips: {
          getSize: 32,
        },
        guides: {
          getFillColor: (guide) => {
            console.log(guide);
            console.log(guide.properties.guideType);
            if (guide.properties.guideType === "tentative") {
              //   setFeatures(initFeatures);
              return SELECTED_FILL_COLOR;
            } else {
              return UNSELECTED_FILL_COLOR;
            }
          },
        },
        geojson: {
          extensions: [new PathStyleExtension({ dash: true })],
          getDashArray: [3, 2],
          dashJustified: true,
          lineWidthScale: 0.1,
          getFillColor: (feature) => {
            return [255, 255, 255, 22];
          },
          getLineColor: (feature) => {
            if (
              selectedFeatureIndexes.some((i) => data.features[i] === feature)
            ) {
              return UNSELECTED_LINE_COLOR;
            } else {
              return SELECTED_LINE_COLOR;
            }
          },
        },
      },
      //   _subLayerProps: {
      //     geojson: {
      //       getFillColor: (feature) => {
      //         if (
      //           selectedFeatureIndexes.some((i) => data.features[i] === feature)
      //         ) {
      //           return SELECTED_FILL_COLOR;
      //         } else {
      //           return UNSELECTED_FILL_COLOR;
      //         }
      //       },
      //     },
      //   },
    });
    setter(newLayer);
    // console.log(features);
  }, [features, mode, multiDraw]);

  return (
    <div className={styles.toolboxDrawer}>
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"activate multiple drawings"}
      >
        <label className={styles.switch}>
          <input
            type='checkbox'
            onChange={(e) => setMultiDraw(e.target.checked)}
          />
          <span className={styles.slider}></span>
        </label>
      </Tooltip>
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"panning"}
      >
        <BiPointer
          className={highlight === 1 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(1);
            setMode(() => ViewMode);
          }}
        />
      </Tooltip>
      {/* <BiMapPin onClick={(e) => {console.log(e)}} /> */}
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"draw circle"}
      >
        <BiCircle
          className={highlight === 2 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(2);
            setMode(() => DrawCircleFromCenterMode);
          }}
        />
      </Tooltip>
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"draw rectangle"}
      >
        <BiShapeSquare
          className={highlight === 3 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(3);
            setMode(() => DrawRectangleMode);
          }}
        />
      </Tooltip>
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"draw polygon"}
      >
        <BiShapePolygon
          className={highlight === 4 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(4);
            setMode(() => DrawPolygonMode);
          }}
        />
      </Tooltip>
      {/* <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"measure tool"}
      >
        <BiRuler
          className={highlight === 5 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(5);
            setMode(() => MeasureDistanceMode);
          }}
        />
      </Tooltip> */}
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"clear all"}
      >
        <BiTrash
          className={highlight === 6 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(6);
            setToolboxSelectApply(false);
            setMode(() => ViewMode);
            setFeatures(initFeatures);
          }}
        />
      </Tooltip>
      {/* <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"import geometry"}
      >
        <BiImport
          className={highlight === 7 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(7);
          }}
        />
      </Tooltip>
      <Tooltip
        mouseEnterDelay={MOUSEENTERDELAY}
        mouseLeaveDelay={MOUSELEAVEDELAY}
        placement='left'
        title={"export geometry"}
      >
        <BiExport
          className={highlight === 8 ? styles.btnHighlight : null}
          onClick={(e) => {
            setHighlight(8);
          }}
        />
      </Tooltip> */}
      {features.features.length > 0 && (
        <Tooltip
          mouseEnterDelay={MOUSEENTERDELAY}
          mouseLeaveDelay={MOUSELEAVEDELAY}
          placement='left'
          title={toolboxSelectApply ? "undo selection" : "apply selection"}
        >
          <div className={true ? styles.btnApply : null}>
            {toolboxSelectApply ? (
              <BiXCircle
                className={toolboxSelectApply ? styles.btnActive : null}
                onClick={(e) => {
                  setToolboxSelectApply(!toolboxSelectApply);
                }}
              />
            ) : (
              <BiCheckCircle
                className={toolboxSelectApply ? styles.btnActive : null}
                disabled={true}
                onClick={(e) => {
                  setToolboxSelectApply(!toolboxSelectApply);
                  setToolboxCalcGraph(false);
                  setHighlight(1);
                  setMode(() => ViewMode);
                }}
              />
            )}
          </div>
        </Tooltip>
      )}
      {toolboxSelectApply && features.features.length > 0 && (
        <Tooltip
          mouseEnterDelay={MOUSEENTERDELAY}
          mouseLeaveDelay={MOUSELEAVEDELAY}
          placement='left'
          title={toolboxSelectApply ? "show chart" : ""}
        >
          <div className={true ? styles.btnApply : null}>
            <MdOutlineAutoGraph
              className={toolboxSelectApply ? styles.btnActive : null}
              onClick={(e) => {
                setToolboxCalcGraph(true);
                setToolboxShowGraph(true);
              }}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
} from "../../common/common-sublayers";
import _ from "lodash";
import chroma from "chroma-js";

//------------------ VARIABLES ------------------

const scale = chroma.scale(["cyan", "red"]);
const brewer = chroma.brewer.RdYlBu.map((el) => chroma(el).rgb()).reverse();
// console.log(brewer);
// console.log(scale(0.5).rgb());

const hoverOptions = {
  power_grid: ["__typename"],
};
//------------------ Helpers ------------------
const handleHover = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
  obj &&
    console.log({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
};
const handleClick = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "click",
      ..._.omit(obj, ["geom"]),
    });
};

//------------------ Layer Specific Options ------------------

const iconLayerOptions = {
  getPosition: (d) => [...d.geom.coordinates, 5],
  sizeMinPixels: 5,
  sizeMaxPixels: 40,
  getSize: (d) => 25,
  sizeScale: 1,
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
      anchorY: 16,
      mask: true,
    },
  },
};
const lineLayerOptions = {};
const polyLayerOptions = {};
const cubeLayerOptions = {};
const pointLayerOptions = {};

//------------------ Functions ------------------

export const getWasteCollectionZones = (
  data,
  setHoveredBuildings,
  pickable = false
) => ({
  collection_zones_line_near: lineLayer(data.waste_collection_zones.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "waste_collection_zones_line_near",
    viewProps: { zoomMin: 10, zoomMax: 20, viewIds: ["main"] },
    widthMaxPixels: 5,
    widthScale: 7,
    getWidth: 7,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  collection_zones_line_far: lineLayer(data.waste_collection_zones.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "waste_collection_zones_line_far",
    viewProps: { zoomMin: 0, zoomMax: 10, viewIds: ["main"] },
    widthMaxPixels: 100,
    widthScale: 42,
    getWidth: 7,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  collection_zones_Polygon_near: polygonLayer(
    data.waste_collection_zones.Polygon,
    {
      ...polyLayerOptions,
      pickable: pickable,
      id: "waste_collection_zones_Polygon_near",
      viewProps: { zoomMin: 9, zoomMax: 20, viewIds: ["main"] },
      getFillColor: [230, 57, 70, 55],
      getLineColor: [100, 100, 100, 255],
      getLineWidth: 1,
      lineWidthMinPixels: 2,
      lineWidthMaxPixels: 4,
      onHover: (e) =>
        handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
      onClick: (e) =>
        handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
    }
  ),
  collection_zones_Polygon_far: polygonLayer(
    data.waste_collection_zones.Polygon,
    {
      ...polyLayerOptions,
      pickable: pickable,
      id: "waste_collection_zones_Polygon_far",
      viewProps: { zoomMin: 0, zoomMax: 9, viewIds: ["main"] },
      getLineColor: [100, 100, 100, 255],
      getLineWidth: 1,
      lineWidthMinPixels: 1,
      lineWidthMaxPixels: 2,
      filled: false,
      onHover: (e) =>
        handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
      onClick: (e) =>
        handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
    }
  ),
});
export const getWasteCollectionSites = (data, setHoveredBuildings, pickable = false) => ({
  waste_site_icons: iconLayer(data.waste_collection_zones.point, {
    ...iconLayerOptions,
    pickable: pickable,
    id: "waste_collection_zones_icons",
    viewProps: { zoomMin: 13, zoomMax: 20, viewIds: ["main"] },
    getColor: [85, 166, 48, 200],
    iconAtlas: "https://img.icons8.com/ios-filled/50/000000/waste.png",
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 48,
        height: 48,
        anchorY: 24,
        mask: true,
      },
    },
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  waste_site_points_near: pointLayer(data.waste_collection_zones.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "waste_collection_zones_points_near",
    viewProps: { zoomMin: 10, zoomMax: 13, viewIds: ["main"] },
    getFillColor: [85, 166, 48, 200],
    radiusScale: 2,
    radiusMinPixels: 5,
    radiusMaxPixels: 30,
    stroked: false,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  waste_site_points_far: pointLayer(data.waste_collection_zones.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "waste_collection_zones_points_far",
    viewProps: { zoomMin: 0, zoomMax: 10, viewIds: ["main"] },
    getFillColor: [85, 166, 48, 200],
    radiusScale: 1,
    radiusMinPixels: 2,
    radiusMaxPixels: 30,
    stroked: false,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
});

import React, { useState, useEffect, useCallback } from 'react'
import styles from './styles.module.less'
import { Dropdown, Menu, Typography, Button } from 'antd'
import { MdOutlineDesignServices } from 'react-icons/md'
import { FaRecycle } from 'react-icons/fa'
import { FaExchangeAlt } from 'react-icons/fa'
import { RiArrowDropDownFill } from 'react-icons/ri'
import { SlEnergy } from 'react-icons/sl'
import { panelsStore, controlStore } from '../../../store/store'
import _ from 'lodash'

export default function ScenarioMenu() {
  const [hovered, setHovered] = useState(false)
  const [menuData, setMenuData] = useState(undefined)

  const setActiveServices = panelsStore((state) => state.setActiveServices)
  const setShowServicesPanels = panelsStore((state) => state.setShowServicesPanels)
  const showServicesPanels = panelsStore((state) => state.showServicesPanels)
  const layersShown = controlStore((state) => state.layersShown)
  const setLayersShown = controlStore((state) => state.setLayersShown)

  const resetLayers = useCallback(() => {
    _.keys(layersShown).forEach((element) => {
      setLayersShown(element, false)
    })
  }, [layersShown])

  const menu = useCallback(
    () => (
      <Menu key="Services" theme="dark">
        <Menu.Item
          key="Services_item_1"
          onClick={() => {
            resetLayers()
            setActiveServices('retrofitAnalysis', true)
            setLayersShown('services', true)
            setLayersShown('built', true)
            setLayersShown('built_buildingsretrofit', true)
          }}
        >
          <div className={styles.chartIcon}>
            <FaExchangeAlt />
            <Typography.Text>Building Retrofit Analysis</Typography.Text>
          </div>
        </Menu.Item>
        <Menu.Item
          key="Services_item_3"
          onClick={() => {
            resetLayers()
            setActiveServices('wasteOptimization', true)
            setLayersShown('services', true)
            setLayersShown('waste', true)
            setLayersShown('waste_optimization', true)
          }}
        >
          <div className={styles.chartIcon}>
            <FaRecycle />
            <Typography.Text>Waste Optimization</Typography.Text>
          </div>
        </Menu.Item>
      </Menu>
    ),
    [layersShown],
  )

  useEffect(() => {
    setMenuData(menu())
  }, [hovered])

  return (
    <div className={styles.panelTrigger} onClick={() => setShowServicesPanels(!showServicesPanels)}>
      <Button>Services</Button>
    </div>
  )
}

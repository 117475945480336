import _ from "lodash"

export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return [r, g, b, 255];
    // return `rgba(${r},${g},${b},${alpha})`;
  };

export const cumulativeSum = function (arr) {
  var builder = function (acc, n) {
      var lastNum = acc.length > 0 ? acc[acc.length-1] : 0;
      acc.push(lastNum + n);
      return acc;
  };
  return _.reduce(arr, builder, []);
}

export const readTextFile = (file, callback) => {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function() {
      if (rawFile.readyState === 4 && rawFile.status == "200") {
          callback(rawFile.responseText);
      }
  }
  rawFile.send(null);
}


export class Tree {
  // Tree representation of data layers
  constructor() {
    this.root = {};
  }

  getTree(list) {
    const index = list .reduce(
      (a, {path, ...rest}) => ({...a, [path]: {path, ...rest}}), 
      {}
    )
    
    return list .reduce((root, {path}) => {
      const node = index [path]
      const parent = index [path .split('.') .slice(0, -1) .join('.')] || root
      parent.children = [...(parent.children || []), node]
      return root
    }, {children: []}) .children
  }


  getFlat(array = []) {
    return array.reduce((r, item) => {
      const { children: deletedKey, ...otherKeys } = item;
      return r.concat({ ...otherKeys }, this.getFlat(item.children));
    }, []);
  }
}

export function shuffleArray(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
  }
  return a;
}

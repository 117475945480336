import React, { useEffect } from "react";
import styles from "./loading-spinner.module.less";
import { systemStore } from "../../store/store";
import { Spin } from "antd";

export default function LoadingSpinner() {
  const isLoading = systemStore((state) => state.isLoading);

  useEffect(() => {
    // console.log(isLoading);
  }, [isLoading]);
  return (
    <div className={styles.container}>
      {isLoading.main && <Spin delay={100} />}
    </div>
  );
}

import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
} from "../../common/common-sublayers";
import chroma from "chroma-js";
import {
  ScreenGridLayer,
  HeatmapLayer,
  HexagonLayer,
} from "@deck.gl/aggregation-layers";
import {DataFilterExtension} from '@deck.gl/extensions';

//------------------ VARIABLES ------------------

const scale = chroma.scale(["#58f", "#ee2", "#f35"]);

const brewer = chroma.brewer.RdYlBu.map((el) => chroma(el).rgb()).reverse();

const dataFilter = new DataFilterExtension({
  filterSize: 1,
  // Enable for higher precision, e.g. 1 second granularity
  // See DataFilterExtension documentation for how to pick precision
  fp64: false,
});

//------------------ Layer Specific Options ------------------

const iconLayerOptions = {
  getPosition: (d) => [...d.geom.coordinates, 5],
  sizeMinPixels: 5,
  sizeMaxPixels: 40,
  getSize: (d) => 25,
  sizeScale: 1,
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
      anchorY: 16,
      mask: true,
    },
  },
};
const lineLayerOptions = {
  id: "power_grid_line",
  getColor: (d) =>
    d.voltage
      ? brewer[Math.round((d.voltage / 750000) * 8)]
      : [111, 111, 111, 255],
};
const polyLayerOptions = {};
const cubeLayerOptions = {};
const pointLayerOptions = {
  getPosition: (d) => [parseFloat(d.LOC_LONG), parseFloat(d.LOC_LAT)],
  getRadius: (d) => {
    // console.log(d.DT_ACCDN, Date.parse(d.DT_ACCDN));
    return Number(d.VITESSE_AUTOR) < 30
      ? 1
      : (Number(d.VITESSE_AUTOR) - 20) / 3;
  },
};

//------------------ Functions ------------------

export const getRoadCollision = ({
  data,
  filterValue,
  filterEnabled,
  pickable,
}) => {
  console.log('filterValue###', filterValue)
  return {
    transport_collision_points: pointLayer(data.records, {
      ...pointLayerOptions,
      pickable: pickable,
      opacity: 1.0,
      id: "transport_collision_points",
      viewProps: { zoomMin: 3, zoomMax: 24, viewIds: ["main"] },
      getFillColor: (d) => [
        ...scale(
          Number(d.VITESSE_AUTOR) < 30
            ? 0.1
            : (Number(d.VITESSE_AUTOR) - 20) / 30
        ).rgb(),
        188,
      ],
      radiusScale: 1,
      radiusMinPixels: 1,
      radiusMaxPixels: 30,
      stroked: true,
      lineWidthMinPixels: 0,
      lineWidthMaxPixels: 1,
      getLineColor: [255, 255, 255, 55],
      getFilterValue: (d) => Date.parse(d.DT_ACCDN) / 3600000,
      filterRange: [filterValue[0], filterValue[1]],
      filterSoftRange: [
        filterValue[0] * 0.9 + filterValue[1] * 0.1,
        filterValue[0] * 0.1 + filterValue[1] * 0.9,
      ],
      extensions: [dataFilter],
    }),
  };
};

export const vs = `#define SHADER_NAME scatterplot-layer-vertex-shader
    attribute vec3 positions;
    attribute vec3 instancePositions;
    attribute vec3 instancePositions64Low;
    attribute float instanceRadius;
    attribute float instanceLineWidths;
    attribute vec4 instanceFillColors;
    attribute vec4 instanceLineColors;
    attribute vec3 instancePickingColors;
    uniform float opacity;
    uniform float radiusScale;
    uniform float radiusMinPixels;
    uniform float radiusMaxPixels;
    uniform float lineWidthScale;
    uniform float lineWidthMinPixels;
    uniform float lineWidthMaxPixels;
    uniform float stroked;
    uniform bool filled;
    varying vec4 vFillColor;
    varying vec4 vLineColor;
    varying vec2 unitPosition;
    varying float innerUnitRadius;
    varying float outerRadiusPixels;
    
    void main(void) {
      geometry.worldPosition = instancePositions;
      // Multiply out radius and clamp to limits
      outerRadiusPixels = clamp(
        project_size_to_pixel(radiusScale * instanceRadius),
        radiusMinPixels, radiusMaxPixels
      );
      
      // Multiply out line width and clamp to limits
      float lineWidthPixels = clamp(
        project_size_to_pixel(lineWidthScale * instanceLineWidths),
        lineWidthMinPixels, lineWidthMaxPixels
      );
      // outer radius needs to offset by half stroke width
      outerRadiusPixels += stroked * lineWidthPixels / 2.0;
      // position on the containing square in [-1, 1] space
      unitPosition = positions.xy;
      geometry.uv = unitPosition;
      geometry.pickingColor = instancePickingColors;
      innerUnitRadius = 1.0 - stroked * lineWidthPixels / outerRadiusPixels;
      
      vec3 offset = positions * project_pixel_size(outerRadiusPixels);
      DECKGL_FILTER_SIZE(offset, geometry);
      gl_Position = project_position_to_clipspace(instancePositions, instancePositions64Low, offset, geometry.position);
      DECKGL_FILTER_GL_POSITION(gl_Position, geometry);
      // Apply opacity to instance color, or return instance picking color
      vFillColor = vec4(instanceFillColors.rgb, instanceFillColors.a * opacity);
      DECKGL_FILTER_COLOR(vFillColor, geometry);
      vLineColor = vec4(instanceLineColors.rgb, instanceLineColors.a * opacity);
      DECKGL_FILTER_COLOR(vLineColor, geometry);
    }`
    
export const fs = `#define SHADER_NAME scatterplot-layer-fragment-shader
    precision highp float;
    uniform bool filled;
    uniform float stroked;
    varying vec4 vFillColor;
    varying vec4 vLineColor;
    varying vec2 unitPosition;
    varying float innerUnitRadius;
    varying float outerRadiusPixels;
    void main(void) {
      if (vFillColor.a == 0.0) {
        discard;
      }
      geometry.uv = unitPosition;
      float distToCenter = length(unitPosition) * outerRadiusPixels;
      float inCircle = smoothedge(distToCenter, outerRadiusPixels);
      if (inCircle == 0.) {
        discard;
      }
      if (stroked > 0.5) {
        float isLine = smoothedge(innerUnitRadius * outerRadiusPixels, distToCenter);
        if (filled) {
          gl_FragColor = mix(vFillColor, vLineColor, isLine);
        } else {
          if (isLine == 0.0) {
            discard;
          }
          gl_FragColor = vec4(vLineColor.rgb, vLineColor.a * isLine);
        }
      } else if (filled) {
        //gl_FragColor = vFillColor;
      } else {
        //discard;
      }
      gl_FragColor.a *= inCircle;
      DECKGL_FILTER_COLOR(gl_FragColor, geometry);
    }`
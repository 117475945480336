import React, { useEffect, useCallback, useState } from "react";
import { Tree as AntTree } from "antd";
import { panelsStore, controlStore } from "../../store/store";
import Row from "./row";
import { Tree as TreeData } from "../../utils/helpers";
import { layersPickable } from "../../store/initials";
import _ from "lodash";

const treeObj = new TreeData();

const mapRowToComponent = (item, setClicked) => {
  return {
    title: (
      <Row
        obj={{
          text: item.titleText,
          isCloseShown: item.isCloseShown,
          isPickableShown: item.isPickableShown,
          show: item.show,
          pickable: item.pickable,
          key: item.key,
        }}
        setClicked={setClicked}
      />
    ),
    key: item.key,
    show: item.show,
    pickable: item.pickable,
    ...(item.children &&
      item.children.length > 0 && {
      children: item.children.map((subitem, _) =>
        mapRowToComponent(subitem, setClicked)
      ),
    }),
  };
};

export default function Tree({ layers, layersSetter, layersChanged }) {
  const [data, setData] = useState(undefined);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [clicked, setClicked] = useState({
    target: undefined,
    key: undefined,
  });

  const setLayersShown = controlStore((state) => state.setLayersShown);
  const layersShown = controlStore((state) => state.layersShown);
  const layersPickable = controlStore((state) => state.layersPickable);
  const setLayersPickable = controlStore((state) => state.setLayersPickable);

  const getUpdatedTree = useCallback((mainLayers) => {
    const data = treeObj.getTree(mainLayers);
    setData(data && data.map((item, _) => mapRowToComponent(item, setClicked)));
  }, []);


  useEffect(() => {
    const extendedMainLayers = layers.map((el) => {
      // if (el.key.includes("built")) {
      //   // if (el.key.includes("collision")) {
      //   return {
      //     ...el,
      //     isCloseShown: true,
      //     isPickableShown: true,
      //     pickable: false,
      //     show: true,
      //   };
      // }
      // if (el.key==="transport") {
      //   return {
      //     ...el,
      //     isCloseShown: true,
      //     isPickableShown: true,
      //     pickable: false,
      //     show: true,
      //   };
      // }

      return {
        isCloseShown: true,
        isPickableShown: true,
        pickable: false,
        show: false,
        ...el,
      };
    });

    extendedMainLayers.forEach((el) => {

      setLayersShown(el.key, el.show);
      setLayersPickable(el.key, el.pickable);
    });

    layersSetter(extendedMainLayers);
    getUpdatedTree(extendedMainLayers);

  }, []);

  useEffect(() => {
    const { target, key } = clicked;
    if (key) {
      var index = _.findIndex(layers, { key: key });
      const rowObj = layers[index];
      rowObj &&
        layers.splice(index, 1, { ...rowObj, [target]: !rowObj[target] });
      clicked.target === "show" && setLayersShown(clicked.key, !rowObj[target]);
      clicked.target === "pickable" &&
        setLayersPickable(clicked.key, !rowObj[target]);
      clicked.target === "close" && index > -1 && layers.splice(index, 1);
      layersSetter(layers);
      getUpdatedTree(layers);
    }
  }, [clicked, layersChanged]);


  //// update tree DOM element when layersShown and layersPickable are changed
  useEffect(() => {
    if (_.values(layersShown).length > 0) {
      let newLayer = layers.map(el => ({ ...el, show: layersShown[el.key], pickable: layersPickable[el.key] }))
      layersSetter(newLayer);
      getUpdatedTree(newLayer);
      setExpandedKeys(_.keys(layersShown).filter(k => layersShown[k]))
    }
  }, [layersShown, layersPickable]);


  return (
    <AntTree
      showLine={{ showLeafIcon: true }}
      onExpand={(keys) => setExpandedKeys(keys)}
      expandedKeys={expandedKeys}
      // showIcon={showIcon}
      // defaultExpandedKeys={["built"]}
      // onSelect={onSelect}
      treeData={data}
    />
  );
}

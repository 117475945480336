import React from "react";
import ReactJson from "react-json-view";
import styles from "./styles.module.less";
import { panelsStore, dataStore } from "../../../store/store";
import {FaWindowClose} from 'react-icons/fa';

export default function index() {
  // Shared variables
  const activePanels = panelsStore((state) => state.activePanels);
  const setActivePanels = panelsStore((state) => state.setActivePanels);
  const settingsData = dataStore((state) => state.settingsData);
  const setSettingsData = dataStore((state) => state.setSettingsData);

  return (
    activePanels.settingsPanel && (
      <div className={styles.container}>
        <button onClick={(_) => setActivePanels("settingsPanel", false)}>
          <FaWindowClose size={12}/>
        </button>
        <ReactJson
          src={settingsData}
          name='settings'
          // theme='brewer'
          theme='bright:inverted'
          iconStyle='circle'
          indentWidth={2}
          collapsed={1}
          collapseStringsAfterLength={20}
          enableClipboard={true}
          displayObjectSize={false}
          displayDataTypes={false}
          onEdit={(el) => setSettingsData(el.updated_src)}
          onAdd={(el) => setSettingsData(el.updated_src)}
          onDelete={(el) => setSettingsData(el.updated_src)}
          quotesOnKeys={false}
        />
      </div>
    )
  );
}

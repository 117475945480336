import React, { useState, useEffect } from "react";
import MapLayer from "./layer";
import MapLayerFlow from "./layer/flow-layer/index";
import MapLayerCollision from "./layer/collision-layer/index";
import { controlStore } from "../../../store/store";

export default function index(props) {
  const layersShown = controlStore((state) => state.layersShown);
  const [flowLayer, setFlowLayer] = useState([]);
  const [collisionLayer, setCollisionLayer] = useState([]);
  const [layer, setLayer] = useState([]);

  useEffect(() => {
    props.setLayer([flowLayer, ...collisionLayer, ...layer]);
  }, [layer, flowLayer, collisionLayer]);

  return (
    <>
      {layersShown.transport && layersShown.transport_flow && (
        <MapLayerFlow setLayer={setFlowLayer} />
      )}
      {layersShown.transport && layersShown.transport_roadcollision && (
        <MapLayerCollision setLayer={setCollisionLayer} />
      )}
      {/* <MapLayer setLayer={setLayer} /> */}
    </>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { Select, Typography, Divider } from "antd";
import styles from "./agents.module.less";
import { dataStore, statsStore } from "../../../../../../../store/store";
const { Title } = Typography;


export default function Selection() {
  const [agentNames, setAgentNames] = useState([]);
  //// SHARED STATES
  const floatingData = dataStore((state) => state.floatingData);
  const setFloatingData = dataStore((state) => state.setFloatingData);
  const selectedAgents = statsStore((state) => state.selectedAgents);
  const setSelectedAgents = statsStore((state) => state.setSelectedAgents);


  useEffect(() => {
    setAgentNames(
      floatingData.map((el) => ({ value: el.vname, disabled: false }))
    );
  }, [floatingData]);

  return (
    <div className={styles.wrapper}>
      <Select
        size='small'
        maxTagCount={4}
        showArrow={true}
        mode='multiple'
        style={{ width: "100%" }}
        placeholder='selected agents'
        dropdownClassName={styles.dropdown}
        // defaultValue={activeAgents}
        // onChange={handleChange}
        options={agentNames}
        value={[...selectedAgents]}
        onDeselect={(e)=>setSelectedAgents(e, false)}
        onSelect={(e)=>setSelectedAgents(e, true)}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./styles.module.less";
import { statsStore } from "../../../store/store";
import LoadingModal from "../../../utils/loadingmodal";
import Chart from "./chart"

const API_URL = `${process.env.REACT_APP_API_SERVER_ENDPOINT_URL}`;

export default function index() {
  //------------------ LOCAL STATES ------------------
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(undefined);
  // const [idfDownloadPath, setIdfDownloadPath] = useState(null);
  //------------------ SHARED STATES ------------------
  //   const setActiveServices = panelsStore((state) => state.setActiveServices);
  //   const activeServices = panelsStore((state) => state.activeServices);
  //   const setLayersShown = controlStore((state) => state.setLayersShown);
  const selectedBuildings = statsStore((state) => state.selectedBuildings);

  useEffect(() => {
    if (
      selectedBuildings.target &&
      Object.keys(selectedBuildings.target).length > 0
    ) {
      // console.log("selectedBuildings", selectedBuildings);
      setLoading(true);
      handleSubmit();
    }
  }, [selectedBuildings]);

  const handleSubmit = () => {
    var url = `${API_URL}/analysis/rcmodel`;
    let payload = selectedBuildings ? selectedBuildings : {};
    axios
      .post(url, payload)
      .then((response) => {
        console.log("response", response.data);
        setValues(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (values) {
      setLoading(false);
    }
    // values && console.log(values.HeatingDemand)
  }, [values]);

  return (
    <>
      <div className={`container ${styles.container}`}>
        <div className={` ${styles.description}`}>
          <h2>Building Energy Analysis</h2>
          <h5>based on R-C model</h5>
        </div>
        <div className={` ${styles.charts}`}>
          {values && <Chart data={values.HeatingDemand}/>}
        </div>
      </div>
      {loading && <LoadingModal />}
    </>
  );
}

import React from "react";
import styles from "./statistics-window.module.less";
import _ from "lodash";
import { GrFormClose } from "react-icons/gr";


export default function StatsWin({ data, closeCallback }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Energy Statistics</div>
      <GrFormClose className={styles.closeBtn} onClick={closeCallback}/>
      <ul className={styles.leftList}>
        <li key="lSelected">
          <span>Area Selected</span>
        </li>
        <li key="lnumbers">
          <span>Number of Buildings</span>
        </li>
        {data.energyDemands.map((d, i) => (
          <li key="ldemand">
            <span>{d.x[0]} - {d.x[1]} </span>
          </li>
        ))}
        <li key="ltotal">
          <span>TOTAL</span>
        </li>
      </ul>
      <ul className={styles.rightList}>
        <li key="rSelected">
          <span>{data.areaSelected}</span>
        </li>
        <li key="rnumbers">
          <span>{data.nrBuildings}</span>
        </li>
        {data.energyDemands.map((d, i) => (
          <li key="rdemand">
            <span>{d.energy} KWh </span>
          </li>
        ))}
        <li key="rtotal">
          <span>{_.sumBy(data.energyDemands, 'energy') } KWh</span>
        </li>
      </ul>
    </div>
  );
}

import _ from "lodash"

export const dataSplitbyGeom = (inpData) => {
  let dataObj = {};
  Object.keys(inpData).forEach((key) => {
    const val = inpData[key];
    dataObj[key] = {
      point: val
        .filter(
          (d) => d.geom.type === "Point" || d.geom.type === "MultiPoint"
        )
        .map((d) =>
          d.geom.type === "MultiPoint"
            ? {
              ...d,
              geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
            }
            : d
        ),
      line: val
        .filter(
          (d) =>
            d.geom.type === "LineString" || d.geom.type === "MultiLineString"
        )
        .map((d) =>
          d.geom.type === "MultiLineString"
            ? {
              ...d,
              geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
            }
            : d
        ),
      Polygon: val
        .filter(
          (d) => d.geom.type === "Polygon" || d.geom.type === "MultiPolygon"
        )
        .map((d) =>
          d.geom.type === "MultiPolygon"
            ? {
              ...d,
              geom: { ...d.geom, coordinates: d.geom.coordinates[0] },
            }
            : d
        ),
    };
  });
  return dataObj
}


export const expandSublayers = (layersObj) => {
  const result = _.values(layersObj).reduce((prev, curr) => {
    Object.keys(curr).forEach((key) => {
      prev[key] = curr[key];
    });
    return prev;
  }, {});
  return result
}
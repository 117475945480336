import React, { useState, useEffect } from "react";
import styles from "./basemap-switcher.module.less";
import { Drawer, Button, Space, Icon } from "antd";
import { FaMapMarkedAlt } from "react-icons/fa";
import { systemStore } from "../../store/store";

const thumbnailsMappings = {
  dark1: "Dark-1.png",
  light1: "Light-1.png",
  dark2: "Dark-2.png",
  light2: "Light-2.png",
};

export default function BasemapDrawer(props) {
  //// SHARED STATE VARIABLES
  const baseMapStyle = systemStore((state) => state.baseMapStyle);
  const setBaseMapStyle = systemStore((state) => state.setBaseMapStyle);

  return (
    <div
      className={
        styles.basemapDrawer +
        " " +
        (props.hide ? styles.hide : "") +
        " " +
        (baseMapStyle.includes("light") ? styles.basemapDrawerDark : "")
      }
    >
      <div className={styles.overlayLabel}>
        <div
          className={
            styles.overlayText +
            " " +
            (baseMapStyle.includes("light") ? styles.overlayTextDark : "")
          }
        >
          maps
        </div>
        <div
          className={
            styles.overlayIcon +
            " " +
            (baseMapStyle.includes("light") ? styles.overlayIconDark : "")
          }
        >
          <FaMapMarkedAlt />
        </div>
      </div>
      {Object.keys(thumbnailsMappings)
        // this sort makes sure selected element is always first in list
        .sort(function (x, y) {
          return x == baseMapStyle ? -1 : y == baseMapStyle ? 1 : 0;
        })
        .map((el) => {
          return (
            <img
              onClick={() => setBaseMapStyle(el)}
              src={thumbnailsMappings[el]}
              key={el}
            />
          );
        })}
    </div>
  );
}

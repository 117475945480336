import React, { useState, useEffect } from "react";
import styles from "./styles.module.less";
import Title from "./title";
import TopMenu from "./topmenu";
import InfoMenu from "./infomenu";
import { Divider } from "antd";

export default function index() {
  //// LOCAL STATES

  //// SHARED STATES

  return (
    <div className={styles.wrapper}>
      <Title />
      {/* <Divider type='vertical' /> */}
      <TopMenu />
      {/* <Divider type='vertical' /> */}
      {/* <InfoMenu /> */}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import styles from "./styles.module.less";
import _ from "lodash";
// import { Typography } from "antd";
// import Tree from "./tree";
import Sidebar from "./sidebar";
import { panelsStore, controlStore } from "../../store/store";

export default function LayerManager(props) {
  const [collapse, setCollapse] = useState(false);
  
  const setLayersShown = controlStore((state) => state.setLayersShown);
  const setLayersPickable = controlStore((state) => state.setLayersPickable);

  const handleCollapse = () => {
    setCollapse((pre) => !pre);
  };

  useEffect(() => {
    setLayersShown("built", true);
    setLayersShown("built_buildingsmodel", true);
    setLayersPickable("built", true);
    setLayersPickable("built_buildingsmodel", true);
  }, []);

  return (
    <div>
      {!props.hide && (
        <button
          className={styles.hideBtn + " " + (collapse ? styles.slideLeft : "")}
          onClick={handleCollapse}
        >
          {collapse ? "Show" : "Hide"}
        </button>
      )}
      {!collapse && <Sidebar hide={props.hide} />}
    </div>
  );
}

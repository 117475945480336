import { useState, useEffect, useRef } from "react";
import styles from "./analysis-panel.module.less";
import { Button, Slider, Typography, Divider } from "antd";
import { RiArrowDropDownFill } from "react-icons/ri";
import { RiArrowDropUpFill } from "react-icons/ri";
import { Portal } from "react-portal";
import * as d3 from "d3";

export default function ActionSelect({
  toggleBrush,
  setToggleBrush,
  brushRadius,
  setBrushRadius,
  brushOptions,
}) {
  const d3Element = useRef(null);

  useEffect(() => {
    if (toggleBrush) {
      let size = [100, 100];
      let svg = d3
        .select(d3Element.current)
        .append("svg")
        .attr("width", size[0])
        .attr("height", size[1]);
      let rect_width = 10;
      svg
        .selectAll("rect")
        .data([10, 20, 30, 40, 50])
        .enter()
        .append("rect")
        .attr("x", (d, i) => 5 + i * (rect_width + 5))
        .attr("y", (d, i) => size[1] - d)
        .attr("width", rect_width)
        .attr("height", (d) => d)
        .attr("fill", "pink");
    }
  }, [toggleBrush]);

  return (
    <Portal>
      <div className={styles.container}>
        <Button onClick={() => setToggleBrush(!toggleBrush)}>
          {toggleBrush ? (
            <div>
              hide analysis <RiArrowDropUpFill />{" "}
            </div>
          ) : (
            <div>
              show analysis <RiArrowDropDownFill />{" "}
            </div>
          )}
        </Button>
        {toggleBrush && (
          <div className={styles.charts}>
            <Divider type='horizontal' />
            <Typography.Text>circle radius</Typography.Text>
            <Slider
              defaultValue={brushOptions.defaultValue}
              min={brushOptions.min}
              max={brushOptions.max}
              value={brushRadius}
              onChange={(e) => setBrushRadius(e)}
            />
            <Divider type='horizontal' />
            <Typography.Text>Chart</Typography.Text>
            <div className={styles.d3Chart} ref={d3Element}></div>
          </div>
        )}
      </div>
    </Portal>
  );
}

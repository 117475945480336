import React, { useState } from "react";
import styles from "./styles.module.less";
import { Image, Drawer, Tooltip } from "antd";
import { Button } from "antd";
import { BiCaretDown } from "react-icons/bi";


export default function Panels({children}) {
  const [visible, setVisible] = useState(false);
  return (
    <div className={styles.wrapper + " " + (visible ? "" : styles.inactive)}>
      <Tooltip
        mouseEnterDelay={0.2}
        mouseLeaveDelay={0.0}
        placement='bottomLeft'
        title={visible ? "close analysis panel" : "open analysis panel"}
      >
        <button
          className={styles.button + " " + (visible ? "" : styles.buttonHidden)}
          onClick={() => setVisible(!visible)}
        >
          <BiCaretDown />
        </button>
      </Tooltip>

      {children}
      
    </div>
  );
}

import React, {
  useEffect,
  useState,
  useCallback,
  // useContext,
  useRef,
} from "react";
// import { SocketContext } from "../../../../../lib/socketClient";
import { panelsStore, dataStore, statsStore } from "../../../../../../../store/store";
import { useFetchData } from "../charthooks";
import styles from "./timeseries.module.less";
import { Line } from "react-chartjs-2";
import { Tooltip, Switch } from "antd";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import { FaWindowClose } from "react-icons/fa";
import { getColumnsBetweenTS } from "../../../../../queries";
import { useQuery, useSubscription } from "@apollo/client";
import Metrics from "../selections/metrics";
import NumberInput from "../inputbox/number";
import { defaultOptions, colorArray } from "../settings";
import _ from "lodash";
import { cumulativeSum } from "../../../../../../../utils/helpers";
import * as d3 from "d3";

// const cumulativeSum = (
//   (sum) => (value) =>
//     (sum += value)
// )(0);

const dataAugment = (data, metricTypes, isShown, isColumative) => {
  if (isShown) {
    var partitionedById = _(data).groupBy("vname").values().value();
    var datasets = {
      labels: _.map(partitionedById[0], "vts"),
      datasets: metricTypes
        .map((metric) => {
          return partitionedById.map((el, ix) => ({
            label: metric.split("_")[1] + ":" + el[0].vname,
            data: isColumative
              ? cumulativeSum(_.map(el, metric))
              : _.map(el, metric),
            borderColor: colorArray[ix],
            pointRadius: 0,
            borderWidth: 1,
          }));
        })
        .flat(),
    };
    console.info("metricTypes ", metricTypes);
    console.info(datasets);
    return datasets;
  }

  return {};
};

const BASE_METRICS = ["vts", "vname"];

export default function emission(props) {
  //// LOCAL STATES
  const [historyLen, setHistoryLen] = useState(60);
  const [comulativeChecked, setComulativeChecked] = useState(true);
  const [treeValue, setTreeValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [metricsList, setMetricsList] = useState(BASE_METRICS);
  const isMounted = useRef(true);
  //// SHARED STATES
  const setActivePanels = panelsStore((state) => state.setActivePanels);
  const dataIndex = dataStore((state) => state.index);
  const selectedAgents = statsStore((state) => state.selectedAgents);

  //// FUNCTIONS
  const onButtonClose = useCallback(
    () => setActivePanels("timeseriesPanel", false),
    []
  );

  // //// CONTEXTS
  // const socket = useContext(SocketContext);

  //// HOOKS
  const res = useSubscription(
    getColumnsBetweenTS(
      historyLen && historyLen < dataIndex ? dataIndex - historyLen : 0, // making sure starting timestamp is not undefined and negative
      dataIndex,
      metricsList, // list of columns
      [...selectedAgents], // list of entities
      true // subscription flag
    )
  );

  //// SIDE EFFECTS
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsLoading(res.loading);
  }, [res.loading]);

  useEffect(() => {
    const isShown = metricsList.length > 2 && selectedAgents.size > 0;
    res.data
      ? setData(
          dataAugment(res.data.agents, treeValue, isShown, comulativeChecked)
        )
      : null;
  }, [res.data]);

  useEffect(() => {
    setMetricsList([...BASE_METRICS, ...treeValue]);
  }, [treeValue]);

  // useEffect(() => {
  //   const {h, w} = [500, 700]
  //   const dataD3 = [12, 5, 6, 6, 9, 10];
  //   const svg = d3.select(".svgd3")
  //   .append("svg")
  //   .attr("width", w)
  //   .attr("height", h)
  //   .append("g")
    
  // }

  // , []);

  // return <div className='svgd3'></div>;



  return (
    // <Draggable>
    <Resizable
      defaultSize={{
        x: 0,
        y: 0,
        width: 330,
        height: 200,
      }}
      lockAspectRatio={true}
      className={styles.wrapper}
    >
      <FaWindowClose className={styles.closebtn} onClick={onButtonClose} />
      <Line data={data} options={defaultOptions} backgroundColor={"#fff"} />
      <div className={styles.inputGroup}>
        <Metrics treeValue={treeValue} setTreeValue={setTreeValue} />
        <NumberInput historyLenSetter={setHistoryLen} />
        <Tooltip
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.5}
          placement='bottom'
          title={"show comulative"}
        >
          <Switch
            size='small'
            onChange={(e) => setComulativeChecked(e)}
            defaultChecked
          />
        </Tooltip>
      </div>
    </Resizable>
  );
}

import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
  solidPolygonLayer,
} from "../../common/common-sublayers";
import chroma from "chroma-js";
import CustomMVTLayer from "../../common/custom-mvt-layer";
import { OBJLoader } from "@loaders.gl/obj";
import { PLYLoader } from "@loaders.gl/ply";
import {
  ScreenGridLayer,
  HeatmapLayer,
  HexagonLayer,
} from "@deck.gl/aggregation-layers";
import _ from "lodash";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { GeoJsonLayer } from "@deck.gl/layers";
import { shuffleArray } from "../../../../utils/helpers";
import { MVTLayer } from "@deck.gl/geo-layers";
import { ScatterplotLayer } from "@deck.gl/layers";
import { SolidPolygonLayer } from "@deck.gl/layers";
import area from '@turf/area';
import centroid from '@turf/centroid';

//------------------ VARIABLES ------------------
var selectedIndex = undefined;
// Source data GeoJSON
const FOOTPRINT_URL = `${process.env.REACT_APP_TILE_SERVER_ENDPOINT_URL}/public.building_footprint_year_built_height_max.json`;
// const LOT_URL = `${process.env.REACT_APP_TILE_SERVER_ENDPOINT_URL}/public.building_lots.json`;
const LOT_URL = `${process.env.REACT_APP_TILE_SERVER_ENDPOINT_URL}/public.mtl_buildings_nrcan.json`;

const scaleHeight = chroma.scale(["#006ba6", "#8f2d56"]);
const scaleYear = chroma.scale(["#ff006d", "#01befe"]).domain([0.1, 1.0]);
const brewer = chroma.brewer.YlGn.map((el) => chroma(el).rgb()).reverse();
// console.log(brewer);
// console.log(scale(0.5).rgb().push(10));

//------------------ Layer Specific Options ------------------
const solidPolygonLayerOptions = {
  getPolygon: (d) => d.geometry.coordinates,
};
const geoJsonLayerOptions = {
  getPosition: (d) => d.geometry.coordinates,
};
//------------------ Functions ------------------
const calcGeomStats = (dataObj) => {
  return { centroid: centroid(dataObj).geometry.coordinates, area: area(dataObj) };
};

const lineLayerOptions = {
  id: "power_grid_line",
  getColor: (d) =>
    d.voltage
      ? brewer[Math.round((d.voltage / 750000) * 8)]
      : [111, 111, 111, 255],
};

const pointLayerOptions = {
  radiusScale: 2,
  radiusMinPixels: 1,
  radiusMaxPixels: 50,
  stroked: false,
  opacity: 0.05,
};

export const getBaseBuildings = (
  clickedItem,
  setClickedItem,
  surroundingItems,
  setHoveredBuildings,
  pickable = false
) => {
  return {
    built_buildingsmodel: new MVTLayer({
      id: "built_buildingsmodel",
      data: FOOTPRINT_URL,
      minZoom: 12,
      maxZoom: 30,
      // wireframe: false,
      binary: true,
      viewProps: { zoomMin: 0, zoomMax: 30, viewIds: ["main"] },
      onTileError: () => {},
      onHover: ({ x, y, object }) => {
        // object && setSelect(object.properties.index);
        object && setHoveredBuildings(object.properties);
        // object && console.log(object.properties);
      },
      onClick: ({ x, y, object }) => {
        if (object) {
          let objProps = object.properties;
          let geom = object.geometry;
          setClickedItem(
            object ? { ...objProps, ...calcGeomStats(geom), coords: geom.coordinates } : {}
          );
        }
      },
      uniqueIdProperty: "index",
      // highlightedFeatureId: select,
      // highlightedFeatureId: ,
      highlightColor: [255, 120, 0, 255],
      autoHighlight: true,
      renderSubLayers: (props) => {
        // console.log(props)
        return [
          new GeoJsonLayer(props, {
            wireframe: true,
            uniqueIdProperty: "index",
            // highlightedFeatureId: select,
            // highlightColor: [255, 0, 0, 255],
            autoHighlight: true,
            pickable: pickable,
            opacity: 0.99,
            stroked: true,
            filled: true,
            extruded: true,
            lineWidthMinPixels: 2,
            // getElevation: (f) => Math.sqrt(f.properties.height_max) * 7,
            getElevation: (f) => f.properties.height_max,
            getLineColor: [0, 0, 0, 255],
            // getFillColor: [255, 255, 255, 200],
            getFillColor: (f) =>
              clickedItem && f.properties.index === clickedItem.index
                ? [255, 20, 20, 150]
                : clickedItem && surroundingItems.includes(f.properties.index)
                ? [138, 201, 38, 200]
                : [255, 255, 255, 200],
            updateTriggers: {
              getFillColor: [clickedItem, surroundingItems],
            },
          }),
        ];
      },
    }),
  };
};


export const getFootprints = (
  pickable = false
) => {
  return {
    built_buildingsfootprint: new MVTLayer({
      id: "built_buildingsfootprint",
      data: LOT_URL,
      minZoom: 7,
      maxZoom: 30,
      // wireframe: false,
      binary: true,
      viewProps: { zoomMin: 0, zoomMax: 30, viewIds: ["main"] },
      onTileError: () => {},
      onHover: ({ x, y, object }) => {
        // object && setSelect(object.properties.index);
        // object && setHoveredBuildings(object);
        // object && console.log(object.properties);
      },
      onClick: ({ x, y, object }) => {
        // object && setSelect(object.properties.index);
        // object && setSelected(object);
      },
      uniqueIdProperty: "index",
      autoHighlight: true,
      renderSubLayers: (props) => {
        return [
          new GeoJsonLayer(props, {
            wireframe: false,
            uniqueIdProperty: "index",
            // highlightedFeatureId: select,
            highlightColor: [255, 255, 255, 255],
            autoHighlight: true,
            pickable: pickable,
            opacity: 0.9,
            stroked: true,
            filled: true,
            extruded: true,
            getElevation: (f) => f.properties.heightmax,
            getLineColor: [0, 0, 0, 255],
            // getFillColor: [255, 255, 255, 200],
            getFillColor: [255, 200, 200, 200],
            lineWidthMaxPixels: 1,
          }),
        ];
      },
    }),
  };
};

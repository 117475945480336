import React, { useEffect, useState, useCallback } from "react";
import styles from "./switcher.module.less";
import { Switch, Typography } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { controlStore } from "../../store/store";

const labelOn = "hide all";
const labelOff = "show all";

export default function Pickable(props) {
  //------------------ LOCAL STATES ------------------
  //------------------ SHARED STATES ------------------
  // const hideAll = controlStore((state) => state.hideAll);
  // const setHideAll = controlStore((state) => state.setHideAll);

  //------------------ SIDE EFFECTS -----------------

  return (
    <div
      className={
        (props.active ? styles.wrapper : styles.wrapper) + " " + styles.verticalAlign
      }
    >
      <Switch
        size='small'
        defaultChecked={props.active}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(e) => {
          props.setter(e);
          console.log(props.active);
        }}
      />
      <Typography.Text>{props.active ? labelOff : labelOn}</Typography.Text>
    </div>
  );
}

import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { controlStore, systemStore, statsStore } from "../../../../store/store";
import { getPublicTrees } from "./sublayers";

import { useLazyQuery } from "@apollo/client";
import { dataSplitbyGeom } from "../../helpers";
import { TreeQuery, AirQuery } from "../../queries";

export default function energyObject(props) {
  //------------------ LOCAL STATES ------------------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [layers, setLayers] = useState({});
  const [limitTrees, setLimitTrees] = useState(5000);
  const [lastFetchedLength, setLastFetchedLength] = useState(0);
  const [subLayers, setSubLayers] = useState({});
  const [resultsTrees, setResultsTrees] = useState([]);

  //------------------ SHARED STATES ------------------
  const layersPickable = controlStore((state) => state.layersPickable);
  const layersShown = controlStore((state) => state.layersShown);
  const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const setIsLoading = systemStore((state) => state.setIsLoading);

  //------------------ FUNCTIONS ------------------

  //------------------ FUNCTIONS ------------------
  //------------------ HOOKS ------------------
  const [fetchFuncTree, fetchObjTree] = useLazyQuery(TreeQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
    variables: { offset: 0, limit: limitTrees },
    notifyOnNetworkStatusChange: true,
  });
  const [fetchFuncAir, fetchObjAir] = useLazyQuery(AirQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
  });

  //------------------ SIDE EFFECTS ------------------
  useEffect(() => {
    if (fetchObjTree.loading || fetchObjAir.loading) {
      setIsLoading("main", true);
    } else {
      setIsLoading("main", false);
    }
  }, [fetchObjTree.loading, fetchObjAir.loading]);

  // fetch more after 
  useEffect(() => {
    if (fetchObjTree.data && lastFetchedLength!==resultsTrees.length) {
      setLastFetchedLength(resultsTrees.length)
      if (layersShown["ecosystem_publictrees"]) {
        fetchObjTree
          .fetchMore({
            fetchMoreOptions: {
              variables: { limit: limitTrees, offset: resultsTrees.length },
              notifyOnNetworkStatusChange: true,
            },
          })
          .then((d) => {
            let res = [...resultsTrees, ...d.data.trees_public];
            setResultsTrees(_.uniqBy(res, 'index'));
          });
      }
    }
  }, [resultsTrees]);

  // initial filling of first batch of data
  useEffect(() => {
    if (fetchObjTree.data && resultsTrees.length === 0) {
      setResultsTrees(fetchObjTree.data.trees_public);
    }
  }, [fetchObjTree.data]);

  // initial ftriggering of lazy fetch func
  useEffect(() => {
    if (!fetchObjTree.data) {
      if (layersShown["ecosystem_publictrees"]) {
        console.log("fetchFuncTree Triggered");
        fetchFuncTree();
      }
    }
    if (!fetchObjAir.data) {
      if (layersShown["ecosystem_airquality"]) {
        console.log("fetchFuncAir Triggered");
        fetchFuncAir();
      }
    }
  }, [layersShown]);

  useEffect(() => {
    let layersObj = {};
    if (fetchObjTree.data && layersShown["ecosystem_publictrees"]) {
      let dataObj = dataSplitbyGeom({ trees_public: resultsTrees });
      layersObj = {
        ...layersObj,
        ...getPublicTrees(
          dataObj,
          setHoveredBuildings,
          setSubLayers,
          layersPickable["ecosystem_publictrees"]
        ),
        ...subLayers,
      };
    }
    if (fetchObjAir.data && layersShown["ecosystem_airquality"]) {
      let dataObj = dataSplitbyGeom(fetchObjAir.data);
      layersObj = {
        ...layersObj,
        ...getAirQuality(
          dataObj,
          setHoveredBuildings,
          layersPickable["ecosystem_airquality"]
        ),
      };
    }

    // update layers on any change
    props.setLayer(_.values(layersObj));
    // props.setLayer(_.values(expandSublayers(layers)));
  }, [resultsTrees, fetchObjAir.data, layersShown, layersPickable, subLayers]);

  return null;
}

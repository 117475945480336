import { project32, picking } from "@deck.gl/core";
import GL from "@luma.gl/constants";
import { Model, Geometry } from "@luma.gl/core";
import { vs, fs } from "../shaders/costum-scatter-shader"
import {Layer} from '@deck.gl/core';

const DEFAULT_COLOR = [0, 0, 0, 255];

const defaultProps = {
  radiusUnits: "meters",
  radiusScale: { type: "number", min: 0, value: 1 },
  radiusMinPixels: { type: "number", min: 0, value: 0 }, //  min point radius in pixels
  radiusMaxPixels: { type: "number", min: 0, value: Number.MAX_SAFE_INTEGER }, // max point radius in pixels

  lineWidthUnits: "meters",
  lineWidthScale: { type: "number", min: 0, value: 1 },
  lineWidthMinPixels: { type: "number", min: 0, value: 0 },
  lineWidthMaxPixels: {
    type: "number",
    min: 0,
    value: Number.MAX_SAFE_INTEGER,
  },

  stroked: false,
  filled: true,

  getPosition: { type: "accessor" },
  getPositionNext: { type: "accessor" },
  getTimeStamp: { type: "accessor" },
  getTimeStampNext: { type: "accessor" },
  getRadius: { type: "accessor", value: 1 },
  getFillColor: { type: "accessor", value: DEFAULT_COLOR },
  getLineColor: { type: "accessor", value: DEFAULT_COLOR },
  getLineWidth: { type: "accessor", value: 1 },

  // deprecated
  strokeWidth: { deprecatedFor: "getLineWidth" },
  outline: { deprecatedFor: "stroked" },
  getColor: { deprecatedFor: ["getFillColor", "getLineColor"] },
};



class ScatterplotLayer extends Layer {
  getShaders() {
    return super.getShaders({ vs, fs, modules: [project32, picking] });
  }

  initializeState() {
    const { gl } = this.context;

    this.setState({ model: this._getModel(gl) });
    this.getAttributeManager().addInstanced({
      instancePositions: {
        size: 3,
        type: GL.DOUBLE,
        fp64: this.use64bitPositions(),
        transition: true,
        accessor: "getPosition",
      },
      instanceRadius: {
        size: 1,
        transition: true,
        accessor: "getRadius",
        defaultValue: 1,
      },
      instanceFillColors: {
        size: this.props.colorFormat.length,
        transition: true,
        normalized: true,
        type: GL.UNSIGNED_BYTE,
        accessor: "getFillColor",
        defaultValue: [0, 0, 0, 255],
      },
      instanceLineColors: {
        size: this.props.colorFormat.length,
        transition: true,
        normalized: true,
        type: GL.UNSIGNED_BYTE,
        accessor: "getLineColor",
        defaultValue: [0, 0, 0, 255],
      },
      instanceLineWidths: {
        size: 1,
        transition: true,
        accessor: "getLineWidth",
        defaultValue: 1,
      },
    });
  }

  updateState({ props, oldProps, changeFlags }) {
    super.updateState({ props, oldProps, changeFlags });
    if (changeFlags.extensionsChanged) {
      const { gl } = this.context;
      if (this.state.model) {
        this.state.model.delete();
      }
      this.setState({ model: this._getModel(gl) });
      this.getAttributeManager().invalidateAll();
    }
  }

  draw({ uniforms }) {
    const { viewport } = this.context;
    const {
      radiusUnits,
      radiusScale,
      radiusMinPixels,
      radiusMaxPixels,
      stroked,
      filled,
      lineWidthUnits,
      lineWidthScale,
      lineWidthMinPixels,
      lineWidthMaxPixels,
    } = this.props;

    const pointRadiusMultiplier =
      radiusUnits === "pixels" ? viewport.metersPerPixel : 1;
    const lineWidthMultiplier =
      lineWidthUnits === "pixels" ? viewport.metersPerPixel : 1;

    this.state.model
      .setUniforms(uniforms)
      .setUniforms({
        stroked: stroked ? 1 : 0,
        filled,
        radiusScale: radiusScale * pointRadiusMultiplier,
        radiusMinPixels,
        radiusMaxPixels,
        lineWidthScale: lineWidthScale * lineWidthMultiplier,
        lineWidthMinPixels,
        lineWidthMaxPixels,
      })
      .draw();
  }

  _getModel(gl) {
    // a square that minimally cover the unit circle
    // const positions = [-1, -1, 0, 1, -1, 0, 3, 0, 0, 1, 1, 0, -1, 1, 0];
    const positions = [-1, -1, 0, 1, -1, 0, 1, 0, 0, 1, 1, 0, -1, 1, 0];

    return new Model(
      gl,
      Object.assign(this.getShaders(), {
        id: this.props.id,
        geometry: new Geometry({
          drawMode: GL.TRIANGLE_FAN,
          vertexCount: 5,
          attributes: {
            positions: { size: 3, value: new Float32Array(positions) },
          },
        }),
        isInstanced: true,
      })
    );
  }
}

ScatterplotLayer.layerName = "ScatterplotLayer";
ScatterplotLayer.defaultProps = defaultProps;


export default ScatterplotLayer






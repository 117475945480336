import GL from "@luma.gl/constants";
import { shader } from "../shaders/moving-scatter-shader.js";
import ScatterplotLayer from "./costum-scatterplot"


const DEFAULT_COLOR = [0, 0, 0, 255];

const defaultProps = {
  radiusUnits: "meters",
  radiusScale: { type: "number", min: 0, value: 1 },
  radiusMinPixels: { type: "number", min: 0, value: 0 }, //  min point radius in pixels
  radiusMaxPixels: { type: "number", min: 0, value: Number.MAX_SAFE_INTEGER }, // max point radius in pixels

  lineWidthUnits: "meters",
  lineWidthScale: { type: "number", min: 0, value: 1 },
  lineWidthMinPixels: { type: "number", min: 0, value: 0 },
  lineWidthMaxPixels: {
    type: "number",
    min: 0,
    value: Number.MAX_SAFE_INTEGER,
  },

  stroked: false,
  filled: true,

  getPosition: { type: "accessor" },
  getPositionNext: { type: "accessor" },
  getTimeStamp: { type: "accessor" },
  getTimeStampNext: { type: "accessor" },
  getRadius: { type: "accessor", value: 1 },
  getFillColor: { type: "accessor", value: DEFAULT_COLOR },
  getLineColor: { type: "accessor", value: DEFAULT_COLOR },
  getLineWidth: { type: "accessor", value: 1 },

  // deprecated
  strokeWidth: { deprecatedFor: "getLineWidth" },
  outline: { deprecatedFor: "stroked" },
  getColor: { deprecatedFor: ["getFillColor", "getLineColor"] },
};


export default class CustomTripsLayer extends ScatterplotLayer {
  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = shader;
    return shaders;
  }

  initializeState(params) {
    super.initializeState(params);
    // const noAlloc = true;
    const attributeManager = this.getAttributeManager();
    attributeManager.addInstanced({
      instancePositionsNext: {
        size: 3,
        type: GL.DOUBLE,
        fp64: this.use64bitPositions(),
        transition: true,
        accessor: "getPositionNext",
      },
      instanceTimestamps: {
        size: 1,
        transition: true,
        accessor: "getTimeStamp",
        defaultValue: 1,
      },
      instanceNextTimestamps: {
        size: 1,
        transition: true,
        accessor: "getTimeStampNext",
        defaultValue: 1,
      },
    });
  }

  updateState({ oldProps, props, changeFlags }) {
    super.updateState({ props, oldProps, changeFlags });
  }

  draw(params) {
    const { trailLength, currentTime } = this.props;

    params.uniforms = Object.assign({}, params.uniforms, {
      trailLength,
      currentTime,
    });
    super.draw(params);
  }
}

CustomTripsLayer.layerName = "CustomTripsLayer";
CustomTripsLayer.defaultProps = defaultProps;

import React, { useEffect, useState } from 'react'
import styles from './styles.module.less'
import { controlStore, panelsStore, statsStore } from '../../../store/store'
import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_SERVER_ENDPOINT_URL}`

export default function Eplus() {
  //------------------ LOCAL STATES ------------------
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(null)
  const [idfDownloadPath, setIdfDownloadPath] = useState(null)
  //------------------ SHARED STATES ------------------
  const setActiveServices = panelsStore((state) => state.setActiveServices)
  const activeServices = panelsStore((state) => state.activeServices)
  const setLayersShown = controlStore((state) => state.setLayersShown)
  const selectedBuildings = statsStore((state) => state.selectedBuildings)

  //------------------ Functions ------------------
  const handleIdfGenBtn = (e) => {
    e.preventDefault()
    setLoading(true)
    setValues(null)
    setIdfDownloadPath(null)
    handleSubmit()
  }

  const handleIdfDownloadBtn = (e) => {
    var url = `${API_URL}/download/idf`
    const headers = {
      'content-type': 'application/json',
    }

    axios
      .post(
        url,
        { path: idfDownloadPath },
        {
          headers: headers,
          responseType: 'blob',
        },
      )
      .then((response) => {
        let blob = new Blob([response.data], {
            type: 'text/plain',
          }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = '',
          disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (values !== null) {
      setLoading(false)
    }
  }, [values])

  const handleSubmit = () => {
    var url = `${API_URL}/analysis/idf`
    let payload = selectedBuildings ? selectedBuildings : {}
    axios
      .post(url, payload)
      .then((response) => {
        setValues(response.data)
        setIdfDownloadPath(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //------------------ SIDE EFFECTS ------------------

  return (
    <>
      {activeServices.eplusTool && (
        <div className={styles.widgetContainer}>
          <div className={styles.widgetDescription + ' ' + styles.descriptionParagraph}>
            Generate IDF file for an Energy Plus simulation on a selected building.
          </div>
          <div className={styles.widgetDescription + ' ' + styles.descriptionParagraph}>
            Please select a building and press the generate button.
          </div>
          {selectedBuildings.target && (
            <div className={styles.selectionSummary}>
              <table>
                <tr>
                  <td>Index: </td>
                  <td>{selectedBuildings.target.index}</td>
                </tr>
                <tr>
                  <td>Year: </td>
                  <td>{selectedBuildings.target.year_built === 9999 ? 'N/A' : selectedBuildings.target.year_built}</td>
                </tr>
                <tr>
                  <td>Height: </td>
                  <td>{selectedBuildings.target.height_max && selectedBuildings.target.height_max + ' m'}</td>
                </tr>
                <tr>
                  <td>UID: </td>
                  <td>{selectedBuildings.target.uid}</td>
                </tr>
              </table>
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <button
              type="button"
              className={styles.generateIdfBtn + ' ' + (loading ? styles.loading : '')}
              onClick={handleIdfGenBtn}
            >
              {loading ? (
                <span className={styles.generateIdfBtnTxt}>.. processing ..</span>
              ) : (
                <span className={styles.generateIdfBtnTxt}>Generate IDF</span>
              )}
            </button>
            {values !== null && (
              <button type="button" className={styles.downloadIdfBtn} onClick={handleIdfDownloadBtn}>
                <span className={styles.generateIdfBtnTxt}>Download</span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

import { MVTLayer } from "@deck.gl/geo-layers";



export default class CustomMVTLayer extends MVTLayer {
  initializeState(props) {
    super.initializeState(props);
    // console.log('props: ', props)
  }

  renderLayers() {
    return null;
  }

  renderSubLayers(props) {
    return null;
  }

}


CustomMVTLayer.layerName = "CustomMVTLayer";

import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  controlStore,
  systemStore,
  statsStore,
  toolboxStore,
  servicesStore,
} from "../../../../../store/store";
import { getBuildings } from "./sublayers";

import { useLazyQuery } from "@apollo/client";
import { dataSplitbyGeom } from "../../../helpers";
import { EVQueryMod, EVQuery } from "../../../queries";

import { gql } from "@apollo/client";

const cQuery = gql`
  query building($polygon: geometry) {
    building_footprint_year_built_height_max(where: { geom: { _st_within: $polygon } }) {
      index
      height_max
      geom
      year_built
    }
  }
`;

export default function energyObject(props) {
  //------------------ LOCAL STATES ------------------
  const [queryOptions, setQueryOptions] = useState({});
  const [selectBldg, setSelectBldg] = useState(undefined);
  const [selectItem, setSelectItem] = useState(undefined);

  //------------------ SHARED STATES ------------------
  const layersPickable = controlStore((state) => state.layersPickable);
  const layersShown = controlStore((state) => state.layersShown);
  const hoveredBuildings = statsStore((state) => state.hoveredBuildings);
  const toolboxGeoJson = toolboxStore((state) => state.toolboxGeoJson);
  const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const setIsLoading = systemStore((state) => state.setIsLoading);
  const setRetrofitSelectedData = servicesStore(
    (state) => state.setRetrofitSelectedData
  );
  const chartHoveredItems = statsStore((state) => state.chartHoveredItems);

  //------------------ FUNCTIONS ------------------

  //------------------ HOOKS ------------------
  const [fetchFuncEV, fetchObjEv] = useLazyQuery(cQuery, queryOptions);

  //------------------ SIDE EFFECTS ------------------

  useEffect(() => {
    // TODO: Add Multipolygon support
    if (toolboxGeoJson.features.length > 0) {
      setQueryOptions({
        variables: {
          polygon: {
            crs: {
              type: "name",
              properties: { name: "EPSG:4326" },
            },
            type: "Polygon",
            coordinates: toolboxGeoJson.features[0].geometry.coordinates,
          },
        },
        fetchPolicy: "cache-first", // checks cache before making a network request
      });
    }
  }, [toolboxGeoJson.features]);

  useEffect(() => {
    if (fetchObjEv.loading) {
      setIsLoading("main", true);
    } else {
      setIsLoading("main", false);
    }
  }, [fetchObjEv.loading]);

  useEffect(() => {
    if (!fetchObjEv.data && toolboxGeoJson.features.length > 0) {
      if (layersShown["built_buildingsretrofit"]) {
        console.log("fetchFunc Triggered");
        fetchFuncEV();
      }
    }
  }, [layersShown, queryOptions]);

  useEffect(() => {
    let layersObj = {};
    if (
      fetchObjEv.data &&
      layersShown["services"] &&
      layersShown["built_buildingsretrofit"]
    ) {
      let dataObj = dataSplitbyGeom(fetchObjEv.data);
      layersObj = {
        ...layersObj,
        ...getBuildings(
          dataObj,
          chartHoveredItems ? chartHoveredItems.index : -1,
          setHoveredBuildings,
          layersPickable["built_buildingsretrofit"],
        ),
      };
      setRetrofitSelectedData(dataObj.building_footprint_year_built_height_max.Polygon);
    }
    // update layers on any change
    props.setLayer(layersObj);
  }, [fetchObjEv.data, layersShown, layersPickable, queryOptions]);

  useEffect(() => {
    let layersObj = {};
    if (
      fetchObjEv.data &&
      layersShown["services"] &&
      layersShown["built_buildingsretrofit"]
    ) {
      let dataObj = dataSplitbyGeom(fetchObjEv.data);
      
      layersObj = {
        ...layersObj,
        ...getBuildings(
          dataObj,
          chartHoveredItems ? chartHoveredItems.index : -1,
          setHoveredBuildings,
          layersPickable["built_buildingsretrofit"],
        ),
      };
    }
    props.setLayer(layersObj);
  }, [chartHoveredItems]);


  // useEffect(() => {
  //   chartHoveredItems ? setSelectItem(chartHoveredItems) : setSelectItem(null);
  // }, [chartHoveredItems]);

  return null;
}

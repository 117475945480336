import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { controlStore, systemStore, statsStore } from "../../../../store/store";
import { getWasteCollectionZones, getWasteCollectionSites } from "./sublayers";

import { useLazyQuery } from "@apollo/client";
import { dataSplitbyGeom, expandSublayers } from "../../helpers";

import {wasteQuery} from "../../queries";


export default function wasteObject(props) {
  //------------------ LOCAL STATES ------------------
  // const [data, setData] = useState(undefined);
  // const [layers, setLayers] = useState({});

  //------------------ SHARED STATES ------------------
  const layersPickable = controlStore((state) => state.layersPickable);
  const layersShown = controlStore((state) => state.layersShown);
  const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const setIsLoading = systemStore((state) => state.setIsLoading);

  //------------------ FUNCTIONS ------------------
  //------------------ HOOKS ------------------
  const [fetchFunc, fetchObj] = useLazyQuery(wasteQuery, {
    fetchPolicy: "cache-first" // Doesn't check cache before making a network request
  })

  //------------------ SIDE EFFECTS ------------------
  useEffect(() => {
    if (fetchObj.loading) {
      setIsLoading("main", true)
    } else {
      setIsLoading("main", false)
    }

  }, [fetchObj.loading]);

  useEffect(() => {
    if (!fetchObj.data) {
      if (layersShown["waste_collection"] | layersShown["waste_sites"]) {
        // console.log("Fetch Func Triggered waste")
        fetchFunc()
      }
    }
  }, [layersShown]);

  useEffect(() => {
    if (fetchObj.data) {

      let dataObj = dataSplitbyGeom(fetchObj.data)

      const layersObj = {
        ...(layersShown["waste_collection"]
          ? {
            waste_collection_zones: getWasteCollectionZones(
              dataObj,
              setHoveredBuildings,
              layersPickable["waste_collection"]
            ),
          }
          : {}),
        ...(layersShown["waste_sites"]
          ? {
            waste_sites: getWasteCollectionSites(
              dataObj,
              setHoveredBuildings,
              layersPickable["waste_sites"]
            ),
          }
          : {}),
      };

      // update layers on any change
      let layers = expandSublayers(layersObj)
      props.setLayer(_.values(layers))
    }
  }, [fetchObj.data, layersShown, layersPickable]);


  return null
}

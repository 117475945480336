import React from 'react'
import styles from './title.module.less'
import { Divider } from 'antd'
import { MdOutlineLayers } from 'react-icons/md'
import { BiHomeAlt } from 'react-icons/bi'

export default function title() {
  return (
    <a
      className={styles.titleWrapper}
      key="back"
      href="https://www.citylayers.ca"
    >
      <div className={styles.title}>
        <div className={styles.titleStart}>Cities</div>
        <div className={styles.titleEnd}>Layers</div>
        <MdOutlineLayers className={styles.layersLogo} />
        <BiHomeAlt className={styles.cityLogo} />
        {/* <Divider type='vertical' /> */}
      </div>
    </a>
  )
}

import React, { useState, useEffect } from 'react'
import SlideMenu from './menu'
import SlideBody from './body'
import { panelsStore } from '../../../store/store'

export default function Index() {
  const [activeMenu, setActiveMenu] = useState(null)

  const setActiveServices = panelsStore((state) => state.setActiveServices)
  const activeServices = panelsStore((state) => state.activeServices)

  useEffect(() => {
    if (activeMenu !== 'IDF Generator' && activeServices.eplusTool) {
      setActiveServices('eplusTool', false)
    }
    if (activeMenu == 'IDF Generator' && !activeServices.eplusTool) {
      setActiveServices('eplusTool', true)
    }
  }, [activeMenu])

  return (
    <>
      <SlideBody setActiveMenu={setActiveMenu} activeMenu={activeMenu} />
    </>
  )
}

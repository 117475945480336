import React, { useRef, useState, useEffect } from "react";
import ThreeCanvas from "./three-canvas";
import styles from "./styles.module.less";
import { servicesStore, statsStore } from "../../../store/store";


export default function index() {
  const selectedBuildings = statsStore((state) => state.selectedBuildings);

  return (
    <div className={styles.container}>
      <div className={styles.canvas}>
        <ThreeCanvas selection={selectedBuildings}/>
      </div>
      <div className={styles.subSection}>vjdnghg</div>
    </div>
  );
}

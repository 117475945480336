

export const handleClickReverse = (
  dataSpeed,
  clicked,
  lastState,
  setDataIndex,
  setDataSpeed,
  setClicked,
  setLastState
) => {
  setDataIndex(0);
  setDataSpeed(1);
  setClicked({ reverse: true, play: false, pause: false, faster: false });
  setTimeout(
    () =>
      {
        setClicked({ reverse: false, play: true, pause: false, faster: false })
      },
    3000
  );
  setLastState({ speed: dataSpeed, clicked: clicked });
}

export const handleClickPause = (
  dataSpeed,
  clicked,
  lastState,
  setDataSpeed,
  setClicked,
  setLastState
) => {
  if (dataSpeed === 0) {
    //untoggling the key
    setDataSpeed(lastState.speed);
    setClicked(lastState.clicked);
  } else {
    //toggling the key
    setLastState({ speed: dataSpeed, clicked: clicked });
    setDataSpeed(0);
    setClicked({ reverse: false, play: false, pause: true, faster: false });
  }
}

export const handleClickPlay = (
  dataSpeed,
  clicked,
  setDataSpeed,
  setClicked,
  setLastState
) => {
  setDataSpeed(1);
  setClicked({ reverse: false, play: true, pause: false, faster: false });
  setLastState({ speed: dataSpeed, clicked: clicked });
}

export const handleClickFaster = (
  dataSpeed,
  clicked,
  setDataSpeed,
  setClicked,
  setLastState
) => {
  setDataSpeed(dataSpeed * 2 > 1000 ? dataSpeed : dataSpeed * 2);
  setClicked({ reverse: false, play: false, pause: false, faster: true });
  setLastState({ speed: dataSpeed, clicked: clicked });
}


import React, { useState } from 'react'
import styles from './styles.module.less'
import Info from '../info'
import ThreeDView from '../threedview'
import EplusTool from '../eplus'
import RCModel from '../rcmodel'
import { Modal } from 'antd'
import { panelsStore } from '../../../store/store'

export default function SlideBody({ activeMenu, setActiveMenu }) {
  const [selectedService, setSelectedService] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const showServicesPanels = panelsStore((state) => state.showServicesPanels)

  if (!showServicesPanels) {
    return null
  }
  // TODO: This is a temporary mock for the menu. It will be replaced with a dynamic menu.
  const servicesList = [
    {
      key: 'Info and Data',
      label: 'Info and Data',
      children: [{ label: 'Building Info', key: 'Building Info' }],
    },
    {
      key: 'Energy Demand',
      label: 'Energy Demand',
      children: [
        { label: 'IDF Generator', key: 'IDF Generator' },
        // { label: 'RC Model', key: 'rc' },
      ],
    },
    {
      key: 'Retrofit Analysis',
      label: 'Retrofit Analysis',
      children: [
        { label: 'Single Building ROI', key: '3dview' },
        { label: 'Multiple Building ROI', key: 'Multiple Building ROI' },
        { label: 'Regional Scenarios', key: 'Regional Scenarios' },
      ],
    },
    {
      key: 'Energy System Optimizer',
      label: 'Energy System Optimizer',
      children: [
        { label: 'Local Generation', key: 'eplus' },
        { label: 'Regional Generation', key: 'Regional Generation' },
        { label: 'Mapping Model', key: 'Mapping Model' },
      ],
    },
    {
      key: 'DCHN',
      label: 'DCHN',
      children: [
        { label: 'Cost Feasibility', key: 'Cost Feasibility' },
        { label: 'Network Solution', key: 'Network Solution' },
      ],
    },
  ]

  const handleModalClose = () => {
    setShowModal(false)
    handleClose()
  }

  const handleClick = (e, subServiceKey, serviceLabel) => {
    e.preventDefault()
    setActiveMenu(subServiceKey)
    setSelectedService(serviceLabel)
    if (subServiceKey !== 'Building Info' && subServiceKey !== '3dview' && subServiceKey !== 'rc' && subServiceKey !== 'IDF Generator') {
      setShowModal(true)
    }
  }

  const handleClose = () => {
    setActiveMenu(null)
    setSelectedService(null)
    setShowModal(false)
  }
  return (
    <div className={styles.slideBody}>
      {activeMenu === null ? (
        <div>
          <ul className={styles.sidebarMainServices}>
            {servicesList.map((service) => (
              <li>
                {service.label}
                {service && service.children && (
                  <ul className={styles.sidebarSecondaryServices}>
                    {service.children.map((child) => (
                      <li onClick={(e) => handleClick(e, child.key, service.label)}>{child.label}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles.menuBody}>
          <h4>{activeMenu}</h4>
          {activeMenu === 'Building Info' && <Info />}
          {activeMenu === '3dview' && <ThreeDView />}
          {activeMenu === 'rc' && <RCModel />}
          {activeMenu === 'IDF Generator' && <EplusTool />}
          <button className={styles.slideBtn} onClick={handleClose}>
            Back
          </button>
        </div>
      )}
      <Modal visible={showModal} onCancel={handleModalClose} footer={null} closable />
    </div>
  )
}

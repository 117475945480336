import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
} from "../../common/common-sublayers";
import _ from "lodash";
import chroma from "chroma-js";

//------------------ VARIABLES ------------------

const scale = chroma.scale(["cyan", "red"]);
const brewer = chroma.brewer.RdYlBu.map((el) => chroma(el).rgb()).reverse();

const hoverOptions = {
  power_grid: ["__typename"],
  ev_stations: ["index", "name", "__typename"],
};
//------------------ FUNCTIONS ------------------
const handleHover = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
};
const handleClick = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "click",
      ..._.omit(obj, ["geom"]),
    });
};
//------------------ Layer Specific Options ------------------

const iconLayerOptions = {
  getPosition: (d) => [...d.geom.coordinates, 5],
  sizeMinPixels: 5,
  sizeMaxPixels: 40,
  getSize: (d) => 25,
  sizeScale: 1,
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
      anchorY: 16,
      mask: true,
    },
  },
};
const lineLayerOptions = {
  id: "power_grid_line",
  getColor: (d) =>
    d.voltage
      ? brewer[Math.round((d.voltage / 750000) * 8)]
      : [111, 111, 111, 255],
};
const polyLayerOptions = {};
const cubeLayerOptions = {};
const pointLayerOptions = {};

//------------------ Functions ------------------

export const getPowerGrid = (data, setHoveredBuildings, pickable = false) => ({
  power_grid_points: pointLayer(data.power_grid.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "power_grid_points",
    viewProps: { zoomMin: 10, zoomMax: 13, viewIds: ["main"] },
    getFillColor: [222, 222, 222, 222],
    radiusScale: 1,
    radiusMinPixels: 1,
    radiusMaxPixels: 10,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  power_grid_icons: iconLayer(data.power_grid.point, {
    ...iconLayerOptions,
    pickable: pickable,
    id: "power_grid_icons",
    viewProps: { zoomMin: 13, zoomMax: 20, viewIds: ["main"] },
    getColor: [233, 216, 166, 222],
    iconAtlas: "https://img.icons8.com/ios/50/000000/transmission-tower.png",
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
        anchorY: 25,
        mask: true,
      },
    },
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  power_grid_line_near: lineLayer(data.power_grid.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "power_grid_line_near",
    viewProps: { zoomMin: 10, zoomMax: 20, viewIds: ["main"] },
    widthMaxPixels: 5,
    widthScale: 7,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  power_grid_line_far: lineLayer(data.power_grid.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "power_grid_line_far",
    viewProps: { zoomMin: 0, zoomMax: 10, viewIds: ["main"] },
    widthMaxPixels: 100,
    widthScale: 42,
    getWidth: (d) => (d.voltage ? Math.round((d.voltage / 750000) * 15) : 2),
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
  power_grid_Polygon: polygonLayer(data.power_grid.Polygon, {
    ...polyLayerOptions,
    pickable: pickable,
    id: "power_grid_Polygon",
    viewProps: { zoomMin: 9, zoomMax: 20, viewIds: ["main"] },
    getFillColor: [181, 23, 158, 255],
    getLineColor: [181, 23, 108, 255],
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
  }),
});

export const getEvStations = (data, setHoveredBuildings, pickable = false) => ({
  ev_stations_icons: iconLayer(data.ev_stations.point, {
    ...iconLayerOptions,
    pickable: pickable,
    id: "ev_stations_icons",
    getColor: [52, 93, 242, 222],
    viewProps: { zoomMin: 13, zoomMax: 16, viewIds: ["main"] },
    iconAtlas:
      "https://img.icons8.com/windows/32/000000/park-and-charge--v2.png",
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.ev_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.ev_stations),
  }),
  ev_stations_points: pointLayer(data.ev_stations.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "ev_stations_points",
    viewProps: { zoomMin: 8, zoomMax: 13, viewIds: ["main"] },
    getFillColor: [52, 93, 242, 222],
    radiusScale: 1,
    radiusMinPixels: 3,
    radiusMaxPixels: 100,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.ev_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.ev_stations),
  }),
  ev_stations_cubes: cubeLayer(data.ev_stations.point, {
    ...cubeLayerOptions,
    pickable: pickable,
    viewProps: { zoomMin: 16, zoomMax: 20, viewIds: ["main"] },
    id: "ev_stations_cubes",
    getColor: [52, 93, 242, 222],
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.ev_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.ev_stations),
  }),
});
export const getGasStations = (data, setHoveredBuildings, pickable = false) => ({
  gas_stations_icons: iconLayer(data.gas_stations.point, {
    ...iconLayerOptions,
    pickable: pickable,
    id: "gas_stations_icons",
    viewProps: { zoomMin: 13, zoomMax: 16, viewIds: ["main"] },
    getColor: [129, 178, 154, 222],
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 24,
        height: 24,
        anchorY: 12,
        mask: true,
      },
    },
    iconAtlas:
      "https://img.icons8.com/external-those-icons-lineal-those-icons/24/000000/external-gas-station-construction-industry-those-icons-lineal-those-icons-1.png",
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.gas_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.gas_stations),
  }),
  gas_stations_points: pointLayer(data.gas_stations.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "gas_stations_points",
    viewProps: { zoomMin: 8, zoomMax: 13, viewIds: ["main"] },
    getFillColor: [129, 178, 154, 222],
    radiusScale: 1,
    radiusMinPixels: 3,
    radiusMaxPixels: 100,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.gas_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.gas_stations),
  }),
  gas_stations_cubes: cubeLayer(data.gas_stations.point, {
    ...cubeLayerOptions,
    pickable: pickable,
    viewProps: { zoomMin: 16, zoomMax: 20, viewIds: ["main"] },
    id: "gas_stations_cubes",
    getColor: [129, 178, 154, 222],
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.gas_stations),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.gas_stations),
  }),
});

export const getOilGas = (data, setHoveredBuildings, pickable = false) => ({
  oilgas_installments_points: pointLayer(data.oilgas_installments.point, {
    ...pointLayerOptions,
    pickable: pickable,
    id: "oilgas_installments_points",
    viewProps: { zoomMin: 8, zoomMax: 10, viewIds: ["main"] },
    getFillColor: [72, 149, 239, 255],
    radiusScale: 1,
    radiusMinPixels: 1,
    radiusMaxPixels: 10,
    getPosition: (d) => d.geom.coordinates,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
  }),
  oilgas_installments_icons: iconLayer(data.oilgas_installments.point, {
    ...iconLayerOptions,
    pickable: pickable,
    id: "oilgas_installments_icons",
    viewProps: { zoomMin: 10, zoomMax: 20, viewIds: ["main"] },
    getColor: [72, 149, 239, 200],
    iconAtlas: "https://img.icons8.com/ios-glyphs/30/000000/gas.png",
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 30,
        height: 30,
        anchorY: 15,
        mask: true,
      },
    },
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
  }),
  oilgas_installments_line_near: lineLayer(data.oilgas_installments.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "oilgas_installments_line_near",
    viewProps: { zoomMin: 10, zoomMax: 20, viewIds: ["main"] },
    widthMaxPixels: 5,
    widthScale: 1,
    getWidth: (d) => 25,
    getColor: [72, 149, 239, 200],
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
  }),
  oilgas_installments_line_far: lineLayer(data.oilgas_installments.line, {
    ...lineLayerOptions,
    pickable: pickable,
    id: "oilgas_installments_line_far",
    viewProps: { zoomMin: 0, zoomMax: 10, viewIds: ["main"] },
    widthMaxPixels: 10,
    widthScale: 3,
    getWidth: (d) => 25,
    getColor: [72, 149, 239, 200],
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
  }),
  oilgas_installments_Polygon: polygonLayer(data.oilgas_installments.Polygon, {
    ...polyLayerOptions,
    pickable: pickable,
    id: "oilgas_installments_Polygon",
    viewProps: { zoomMin: 0, zoomMax: 20, viewIds: ["main"] },
    getFillColor: [72, 149, 239, 100],
    getLineColor: [72, 149, 239, 100],
    stroked: false,
    onHover: (e) =>
      handleHover(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
    onClick: (e) =>
      handleClick(e.object, setHoveredBuildings, hoverOptions.oilgas_installments),
  }),
});

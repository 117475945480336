import React, { useState, useEffect, useRef } from "react";
import { readTextFile } from "../../utils/helpers";
import Layers from "../layers/layers";
import Topbar from "../topbar";
import WebFont from 'webfontloader';
// import InfoPanels from "../panels/info";
import LoadingSpinner from "../loading/loading-spinner";
import HideSwitch from "./switcher";
import {
  panelsStore,
  dataStore,
  systemStore,
  controlStore,
} from "../../store/store";
import styles from "./styles.module.less";
import { Layout } from "antd";
import LayerManager from "../layer-manager";
import SettingsPanel from "../panels/settings";
import Panels from "../panels";
import BasemapSwitcher from "./basemap-switcher";
import useMouse from "@react-hook/mouse-position";

const { Content } = Layout;

export default function Canvas() {
  const [hide, setHide] = useState(false);
  const mapRef = useRef(true);

  const setSettingsData = dataStore((state) => state.setSettingsData);
  const setMouseState = systemStore((state) => state.setMouseState);
  const hideAll = controlStore((state) => state.hideAll);
  const setHideAll = controlStore((state) => state.setHideAll);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Quicksand']
      }
    });
   }, []);

  const mouse = useMouse(mapRef, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  // SIDE EFFECTS
  useEffect(() => {
    readTextFile("./sumoDefaultOptions.json", function (text) {
      const jsonObject = JSON.parse(text);
      setSettingsData(jsonObject);
    });
  }, []);

  useEffect(() => {
    setMouseState(mouse);
  }, [mouse]);

  useEffect(() => {
    setHide(hideAll);
    // console.log(hideAll);
  }, [hideAll]);

  return (
    <>
      <Layout className={styles.costumLayout}>
        <LoadingSpinner />
        {!hide && <Topbar />}
        <Layout>{/* <Panels /> */}</Layout>
        <Content>
          <div ref={mapRef} className={styles.mapWrapper}>
            <Layers />
          </div>
        </Content>
        <LayerManager hide={hide} />
        {/* <InfoPanels hide={hide} /> */}
        <SettingsPanel hide={hide} />
        <Panels />
        <BasemapSwitcher hide={hide} />
        <div className={styles.hideSwitcher}>
          <HideSwitch active={hide} setter={setHide} />
        </div>
      </Layout>
    </>
  );
}

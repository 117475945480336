import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.less";
import * as d3 from "d3";
import _ from "lodash";

export default function chart({ data }) {
  const d3Element = useRef();
  const [values, setValues] = useState(undefined);
  const [keys, setKeys] = useState(undefined);

  useEffect(() => {
    setValues(_.values(data));
    setKeys(_.keys(data));
  }, [data]);

  useEffect(() => {
    if (values && keys) {
      let xy = []; // start empty, add each element one at a time
      for (let i = 0; i < keys.length; i++) {
        xy.push({ x: parseInt(keys[i]), y: parseInt(values[i]) });
      }
      // set the dimensions and margins of the graph
      let margin = { top: 10, right: 30, bottom: 30, left: 60 },
        width = 460 - margin.left - margin.right,
        height = 200 - margin.top - margin.bottom;
      // append the svg object to the body of the page
      let svg = d3
        .select(d3Element.current)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      // Add X axis
      let xscl = d3
        .scaleLinear()
        .domain([_.min(keys), _.max(keys)])
        .range([0, width]);
      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(xscl));
      // Add Y axis
      let yscl = d3
        .scaleLinear()
        .domain([_.min(values), _.max(values)])
        .range([height, 0]);
      svg.append("g").call(d3.axisLeft(yscl));

      // Add the line
      svg
        .append("path")
        .datum(xy)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return xscl(d.x);
            })
            .y(function (d) {
              return yscl(d.y);
            })
        );
    }
    return () => {
      d3.select(d3Element.current).select("svg").remove();
    };
  }, [values]);

  return (
    <div className={styles.chartWrapper}>
      <div className={styles.chartElement} ref={d3Element} />
    </div>
  );
}

import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { SocketContext } from "../../../../../../lib/socketClient";
import { hex2rgba } from "../../../../../../utils/helpers";
import { default as TripsLayer } from "./moving-scatterplot";
import _ from "lodash";
import {
  dataStore,
  controlStore,
  statsStore,
  panelsStore,
  systemStore,
} from "../../../../../../store/store";
import {
  useFetchData,
  useProcessFloatingData,
  useDataStats,
} from "../hooks/hooks";
import { CONSTANTS } from "../../../../common/settings";

const fetchDataOptions = {
  channelReq: "float_req",
  channelRes: "float_res",
  historyLen: 3,
  emissionIds: ["vts", "vname", "vlat", "vlon"],
};

export default function trafficObject(props) {
  //------------------ LOCAL STATES ------------------
  const [animation] = useState({});
  const [frame, setFrame] = useState(null);
  const [midPointRatio, setMidPointRatio] = useState(0);
  // const [selectedAgent, setSelectedAgent] = useState(new Set());
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [floatData, setFloatData] = useState({});
  const [currentFloatData, setCurrentFloatData] = useState([]);
  const [currentShownData, setCurrentShownData] = useState([]);

  //------------------ SHARED STATES ------------------
  const layersPickable = controlStore((state) => state.layersPickable);
  const agentCount = statsStore((state) => state.count);
  const setAgentCount = statsStore((state) => state.setCount);
  const floatingData = dataStore((state) => state.floatingData);
  const setFloatingData = dataStore((state) => state.setFloatingData);
  const selectedAgents = statsStore((state) => state.selectedAgents);
  const setSelectedAgents = statsStore((state) => state.setSelectedAgents);
  const agentColor = statsStore((state) => state.color);
  const agentIsShown = statsStore((state) => state.isShown);
  const agentSize = statsStore((state) => state.size);
  const dataSpeed = controlStore((state) => state.speed);
  const dataIndex = dataStore((state) => state.index);
  const setDataIndex = dataStore((state) => state.setIndex);
  // const bufferSize = systemStore((state) => state.bufferSize);
  const setBufferSize = systemStore((state) => state.setBufferSize);
  const isLoading = systemStore((state) => state.isLoading);
  const setIsLoading = systemStore((state) => state.setIsLoading);
  const showEmissionChart = panelsStore((state) => state.showEmissionChart);
  const setShowEmissionChart = panelsStore(
    (state) => state.setShowEmissionChart
  );
  const setEmissionData = panelsStore((state) => state.setEmissionData);

  //// VARIABLES & CONSTANTS
  var framesPerUpdate =
    1200 / (dataSpeed + 1) > 2 ? Math.round(500 / (dataSpeed + 1)) : 5;
  // var tempRatio;
  var loopLength = framesPerUpdate * CONSTANTS.INDEX_STEP * 1000;

  //// FUNCTIONS
  const animate = () => {
    setFrame((t) => (t + 1) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };

  //// CONTEXTS
  const socket = useContext(SocketContext);

  //// HOOKS
  // Fetch and buffer a data pack
  useFetchData(
    setFloatData,
    setBufferSize,
    setIsLoading,
    dataIndex,
    socket,
    dataSpeed,
    fetchDataOptions
  );
  // slice and process current data from buffered pack of data
  useProcessFloatingData(floatData, setCurrentFloatData, dataIndex);
  // Filter active agents and set their counts
  useDataStats(
    currentFloatData,
    setCurrentShownData,
    agentIsShown,
    setAgentCount
  );

  //// SIDE EFFECTS
  useEffect(() => {
    if (dataSpeed > 0) {
      let tempRatio = (frame % framesPerUpdate) / framesPerUpdate;
      // to guarantee MidPointRatio stays 0 and won't advance while new data comes in.
      // would have one or two frames missed without this mechanism
      if (frame % framesPerUpdate == framesPerUpdate - 1) {
        dataIndex < CONSTANTS.INDEX_LOOP_LENGTH - 2
          ? setDataIndex(dataIndex + CONSTANTS.INDEX_STEP)
          : setDataIndex(0);
      }
      setMidPointRatio(tempRatio);
    }

    // refresh layer object on every frame
    props.setLayer(layer);

    animation.id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animation.id);
  }, [frame]);

  useEffect(() => {
    setMidPointRatio(0);
    setFloatingData(currentShownData);
  }, [currentFloatData]);

  //// MAIN LAYER REPRESENTATION
  const layer = new TripsLayer({
    id: "vehicles",
    data: currentShownData,
    getPosition: (d) => [d.vlon, d.vlat],
    getPositionNext: (d) => [d.vlonNext, d.vlatNext],
    getTimeStamp: dataIndex,
    getTimeStampNext: dataIndex + 5,
    trailLength: null,
    currentTime: midPointRatio,
    getFillColor: (d) =>
      selectedAgents.size > 0 && selectedAgents.has(d.vname)
        ? [255, 255, 255, 255]
        : getFillColor(d.vclass, agentIsShown, agentColor),
    pickable: layersPickable.transport_flow,
    radiusMinPixels: 0.1,
    radiusMaxPixels: 4,
    getRadius: (d) =>
      selectedAgents.size > 0 && selectedAgents.has(d.vname)
        ? 200 * agentSize[d.vclass]
        : agentSize[d.vclass],
    stroked: 1,
    filled: 1,
    lineWidthScale: 1,
    lineWidthMinPixels: 0.1,
    lineWidthMaxPixels: 0.2,
    onClick: ({ x, y, object }) => {
      selectedAgents.has(object.vname)
        ? setSelectedAgents(object.vname, false)
        : setSelectedAgents(object.vname, true);
    },
    autoHighlight: true,
    highlightColor: [255, 255, 255],
    radiusScale: 1,
    updateTriggers: {
      getFillColor: selectedAgents.size > 0 ? [...selectedAgents] : null,
      getRadius: selectedAgents.size > 0 ? [...selectedAgents] : null,
    },
  });

  return null;
}

const getFillColor = (agentClass, agentIsShown, agentColor) => {
  return agentIsShown.has(agentClass)
    ? hex2rgba(agentColor[agentClass])
    : [255, 255, 255, 0];
};

import React, { useEffect, useState } from "react";
import { BsFillPlayFill as PlayIcon } from "react-icons/bs";
import { BsFillPauseFill as PauseIcon } from "react-icons/bs";
import { Button, Slider } from "antd";
import styles from "./slider-input.module.less";
import { Portal } from "react-portal";

export default function RangeInput({
  min,
  max,
  value,
  onChange,
  formatLabel,
  show,
  // playSpeed,
  // setPlaySpeed,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [animation] = useState({});
  const [playSpeed, setPlaySpeed] = useState(30);

  useEffect(() => {
    !show && setIsPlaying(false);
  }, [show]);
  useEffect(() => {
    // set default value of slider
    onChange([min, min + 0.1 * (max - min)]);
  }, []);
  // prettier-ignore
  useEffect(() => {
      return () => animation.id && cancelAnimationFrame(animation.id);
    }, [animation]);

  if (isPlaying && !animation.id) {
    const span = value[1] - value[0];
    let nextValueMin = value[0] + playSpeed;
    if (nextValueMin + span >= max) {
      nextValueMin = min;
    }
    animation.id = requestAnimationFrame(() => {
      // console.log(min, max, value)
      animation.id = 0;
      onChange([nextValueMin, nextValueMin + span]);
    });
  }
  const isButtonEnabled = show && value && (value[0] > min || value[1] < max);
  return (
    <Portal>
      <div className={styles.wrapper}>
        <Button
          disabled={!isButtonEnabled}
          onClick={() => {
            setIsPlaying(!isPlaying);
          }}
          size='small'
        >
          {isPlaying ? (
            <PauseIcon title='Stop' />
          ) : (
            <PlayIcon title='Animate' />
          )}
        </Button>
        <Slider
          disabled={!isButtonEnabled}
          min={min}
          max={max}
          // value={value[0] === value[1] ? [min, min + 0.1 * (max - min)] : value}
          value={value}
          range={{ draggableTrack: true }}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          tooltipVisible={show}
          tipFormatter={formatLabel}
        />
        <Slider
          className={styles.speedSlider}
          disabled={!isButtonEnabled}
          min={3}
          max={500}
          value={playSpeed}
          onChange={(newValue) => {
            setPlaySpeed(newValue);
          }}
          tipFormatter={(e) => <div>play speed</div>}
        />
      </div>
    </Portal>
  );
}

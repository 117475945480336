import { gql } from "@apollo/client";
import * as gqlBuilder from "gql-query-builder";

///////////////////////////////////////////////////////////////
/////// BUILT ENV QUERIES /////////////////////////////////////
///////////////////////////////////////////////////////////////

const building_footprint = {
  operation: "building_footprint_year_built_height_max",
  fields: ["index", "geom"],
  variables: { limit: 0 },
};

export const footprintQuery = gql(gqlBuilder.query(building_footprint).query);

///////////////////////////////////////////////////////////////
/////// WASTE QUERIES /////////////////////////////////////////
///////////////////////////////////////////////////////////////

const waste_collection = {
  operation: "waste_collection_zones",
  fields: [
    "geom",
    "ID",
    "TYPE_DECHET",
    "MESSAGE_EN",
    "amenity",
    "landuse",
    "note",
  ],
};

// const { query, variables } = gqlBuilder.query(waste_collection)
export const wasteQuery = gql(gqlBuilder.query(waste_collection).query);

///////////////////////////////////////////////////////////////
/////// ENERGY QUERIES ////////////////////////////////////////
///////////////////////////////////////////////////////////////

const energy_ev_stations = {
  operation: "ev_stations",
  fields: ["geom", "name", "index"],
};
export const EVQuery = gql(gqlBuilder.query(energy_ev_stations).query);

const energy_gas_stations = {
  operation: "gas_stations",
  fields: ["geom", "name"],
};
export const GasQuery = gql(gqlBuilder.query(energy_gas_stations).query);

const energy_oilgas_installments = {
  operation: "oilgas_installments",
  fields: ["geom", "index"],
};
export const oilGasQuery = gql(
  gqlBuilder.query(energy_oilgas_installments).query
);

const energy_power_grid = {
  operation: "power_grid",
  fields: ["geom", "voltage", "index"],
};
export const powerGridQuery = gql(gqlBuilder.query(energy_power_grid).query);

///////////////////////////////////////////////////////////////
/////// ECOSYSTEM QUERIES /////////////////////////////////////
///////////////////////////////////////////////////////////////

const ecosystem_trees = {
  operation: "trees_public",
  fields: ["index", "ESSENCE_ANG", "DHP", "geom"],
  variables: { limit: 0 },
};
export const TreeQuery = gql(gqlBuilder.query(ecosystem_trees).query);

const ecosystem_airquality = {
  operation: "air_quality",
  fields: ["geom", "name"],
};
export const AirQuery = gql(gqlBuilder.query(ecosystem_airquality).query);

///////////////////////////////////////////////////////////////
/////// TRANSPORTATION QUERIES /////////////////////////////////////
///////////////////////////////////////////////////////////////

export const getColumnsBetweenTS = (
  a,
  b,
  columns,
  names = [],
  subscribe = false
) => {
  const subString =
    names.length > 0 ? names.map((name) => `"${name}"`).join(",") : null;
  return gql`
  ${subscribe ? "subscription" : ""}
  {
  agents(where: { _and: [{ vts: { _lt: ${b} } }, { vts: { _gt: ${a} } }  ${
    subString === null ? " " : ", {vname: {_in: [ " + subString + "]}} "
  }  ] }) {
   ${" " + columns.join(" ") + " "}
  }
}
`;
};


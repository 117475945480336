import { useState, useEffect, useRef } from "react";
import {
  NEVER,
  interval,
  of,
  from,
  fromEvent,
  BehaviorSubject,
  Subject,
  zip,
  combineLatest,
  lastValueFrom,
} from "rxjs";
import {
  map,
  filter,
  delay,
  delayWhen,
  concatMap,
  switchMap,
  exhaustMap,
  mergeMap,
  mergeScan,
  switchScan,
  withLatestFrom,
  scan,
  take,
  tap,
  bufferCount,
  combineLatestWith,
  debounceTime,
  pairwise,
} from "rxjs/operators";
import _ from "lodash";

import { CONSTANTS } from "../../../../common/settings";

let floatData = {};
// costum hook for stablishing socket connection with server
export const useFetchData = (
  dataSetter,
  bufferPercentageSetter,
  setIsLoading,
  dataIndex,
  socketInstance,
  dataSpeed,
  options,
) => {
  const [buffer$] = useState(new BehaviorSubject({}));
  const [buffer, setBuffer] = useState({});
  const [batchReceived, setBatchReceived] = useState(true);
  const [lastFetchedExtremums, setLastFetchedExtremums] = useState([0, 0]);
  const {channelReq, channelRes, historyLen, emissionIds} = options

  useEffect(() => {
    socketInstance.on(channelRes, (payload) => {
      floatData = payload;
      setBatchReceived(true);
    });

    const buffSub = buffer$
      .pipe(
        tap(dataSetter),
        map((x) => Object.keys(x)),
      )
      .subscribe();
    return () => {
      buffSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    batchReceived ? setIsLoading(false) : setIsLoading(true);
  }, [batchReceived]);

  useEffect(() => {
    // console.info("dataIndex: ", dataIndex);
    let buffMax = Math.max(...Object.keys(buffer));
    // console.info("buffMax: ", buffMax - dataIndex);
    bufferPercentageSetter((buffMax - dataIndex) / CONSTANTS.MIN_BUFFER_SIZE);
    if (buffMax - dataIndex < CONSTANTS.MIN_BUFFER_SIZE) {
      batchReceived &&
      socketInstance.emit(
        channelReq,
          (function () {
            setBatchReceived(false);
            return [
              lastFetchedExtremums[1] > 0 ? lastFetchedExtremums[1] : dataIndex,
              Math.round(Math.sqrt(dataSpeed + 8)) *
                CONSTANTS.INDEX_STRIDE *
                CONSTANTS.INDEX_STEP,
                emissionIds,
            ];
          })()
        );
    }
    let newData = { ...buffer, ...floatData };
    newData = _.pick(
      newData,
      Object.keys(newData).filter((e) => e >= dataIndex - historyLen*CONSTANTS.INDEX_STEP)
    ); // keep only data after seeker
    setLastFetchedExtremums([
      Math.min(...Object.keys(newData)),
      Math.max(...Object.keys(newData)),
    ]);
    setBuffer(newData);
  }, [dataIndex]);

  useEffect(() => {
    buffer$.next(buffer);
  }, [buffer]);
};

import {
  ScatterplotLayer,
  GeojsonLayer,
  TextLayer,
  LineLayer,
  PathLayer,
  IconLayer,
  PolygonLayer,
} from "@deck.gl/layers";
// import CustomMVTLayer from "./custom-mvt-layer";
import { CubeGeometry } from "@luma.gl/core";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { SolidPolygonLayer } from "@deck.gl/layers";


export const solidPolygonLayer = (data, args) => {
  return new SolidPolygonLayer({
    data: data,
    getPolygon: (d) => d.geom.coordinates,
    getColor: [255, 0, 0],
    extruded: false,
    ...args,
  });
};

export const polygonLayer = (data, args) => {
  return new PolygonLayer({
    data: data,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 122],
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 5,
    getPolygon: (d) => d.geom.coordinates,
    getElevation: (d) => 10,
    getFillColor: (d) => [60, 140, 0, 10],
    getLineColor: [80, 80, 80],
    getLineWidth: 3,
    ...args,
  });
};

export const pointLayer = (data, args) => {
  return new ScatterplotLayer({
    data: data,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 122],
    pickable: true,
    opacity: 0.8,
    stroked: true,
    filled: true,
    radiusScale: 2,
    radiusMinPixels: 3,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 0,
    getPosition: (d) => d.geom.coordinates,
    getFillColor: (d) => [255, 140, 0],
    getLineColor: (d) => [0, 0, 0],
    getLineWidth: 1,
    ...args,
  });
};

export const iconLayer = (data, args) => {
  return new IconLayer({
    data: data,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 122],
    pickable: true,
    // pickable: layersPickable.energy,
    billboard: true,
    getColor: (d) => [255, 5, 5],
    getIcon: (d) => "marker",
    getPosition: (d) => [...d.geom.coordinates, 12],
    sizeMinPixels: 5,
    sizeMaxPixels: 40,
    getSize: (d) => 50,
    sizeScale: 8,
    iconAtlas:
      "https://img.icons8.com/pastel-glyph/64/000000/park-and-charge.png",
    // "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-charging-station-car-service-kiranshastry-lineal-kiranshastry.png",
    // "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 96,
        height: 96,
        anchorY: 48,
        mask: true,
      },
    },
    ...args,
  });
};

export const cubeLayer = (data, args) => {
  return new SimpleMeshLayer({
    data: data,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 122],
    // pickable: layersPickable.energy,
    pickable: true,
    mesh: new CubeGeometry(),
    sizeScale: 3,
    opacity: 0.99,
    wireframe: false,
    getPosition: (d) => d.geom.coordinates,
    getColor: [255, 11, 12],
    getOrientation: (d) => [0, 57, 90],
    getScale: (d) => [1, 3, 1],
    ...args,
  });
};

export const lineLayer = (data, args) => {
  return new PathLayer({
    data: data,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 122],
    pickable: true,
    widthScale: 11,
    // widthMinPixels: 0,
    widthMaxPixels: 5,
    getPath: (d) => d.geom.coordinates,
    getColor: (d) => [111, 111, 111, 255],
    getWidth: (d) => (d.voltage ? Math.round((d.voltage / 750000) * 10) : 2),
    ...args,
  });
};

// const polygonLayer = (data, args) => {
//   return new PolygonLayer({
//     data: data,
//     autoHighlight: true,
//     highlightColor: [255, 255, 255, 122],
//     pickable: true,
//     stroked: true,
//     filled: true,
//     wireframe: true,
//     lineWidthMinPixels: 2,
//     lineWidthMaxPixels: 5,
//     getPolygon: (d) => d.geom.coordinates,
//     getElevation: (d) => 10,
//     getFillColor: (d) => [60, 140, 0, 10],
//     getLineColor: [80, 80, 80],
//     getLineWidth: 3,
//     onHover: (e) => {
//       e.object && console.log("hovered: ", e.object);
//     },
//     onClick: (e) => {
//       e.object && console.log("clicked: ", e.object);
//     },
//     ...args,
//   });
// };

// const pointLayer = (data, args) => {
//   return new ScatterplotLayer({
//     data: data,
//     autoHighlight: true,
//     highlightColor: [255, 255, 255, 122],
//     pickable: true,
//     opacity: 0.8,
//     stroked: true,
//     filled: true,
//     radiusScale: 2,
//     radiusMinPixels: 3,
//     radiusMaxPixels: 100,
//     lineWidthMinPixels: 0,
//     getPosition: (d) => d.geom.coordinates,
//     getFillColor: (d) => [255, 140, 0],
//     getLineColor: (d) => [0, 0, 0],
//     getLineWidth: 1,
//     onHover: (e) => {
//       e.object && console.log("hovered: ", e.object);
//     },
//     onClick: (e) => {
//       e.object && console.log("clicked: ", e.object);
//     },
//     ...args,
//   });
// };

// const iconLayer = (data, args) => {
//   return new IconLayer({
//     data: data,
//     autoHighlight: true,
//     highlightColor: [255, 255, 255, 122],
//     pickable: true,
//     // pickable: layersPickable.energy,
//     billboard: true,
//     getColor: (d) => [255, 5, 5],
//     getIcon: (d) => "marker",
//     getPosition: (d) => [...d.geom.coordinates, 12],
//     sizeMinPixels: 5,
//     sizeMaxPixels: 40,
//     getSize: (d) => 50,
//     sizeScale: 8,
//     iconAtlas:
//       "https://img.icons8.com/pastel-glyph/64/000000/park-and-charge.png",
//     // "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-charging-station-car-service-kiranshastry-lineal-kiranshastry.png",
//     // "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
//     iconMapping: {
//       marker: {
//         x: 0,
//         y: 0,
//         width: 96,
//         height: 96,
//         anchorY: 48,
//         mask: true,
//       },
//     },
//     ...args,
//   });
// };

// const cubeLayer = (data, args) => {
//   return new SimpleMeshLayer({
//     data: data,
//     autoHighlight: true,
//     highlightColor: [255, 255, 255, 122],
//     // pickable: layersPickable.energy,
//     pickable: true,
//     mesh: new CubeGeometry(),
//     sizeScale: 3,
//     opacity: 0.99,
//     wireframe: false,
//     getPosition: (d) => d.geom.coordinates,
//     getColor: [255, 11, 12],
//     getOrientation: (d) => [0, 57, 90],
//     getScale: (d) => [1, 3, 1],
//     ...args,
//   });
// };

// const lineLayer = (data, args) => {
//   return new PathLayer({
//     data: data,
//     autoHighlight: true,
//     highlightColor: [255, 255, 255, 122],
//     // pickable: layersPickable.energy,
//     onHover: (e) => {
//       e.object && console.log("hovered: ", e.object);
//     },
//     pickable: true,
//     widthScale: 11,
//     // widthMinPixels: 0,
//     widthMaxPixels: 5,
//     getPath: (d) => d.geom.coordinates,
//     getColor: (d) =>
//       d.voltage
//         ? brewer[Math.round((d.voltage / 750000) * 8)]
//         : [111, 111, 111, 255],
//     getWidth: (d) => (d.voltage ? Math.round((d.voltage / 750000) * 10) : 2),
//     ...args,
//   });
// };

import { MVTLayer } from "@deck.gl/geo-layers";


export default class CustomMVTLayer extends MVTLayer {
  initializeState() {
    super.initializeState();
  }

  renderLayers() {
    return null;
  }
}


CustomMVTLayer.layerName = "CustomMVTLayer";

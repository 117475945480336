import React from 'react'
import styles from './styles.module.less'
import ServicesMenu from './services-menu'
import { controlStore } from '../../../store/store'

export default function TopMenu(props) {
  const hideAll = controlStore((state) => state.hideAll)
  const setHideAll = controlStore((state) => state.setHideAll)

  return (
    <div className={styles.topmenu}>
      <ServicesMenu />
    </div>
  )
}

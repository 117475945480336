import React, { useEffect, useState } from "react";
import styles from "./styles.module.less";
import _ from "lodash";
import { Typography } from "antd";
import Tree from "./tree";
import { panelsStore, controlStore } from "../../store/store";

export default function Sidebar(props) {
  const [layersChanged, setLayersChanged] = useState(1);

  const mainLayers = panelsStore((state) => state.mainLayers);
  const setMainLayers = panelsStore((state) => state.setMainLayers);
  const customLayers = panelsStore((state) => state.customLayers);
  const setCustomLayers = panelsStore((state) => state.setCustomLayers);
  const activeServices = panelsStore((state) => state.activeServices);
  const layersShown = controlStore((state) => state.layersShown);
  const layersPickable = controlStore((state) => state.layersPickable);

  return (
    <div className={styles.wrapper + " " + (props.hide ? styles.hide : "")}>
      <div className={styles.mainLayers}>
        <div className={styles.dividerWrapper}>
          <div className={styles.divider} />
          <Typography.Title>{"Main Layers"}</Typography.Title>
          <div className={styles.divider} />
        </div>
        <Tree
          layers={mainLayers}
          layersSetter={setMainLayers}
          layersChanged={layersChanged}
        />
      </div>
    </div>
  );
}

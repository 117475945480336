import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  controlStore,
  systemStore,
  statsStore,
  panelsStore,
  servicesStore,
} from "../../../../store/store";
import { getBaseBuildings, getFootprints } from "./sublayers";
import { useLazyQuery } from "@apollo/client";
import { dataSplitbyGeom, expandSublayers } from "../../helpers";
import { gql } from "@apollo/client";
import { footprintQuery } from "../../queries";

// const bldgByIDQuery = gql`
//   query building($bldg_id: bigint) {
//     building_footprint_year_built_height_max(
//       where: { index: { _eq: $bldg_id } }
//     ) {
//       geom
//       height_max
//       index
//       uid
//       year_built
//     }
//   }
// `;

const bldgsByRadiusQuery = gql`
  query building($point: geometry!, $radius: Float!) {
    building_footprint_year_built_height_max(
      where: { geom: { _st_d_within: { distance: $radius, from: $point } } }
    ) {
      index
      landuse_code
      landuse_text
      unit_category
      height_max
      geom
      year_built
      uid
    }
  }
`;

export default function mapObject(props) {
  //------------------ LOCAL STATES ------------------
  // const [bldgsByIDQueryOptions, setBldgsByIDQueryOptions] = useState({});
  const [bldgsByRadiusQueryOptions, setBldgsByRadiusQueryOptions] = useState(
    {}
  );
  const [selectBldg, setSelectBldg] = useState(undefined);
  const [bldgNeighbours, setBldgNeighbours] = useState(undefined);

  //------------------ SHARED STATES ------------------
  const activeServices = panelsStore((state) => state.activeServices);
  const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const layersPickable = controlStore((state) => state.layersPickable);
  const layersShown = controlStore((state) => state.layersShown);
  const setIsLoading = systemStore((state) => state.setIsLoading);
  const setSelectedBuildings = statsStore(
    (state) => state.setSelectedBuildings
  );
  const selectedBuildings = statsStore((state) => state.selectedBuildings);

  //------------------ FUNCTIONS ------------------
  //------------------ HOOKS ------------------
  const [fetchFunc, fetchObj] = useLazyQuery(footprintQuery, {
    fetchPolicy: "cache-first", // Doesn't check cache before making a network request
    variables: { limit: 5 },
  });
  const [fetchBldgByRadiusFunc, fetchBldgByRadiusObj] = useLazyQuery(
    bldgsByRadiusQuery,
    bldgsByRadiusQueryOptions
  );
  //------------------ SIDE EFFECTS ------------------
  //// Target Building Data --> Create Adjacent Query Options
  useEffect(() => {
    if (selectBldg && selectBldg.centroid) {
      setBldgsByRadiusQueryOptions({
        variables: {
          point: {
            crs: {
              type: "name",
              properties: { name: "EPSG:4326" },
            },
            type: "Point",
            coordinates: selectBldg.centroid,
          },
          radius: 0.0005,
        },
        fetchPolicy: "cache-first", // checks cache before making a network request
      });
    }
  }, [selectBldg, activeServices]);

  activeServices.eplusTool;

  useEffect(() => {
    if (!activeServices.eplusTool) {
      setBldgNeighbours({ data: [], Ids: [] });
    }
    if (selectBldg && activeServices.eplusTool) {
      setSelectBldg({ ...selectBldg });
    }
  }, [activeServices]);

  //// Adjacent Query Options --> Fetch Adjacent Buildings
  useEffect(() => {
    if (layersShown["built_buildingsmodel"] && activeServices.eplusTool) {
      if (!fetchBldgByRadiusObj.data && selectBldg) {
        fetchBldgByRadiusFunc();
      }
    }
  }, [bldgsByRadiusQueryOptions]);

  //// Adjacent Building Data --> Set State Variable
  useEffect(() => {
    if (
      fetchBldgByRadiusObj.data &&
      fetchBldgByRadiusObj.data.building_footprint_year_built_height_max &&
      selectBldg
    ) {
      let data =
        fetchBldgByRadiusObj.data.building_footprint_year_built_height_max;
      data = data.filter((el) => el.index !== selectBldg.index);
      let neighbourIds = _.map(data, "index");
      activeServices.eplusTool &&
        setBldgNeighbours({
          data: data,
          Ids: neighbourIds,
        });
    }
  }, [fetchBldgByRadiusObj.data]);

  useEffect(() => {
    setSelectedBuildings({
      target: { ...setSelectedBuildings.target, ...selectBldg },
      adjacent: bldgNeighbours,
    });
    console.log("value was set ", selectedBuildings)
  }, [selectBldg, bldgNeighbours]);

  useEffect(() => {
    if (fetchObj.loading || fetchBldgByRadiusObj.loading) {
      setIsLoading("main", true);
    } else {
      setIsLoading("main", false);
    }
  }, [fetchObj.loading, fetchBldgByRadiusObj.loading]);

  useEffect(() => {
    if (layersShown["built"] && layersShown["built_buildingsmodel"]) {
      if (!fetchObj.data) {
        fetchFunc();
      }
    }
  }, [layersShown]);

  useEffect(() => {
    if (fetchObj.data) {
      const layersObj = {
        ...(layersShown["built"] && layersShown["built_buildingsmodel"]
          ? {
              base_buildings: {
                ...getBaseBuildings(
                  selectBldg,
                  setSelectBldg,
                  bldgNeighbours ? bldgNeighbours.Ids : [],
                  setHoveredBuildings,
                  layersPickable["built_buildingsmodel"]
                ),
              },
            }
          : {}),
        ...(layersShown["built"] && layersShown["built_buildingsfootprint"]
          ? {
              bldg_footprints: {
                ...getFootprints(
                  layersPickable["built_buildingsfootprint"]
                ),
              },
            }
          : {}),
      };
      // update layers on any change
      let layers = expandSublayers(layersObj);
      props.setLayer(layers);
      // props.setLayer(_.values(layersObj));
    }
  }, [
    fetchObj.data,
    layersShown,
    activeServices,
    layersPickable,
    bldgNeighbours,
    selectBldg,
  ]);

  return null;
}

import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
} from "../../common/common-sublayers";
import chroma from "chroma-js";
import CustomMVTLayer from "../../../../utils/custom-mvt-layer";
import { OBJLoader } from "@loaders.gl/obj";
import { PLYLoader } from "@loaders.gl/ply";
import {
  ScreenGridLayer,
  HeatmapLayer,
  HexagonLayer,
} from "@deck.gl/aggregation-layers";
import _ from "lodash";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { shuffleArray } from "../../../../utils/helpers";

//------------------ VARIABLES ------------------
// Source data GeoJSON
const DATA_URL = `${process.env.REACT_APP_TILE_SERVER_ENDPOINT_URL}/public.trees_public.json`;

const scale = chroma.scale(["#444", "#76c893", "#006d77"]);
const brewer = chroma.brewer.YlGn.map((el) => chroma(el).rgb()).reverse();
// console.log(brewer);
// console.log(scale(0.5).rgb().push(10));

const hoverOptions = {
  power_grid: ["__typename"],
};
//------------------ Helpers ------------------
const handleHover = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
  obj &&
    console.log({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
};
const handleClick = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "click",
      ..._.omit(obj, ["geom"]),
    });
};

//------------------ Layer Specific Options ------------------
const colorOpacity = 111;
const iconLayerOptions = {
  getPosition: (d) => [...d.geom.coordinates, 5],
  sizeMinPixels: 5,
  sizeMaxPixels: 40,
  getSize: (d) => 25,
  sizeScale: 1,
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
      anchorY: 16,
      mask: true,
    },
  },
};
const lineLayerOptions = {
  id: "power_grid_line",
  getColor: (d) =>
    d.voltage
      ? brewer[Math.round((d.voltage / 750000) * 8)]
      : [111, 111, 111, 255],
};
const polyLayerOptions = {};
const meshLayerOptions = {
  autoHighlight: true,
  highlightColor: [255, 100, 100, 122],
  // onHover: ({ x, y, object }) => {
  //   object && setSelect(object.properties.index);
  //   object && setHoveredBuildings(object.properties);
  //   object && console.log(object.properties);
  //   console.log(hoveredBuildings);
  // },
  sizeScale: 1,
  opacity: 0.3,
  wireframe: false,
  getPosition: (d) => d.geometry.coordinates,
  getColor: (d) => {
    let scale =
      (Date.now() - Date.parse(d.properties.Date_plantation)) /
      24 /
      3600 /
      1000 /
      30;
    return [0.2 * scale, 255, 0, 120];
  },
  // getColor: (d) => {
  //   if (d.properties.ESSENCE_ANG.includes("Maple")) {
  //     return [0, 255, 0, colorOpacity];
  //   } else if (d.properties.ESSENCE_ANG.includes("Red Ash")) {
  //     return [27, 158, 119, colorOpacity];
  //   } else if (d.properties.ESSENCE_ANG.includes("European")) {
  //     return [77, 175, 74, colorOpacity];
  //   } else if (d.properties.ESSENCE_ANG.includes("Locust")) {
  //     return [141, 211, 199, colorOpacity];
  //   } else {
  //     return [122, 199, 122, colorOpacity];
  //   }
  // },
  getOrientation: (d) => [0, 0, 90],
  getScale: (d) => {
    let rad = 0.5 * Math.sqrt(parseInt(d.properties.DHP));
    return [rad, rad, rad];
  },
};
const pointLayerOptions = {
  getFillColor: (d) => {
    if (d.ESSENCE_ANG.includes("Maple")) {
      return [0, 255, 0, colorOpacity];
    } else if (d.ESSENCE_ANG.includes("Red Ash")) {
      return [255, 0, 0, colorOpacity];
    } else if (d.ESSENCE_ANG.includes("European")) {
      return [255, 255, 0, colorOpacity];
    } else if (d.ESSENCE_ANG.includes("Locust")) {
      return [255, 0, 255, colorOpacity];
    } else {
      return [0, 255, 255, colorOpacity];
    }
  },
  radiusScale: 2,
  radiusMinPixels: 1,
  radiusMaxPixels: 50,
  stroked: false,
  opacity: 0.05,
  getRadius: (d) => Math.log2(d.DHP),
};

//------------------ Functions ------------------

export const getPublicTrees = (
  data,
  setHoveredBuildings,
  setSubLayers,
  pickable = false
) => {
  return {
    ecosystem_trees_points_near: pointLayer(data.trees_public.point, {
      ...pointLayerOptions,
      pickable: pickable,
      id: "ecosystem_trees_points_near",
      viewProps: { zoomMin: 9, zoomMax: 14, viewIds: ["main"] },
      opacity: 0.95,
      radiusScale: 0.5,
      onHover: (e) =>
        handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
      onClick: (e) =>
        handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
    }),

    ecosystem_trees_points_far: pointLayer(data.trees_public.point, {
      ...pointLayerOptions,
      pickable: pickable,
      id: "ecosystem_trees_points_farther",
      viewProps: { zoomMin: 3, zoomMax: 9, viewIds: ["main"] },
      opacity: 0.9,
      radiusScale: 0.05,
      onHover: (e) =>
        handleHover(e.object, setHoveredBuildings, hoverOptions.power_grid),
      onClick: (e) =>
        handleClick(e.object, setHoveredBuildings, hoverOptions.power_grid),
    }),

    ecosystem_trees_3d: new CustomMVTLayer({
      id: "ecosystem_trees",
      data: DATA_URL,
      // opacity: 0.9,
      minZoom: 13,
      maxZoom: 23,
      binary: true,
      viewProps: { zoomMin: 14, zoomMax: 23, viewIds: ["main"] },
      // onTileError: (e) => {
      //   console.log('TILE LOAD ERROR', e)
      // },
      // updateTriggers: {
      //   getFillColor: select,
      //   getElevation: select,
      //   // wireframe: select,
      // },
      onViewportLoad: (tiles) => {
        const layers = tiles.map((tile, ix) => {
          const data = tile.dataInWGS84;

          // if (data.length > 0) {
          //   var list2 = [...data] // spread to avoid mutating the original
          //   // var list2 = shuffleArray([...data]); // spread to avoid mutating the original
          //   var list1 = list2.splice(0, data.length >> 1);
          // }
          // data in world coordinates (WGS84)
          return tile.dataInWGS84
            ? [
                new SimpleMeshLayer({
                  ...meshLayerOptions,
                  id: "ecosystem_trees-mesh-layer-1-" + ix,
                  data: tile.dataInWGS84.filter((e) => e.properties.DHP < 35),
                  viewProps: { zoomMin: 14, zoomMax: 23, viewIds: ["main"] },
                  pickable: pickable,
                  mesh: "lowpolytree.obj",
                  loaders: [OBJLoader],
                  // mesh: 'wwww.ply',
                  // loaders: [PLYLoader],
                }),
                new SimpleMeshLayer({
                  ...meshLayerOptions,
                  id: "ecosystem_trees-mesh-layer-2-" + ix,
                  data: tile.dataInWGS84.filter((e) => e.properties.DHP >= 35),
                  viewProps: { zoomMin: 14, zoomMax: 23, viewIds: ["main"] },
                  pickable: pickable,
                  // mesh: "lowpolytree.obj",
                  // loaders: [OBJLoader],
                  mesh: "wwww.ply",
                  loaders: [PLYLoader],
                  getOrientation: (d) => [0, 11 * d.properties.DHP, 90],
                }),
              ]
            : [];
        });
        setSubLayers(layers);
      },
    }),

        // ecosystem_trees_points: new HeatmapLayer({
    //   id: "ecosystem_trees_points_farther",
    //   viewProps: { zoomMin: 5, zoomMax: 12, viewIds: ["main"] },
    //   data: data.trees_public.point,
    //   aggregation: "SUM",
    //   pickable: pickable,
    //   opacity: 0.99,
    //   autoHighlight: true,
    //   debounceTimeout: 50,
    //   highlightColor: [255, 255, 255, 255],
    //   gpuAggregation: true,
    //   getPosition: (e) => e.geom.coordinates,
    //   getWeight: (d) => Math.log2(d.DHP) / 10,
    //   intensity: 1,
    //   radiusPixels: 10,
    //   threshold: 0.05,
    //   colorRange: [
    //     [...scale(0.0).rgb(), 88],
    //     [...scale(0.5).rgb(), 255],
    //     [...scale(0.99).rgb(), 255],
    //   ],
    //   // colorRange: chroma.brewer.BrBG.map((el) => chroma(el).rgb()).reverse(),
    // }),

    // ecosystem_trees_points: new ScreenGridLayer({
    //   id: 'ecosystem_trees_points',
    //   data: data.trees_public.point,
    //   viewProps: { zoomMin: 6, zoomMax: 22, viewIds: ["main"] },
    //   pickable: false,
    //   opacity: 0.99,
    //   cellSizePixels: 15,
    //   cellMarginPixels: .5,
    //   autoHighlight: true,
    //   highlightColor: [255,255,255,255],
    //   getPosition: (e) => e.geom.coordinates,
    //   getWeight: d => Number(d.DHP)/1,
    //   colorRange: [
    //     [...scale(0.0).rgb(), 120],
    //     [...scale(0.5).rgb(), 200],
    //     [...scale(0.99).rgb(), 255],
    //   ],
    //   onHover: (e) => console.log(e),
    // }),
    // ecosystem_trees_points: new HexagonLayer({
    //   data: data.trees_public.point,
    //   pickable: false,
    //   extruded: false,
    //   radius: 200,
    //   elevationScale: 4,
    //   getPosition: (e) => e.geom.coordinates,
    //   colorRange: [
    //     [...scale(0.0).rgb(), 120],
    //     [...scale(0.5).rgb(), 200],
    //     [...scale(0.99).rgb(), 255],
    //   ],
    // }),
  };
};

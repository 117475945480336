import React, { useState, useCallback } from "react";
import styles from "./styles.module.less";
import { Menu, Divider } from "antd";
import AboutPage from "./about-page";
const { SubMenu, Item } = Menu;

export default function MenuRight() {
  //// LOCAL STATES
  const [showAbout, setShowAbout] = useState(false);

  //// SHARED STATES

  //// FUNCTIONS

  const handleAboutButton = useCallback((e) => {
    setShowAbout(!showAbout);
  }, [showAbout]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        {/* <Divider type='vertical' /> */}
        <Menu key='info' theme='dark' mode='horizontal'>
          <Item key='info_item_1' onClick={handleAboutButton}>about</Item>
          <Item key='info_item_2' >people</Item>
          <Item key='info_item_3' >contact</Item>
        </Menu>
      </div>
      <AboutPage showAbout={showAbout} handleAboutButton={handleAboutButton} />
    </div>
  );
}

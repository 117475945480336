import React, { useState, useRef, useEffect, useCallback } from "react";
import Drawer from "../drawer";
import * as d3 from "d3";
import { servicesStore, statsStore } from "../../../store/store";
import { rgb } from "chroma-js";
import { message, Button, Tooltip } from "antd";
import { FiHelpCircle } from "react-icons/fi";
import axios from "axios";
import { GrAddCircle, GrClear, GrSubtract, GrFormClose } from "react-icons/gr";

import chroma from "chroma-js";
import styles from "./styles.module.less";
import PercentagesSlider from "./multislider";
import _ from "lodash";
import StatsWin from "./statistics-window";

//------------------ VARIABLES ------------------
const scale = chroma.scale(["#2b2d42", "#15616d", "#ef233c", "#edf2f4"]);
const RETROFIT_COLORS = ["#4361ee", "#8ac926", "#d62828"]; //<== retrofit colors corresponding to R0, R1, R2
const DEFAULT_RATIOS = { r0: 0.5, r1: 0.25, r2: 0.25 }; //<== retrofit default ratios
const brewer = _.shuffle(chroma.brewer.set3);
// const brewer = chroma.brewer.Set1.map((el) => chroma(el).rgb()).reverse();

// set the dimensions and margins of the graph
var pie_radius = 16;
var margin = { top: 10 + pie_radius, right: 70, bottom: 30, left: 20 };
var width = 580 - margin.left - margin.right;
var height = 300 - margin.top - margin.bottom;

var helpMessageShown = {
  addDivider: null,
};

const handleAddDividerPopup = (msg) => {
  message.info({
    key: "addDivivderMessagePopup",
    content: msg,
    duration: 5,
    maxCount: 2,
    className: styles.dividerBtnPopup,
    icon: <FiHelpCircle />,
    onClose: () => (helpMessageShown.addDivider = true),
  });
  helpMessageShown.addDivider = true;
};

const handleDragDividerPopup = (msg) => {
  message.info({
    key: "dragDivivderMessagePopup",
    content: msg,
    duration: 5,
    maxCount: 2,
    icon: <FiHelpCircle />,
    className: styles.dividerBtnPopup,
  });
  helpMessageShown.addDivider = true;
};

var initData = _.shuffle(
  Array.from({ length: 300 }, (el, i) => {
    return {
      index: i,
      height: Math.floor(Math.random() * 100),
      year: Math.floor(Math.random() * 122 + 1900),
      demand: Math.floor(Math.random() * 500),
      usage: Math.floor(Math.random() * 500),
    };
  })
).map((el, i) => ({ ...el, index: i }));

// Create scale
var xScale = d3.scaleLinear().domain([1900, 2022]).range([0, width]);
var yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

export default function index() {
  //------------------ LOCAL STATES ------------------
  const d3Element = useRef();
  const drawer = useRef();
  const [mouseCoord, setMouseCoord] = useState({
    x: null,
    y: null,
    mode: "",
    from: "",
  });
  const [data, setData] = useState(initData);
  const [dividers, setDividers] = useState({});
  const [objPicked, setObjPicked] = useState(null);
  const [activeDivider, setActiveDivider] = useState(null);
  const [clickListen, setClickListen] = useState(false);
  const [dividerEdit, setDividerEdit] = useState(false);
  const [partitions, setPartitions] = useState({});
  const [activePartition, setActivePartition] = useState(null);
  const [partitionEdit, setPartitionEdit] = useState(false);
  const [clickedElement, setClickedElement] = useState(null);
  const [ratiosChange, setRatiosChange] = useState([
    { key: "r0", text: "R0", color: RETROFIT_COLORS[0], percentage: 50 },
    { key: "r1", text: "R1", color: RETROFIT_COLORS[1], percentage: 25 },
    { key: "r2", text: "R2", color: RETROFIT_COLORS[2], percentage: 25 },
  ]);
  const [showStatisticsWin, setShowStatisticsWin] = useState(false);

  //------------------ SHARED STATES ------------------
  const retrofitSelectedData = servicesStore(
    (state) => state.retrofitSelectedData
  );

  // const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const hoveredBuildings = statsStore((state) => state.hoveredBuildings);
  const setChartHoveredItems = statsStore(
    (state) => state.setChartHoveredItems
  );

  //------------------ FUNCTIONS ------------------
  // console.log("retrofitSelectedData", retrofitSelectedData);
  // console.log("data", data);
  //------------------ HOOKS ------------------

  //------------------ SIDE EFFECTS ------------------

  //// mock effect to emulate data from server
  useEffect(() => {
    if (retrofitSelectedData.length > 0) {
      let newData = _.map(retrofitSelectedData, (d, i) => ({
        index: d.index,
        height: d.height_max,
        year: Math.floor(1900 + 120 * Math.random()),
        demand: Math.floor(Math.random() * 500),
        usage: Math.floor(Math.random() * 500),
      }));
      setData(newData);
      setPartitions({ ...partitions });
    }
  }, [retrofitSelectedData]);

  //// mock effect to emulate data from server
  useEffect(() => {
    if (data.length > 0) {
      let xRange = [_.minBy(data, "year").year, _.maxBy(data, "year").year];
      let yRange = [
        _.minBy(data, "height").height,
        _.maxBy(data, "height").height,
      ];
      xScale = d3.scaleLinear().domain(xRange).range([0, width]);
      yScale = d3.scaleLinear().domain(yRange).range([height, 0]);
    }
  }, [data]);

  useEffect(() => {
    if (drawer.current) {
      // margin = { top: 10 + pie_radius, right: 70, bottom: 50, left: 50 }
      width =
        Math.round(0.8 * drawer.current.clientWidth) -
        margin.left -
        margin.right;
      // height = drawer.current.scrollHeight - margin.top - margin.bottom;
    }

    var svg = d3
      .select(d3Element.current)
      .selectAll("svg")
      .data([0])
      .join("svg")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .on("click", (event, value) => {
        let mouseCoords = d3.pointer(event);
        let xClamped = Math.min(Math.max(mouseCoords[0], 0), width);
        let yClamped = Math.min(Math.max(mouseCoords[1], 0), height);
        setMouseCoord({ x: xClamped, y: yClamped, mode: "click", from: "svg" });
      });

    // Add scales to axis
    var xAxis = d3.axisBottom(xScale);
    var yAxis = d3.axisLeft(yScale);

    //Insert axis
    svg
      .selectAll(".x-axis")
      .data([0])
      .join("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);
    svg
      .selectAll(".y-axis")
      .data([0])
      .join("g")
      .attr("class", "y-axis")
      .attr("transform", "translate(0, 0)")
      .call(yAxis);

    // create a list of keys
    var keys = [
      "No Retrofit (R0)",
      "Light Retrofit (R1)",
      "Heavy Retrofit (R2)",
    ];

    // Usually you have a color scale in your chart already
    var color = d3.scaleOrdinal().domain(keys).range(RETROFIT_COLORS);

    // Add one dot in the legend for each name.
    svg
      .selectAll(".legendBoxes")
      .data(keys)
      .join("rect")
      .attr("class", "legendBoxes")
      .attr("x", width + margin.left)
      .attr("y", function (d, i) {
        return 0 - 8 + i * 25;
      })
      .attr("width", 10)
      .attr("height", 10)
      .attr("stroke", "none")
      .style("fill", function (d) {
        return color(d);
      });

    // Add one dot in the legend for each name.
    svg
      .selectAll(".legendLabels")
      .data(keys)
      .join("text")
      .attr("class", "legendLabels")
      .attr("x", width + margin.left + 15)
      .attr("y", function (d, i) {
        return 0 + i * 25;
      })
      .style("fill", function (d) {
        return color(d);
      })
      .text(function (d) {
        return d;
      })
      .attr("text-anchor", "left")
      .style("alignment-baseline", "middle")
      .attr("font-size", 11);

    svg
      .selectAll(".circle")
      .data(data)
      .join("circle")
      .attr("class", "circle")
      .attr("cx", (d, i) => xScale(d.year))
      .attr("cy", (d, i) => yScale(d.height))
      .attr("r", (d, i) =>
        hoveredBuildings && d.index === hoveredBuildings.index
          ? 2 * (d.usage / 200 + d.demand / 200)
          : d.usage / 200 + d.demand / 200
      )
      .attr("fill", (d, i) => {
        return _.has(d, "retrofit") ? RETROFIT_COLORS[d.retrofit] : "white";
      })
      .attr("index_val", (d, i) => d.index)
      .attr("height_val", (d, i) => d.height)
      .attr("retrofit_val", (d, i) => d.retrofit)
      .style("opacity", 0.7)
      .style("stroke", "white")
      .style("stroke-width", (d, i) =>
        hoveredBuildings && d.index === hoveredBuildings.index ? 3 : 0
      )

      .on("mouseover", (event, value) => {
        // setObjPicked(true);
        // d3
        //   .select(event.target.parentNode.parentNode)
        //   // svg
        //   .selectAll(".arc")
        //   .style("stroke", "white")
        //   .style("stroke-width", 1);

        setChartHoveredItems({
          index: event.target.getAttribute("index_val"),
          height: event.target.getAttribute("height_val"),
        });

        d3.select(event.target)
          .attr("fill", "white")
          .attr("r", 2 * parseFloat(event.target.getAttribute("r")))
          .style("opacity", 1.0);
      })
      .on("mouseout", (event, value) => {
        // setObjPicked(false);
        // !partitionEdit &&

        d3.select(event.target)
          .attr(
            "fill",
            RETROFIT_COLORS[parseInt(event.target.getAttribute("retrofit_val"))]
          )
          .attr("r", parseFloat(event.target.getAttribute("r")) / 2)
          .style("opacity", 0.7);
      })
      .on("click", (event, value) => {
        // setClickedElement(event.target.parentNode.parentNode);
        // setActivePartition(value.key);
        // setPartitionEdit(true);
        console.log("clicked", event.target);
      });

    // Add X axis label:
    svg
      .selectAll(".xAxisLabel")
      .data([0])
      .join("text")
      .attr("class", "xAxisLabel")
      .attr("text-anchor", "end")
      .attr("x", width / 2 + margin.left)
      .attr("y", height + margin.top / 2 + margin.bottom / 2 + 5)
      .text("Year Built")
      .attr("fill", "white")
      .attr("font-weight", 100)
      .attr("font-family", "Share Tech Mono")
      .style("stroke", "white")
      .style("font-size", "12px");

    // Add Y axis label:
    svg
      .selectAll(".yAxisLabel")
      .data([0])
      .join("text")
      .attr("class", "yAxisLabel")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left - 10)
      .attr("x", -margin.top - height / 2 + 30)
      .text("Height")
      .attr("fill", "white")
      .attr("font-family", "Share Tech Mono")
      .attr("font-weight", 100)
      .style("stroke", "white")
      .style("font-size", "12px");

    var drag = d3.drag();
    svg
      .selectAll(".split-line")
      .data(_.values(dividers))
      .join("line")
      .attr("class", "split-line")
      .attr("id", (d, i) => d.index)
      .attr("x1", (d, i) => d.x)
      .attr("y1", 10)
      .attr("x2", (d, i) => d.x)
      .attr("y2", height - 10)
      .style("stroke-width", 2)
      .style("stroke", (d, i) => {
        // making sure deleting a divider wont change colors of existing ones
        if (d.color) {
          return d.color;
        } else {
          return "gray";
          // let color = brewer[i % brewer.length];
          // let dividers_ = dividers;
          // dividers_[d.key] = { ...dividers_[d.key], color: color };
          // return color;
        }
      })
      .style("fill", "none")
      .on("click", (event, value) => {
        let mouseCoords = d3.pointer(event);
        let xClamped = Math.min(Math.max(mouseCoords[0], 0), width);
        let yClamped = Math.min(Math.max(mouseCoords[1], 0), height);
        setMouseCoord({
          x: xClamped,
          y: yClamped,
          mode: "click",
          from: "divider",
        });
        setActiveDivider(value.key);
        setDividerEdit(true);
      })
      .call(drag)
      .on("mouseover", (event, value) => {
        setObjPicked(true);
        d3.select(event.target).style("stroke-width", 4);
      })
      .on("mouseout", (event, value) => {
        setObjPicked(false);
        d3.select(event.target).style("stroke-width", 2);
      })
      .call(
        drag
          .on("start", function () {
            return false;
          })
          .on("drag", function (event_, val) {
            let xClamped = Math.min(Math.max(event_.x, 0), width);
            let yClamped = Math.min(Math.max(event_.y, 0), height);
            setActiveDivider(val.key);
            setMouseCoord({
              x: xClamped,
              y: yClamped,
              mode: "drag",
              from: "divider",
            });
            setDividerEdit(false);
          })
          .on("end", (sourceElement, index, svgItems) => {})
      );

    console.log("data ", data);
    console.log(" retrofitSelectedData", retrofitSelectedData);
  }, [mouseCoord.x, dividers, activeDivider, dividerEdit, data, hoveredBuildings]);

  //// DIVIDERS DRAG
  useEffect(() => {
    if (mouseCoord.mode === "drag" && mouseCoord.x && activeDivider) {
      let newDivider = {
        [activeDivider]: {
          ...dividers[activeDivider],
          x: mouseCoord.x,
        },
      };

      setDividers({
        ...dividers,
        ...newDivider,
      });
    }
  }, [mouseCoord.x, activeDivider]);

  //// DIVIDERS EDIT
  useEffect(() => {
    if (mouseCoord.mode === "click" && mouseCoord.x && clickListen) {
      let newKey = "div_" + Math.random().toString(16).slice(5);
      let newDivider = {
        [newKey]: {
          key: newKey,
          x: mouseCoord.x,
          // y: mouseCoord.y,
        },
      };
      setDividers({ ...dividers, ...newDivider });
      setClickListen(false);
    }
  }, [clickListen, mouseCoord.x]);

  //// PARTITION EDIT
  useEffect(() => {
    // sort dividers by x value
    let sortedDividers = _.sortBy(_.values(dividers), (elem) => elem.x);
    // create partitions based on pair of neighbour dividers
    let partitionsArr = _.map(
      _.zip(
        [{ key: "head_", x: 0 }, ...sortedDividers],
        [...sortedDividers, { key: "_tail", x: width }]
      ),
      (el) => ({
        key: el[0].key + el[1].key,
        x: [el[0].x, el[1].x],
      })
    );

    // convert array of objects to object of objects
    let partitionsObj = partitionsArr.reduce(function (map, obj) {
      // add default ratios attribute if doesn't exist
      if (partitions[obj.key] && partitions[obj.key].ratios) {
        map[obj.key] = { ratios: partitions[obj.key].ratios, ...obj };
      } else {
        map[obj.key] = { ratios: DEFAULT_RATIOS, ...obj };
      }
      return map;
    }, {});

    setPartitions(partitionsObj);
  }, [dividers, partitionEdit]);

  //// MODIFY DATA RETROFIT BASED ON PARTITIONS
  useEffect(() => {
    let partitionsVals = _.values(partitions);
    if (partitionsVals.length > 0) {
      // edit data object to reflect new ratios
      let newData = _.sortBy(
        _.flatten(
          _.map(partitionsVals, (el, ix) => {
            let subset = _.filter(
              data,
              (e) =>
                e.year <= xScale.invert(el.x[1]) &&
                e.year >= xScale.invert(el.x[0])
            );
            let [r0, r1, r2] = _.map(
              _.values(el.ratios),
              (m) => m * subset.length
            );

            subset = _.flatten([
              _.map(subset.slice(0, r0), (elem) => ({ ...elem, retrofit: 0 })),
              _.map(subset.slice(r0, r0 + r1), (elem) => ({
                ...elem,
                retrofit: 1,
              })),
              _.map(subset.slice(r0 + r1), (elem) => ({
                ...elem,
                retrofit: 2,
              })),
            ]);

            return subset;
          })
        ),
        "index"
      );

      setData(newData);
    }
  }, [partitions]);

  //// PARTITIONS DRAW
  useEffect(() => {
    // Generate the pie
    var pie = d3.pie().sort(null);

    // Generate the arcs
    var arc = d3.arc().innerRadius(0).outerRadius(pie_radius);

    var svg = d3
      .select(d3Element.current)
      .selectAll("svg")
      .data([0])
      .join("svg");

    svg.selectAll("g.arc").remove();
    //Generate groups
    var arcs = svg
      .selectAll(".pie")
      .data(_.values(partitions))
      .join("g")
      .attr("class", "pie")
      .attr("id", (d, i) => d.key)
      .attr(
        "transform",
        (d, i) =>
          "translate(" +
          (d.x[0] + d.x[1]) / 2 +
          "," +
          -1 * (pie_radius + 3) +
          ")"
      )
      .selectAll(".arc")
      .data(
        (d, i) => pie(_.values(d.ratios)),
        (d) => d.key
      );

    arcs
      .join("g")
      .attr("class", "arc")
      .append("path")
      .attr("class", "path")
      .attr("fill", (d, i) => RETROFIT_COLORS[d.index])
      .attr("d", arc);
    // .call((d, i) => console.log(d, i));

    svg
      .selectAll(".pie")

      .on("mouseover", (event, value) => {
        // setObjPicked(true);
        !partitionEdit &&
          d3
            .select(event.target.parentNode.parentNode)
            // svg
            .selectAll(".arc")
            .style("stroke", "white")
            .style("stroke-width", 1);
      })
      .on("mouseout", (event, value) => {
        // setObjPicked(false);
        !partitionEdit &&
          d3
            .select(event.target.parentNode.parentNode)
            // svg
            .selectAll(".arc")
            .style("stroke", "white")
            .style("stroke-width", 0);
      })
      .on("click", (event, value) => {
        setClickedElement(event.target.parentNode.parentNode);
        setActivePartition(value.key);
        setPartitionEdit(true);
      });

    if (clickedElement && partitionEdit) {
      d3.select(clickedElement)
        .selectAll(".arc")
        .style("stroke", "white")
        .style("stroke-width", 3);
    } else {
      d3.select(clickedElement)
        .selectAll(".arc")
        .style("stroke", "white")
        .style("stroke-width", 0);
    }
  }, [partitions]);

  // useEffect(() => {
  //   if (
  //     showStatisticsWin &&
  //     retrofitSelectedData.length > 0 &&
  //     data.length > 0
  //   ) {
  //     let extendedData = _({}) // Start with an empty object
  //       .merge(
  //         _(retrofitSelectedData).groupBy("index").value(),
  //         _(data).groupBy("index").value()
  //       )
  //       .values()
  //       .flatten()
  //       .value();

  //     extendedData = extendedData.map((el) =>
  //       _.pick(el, ["height", "retrofit", "geom"])
  //     );

  //     const headers = {};
  //     axios
  //       .post(
  //         "https://webhook.site/e89ccd10-7cc2-46c1-b1b9-efe4776ede9b",
  //         extendedData,
  //         { headers }
  //       )
  //       .then((response) => {
  //         console.log("Status: ", response.status);
  //         console.log("Data: ", response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Something went wrong!", error);
  //       });
  //   }
  // }, [showStatisticsWin]);

  // console.log("partitions: ", partitions);

  return (
    <>
      {showStatisticsWin && (
        <StatsWin
          data={{
            nrBuildings: data.length,
            areaSelected: "by circle",
            energyDemands: _.values(partitions).map((el) => ({
              x: [
                Math.floor(xScale.invert(el.x[0])),
                Math.floor(xScale.invert(el.x[1])),
              ],
              energy: Math.round(300 + 500 * Math.random()),
            })),
          }}
          closeCallback={() => setShowStatisticsWin(false)}
        />
      )}
      <Drawer>
        <div ref={drawer} className={styles.Wrapper}>
          <div className={styles.chart} ref={d3Element} />
          {!partitionEdit && (
            <div className={styles.dividerTools}>
              <p>Divider Tools</p>
              <div className={styles.dividerButtons}>
                <Tooltip placement='bottom' title={"add a divider"}>
                  <GrAddCircle
                    className={
                      clickListen
                        ? styles.dividerBtn + " " + styles.btnHighlight
                        : styles.dividerBtn
                    }
                    onClick={() => {
                      setClickListen(true);
                      if (!helpMessageShown.addDivider) {
                        handleAddDividerPopup(
                          "Click on plot to place a new vertical divider"
                        );
                        setTimeout(
                          () =>
                            handleDragDividerPopup(
                              "Drag vertical divider left & right"
                            ),
                          5000
                        );
                      }
                      setMouseCoord({ x: null, y: null, mode: "", from: "" });
                    }}
                  />
                </Tooltip>
                <Tooltip placement='bottom' title={"clear all dividers"}>
                  <GrClear
                    className={styles.dividerBtn}
                    onClick={() => setDividers({})}
                  />
                </Tooltip>
                {dividerEdit && (
                  <Tooltip placement='bottom' title={"delete selected divider"}>
                    <GrSubtract
                      className={styles.dividerBtn}
                      onClick={() => {
                        let dividers_ = dividers;
                        delete dividers_[activeDivider];
                        setDividerEdit(false);
                        setDividers({ ...dividers_ }); //force updating dividers object
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          {partitionEdit && (
            <div className={styles.ratioEditorWrapper}>
              <div className={styles.ratioTitle}>
                <p>Ratio Adjust</p>
              </div>
              <div className={styles.ratioEditor}>
                <PercentagesSlider
                  divisions={ratiosChange}
                  setDivisions={setRatiosChange}
                />
                <button
                  // className={styles.applyRatioBtn}
                  onClick={(e) => {
                    setPartitionEdit(true);
                    let newRatios = ratiosChange.reduce(
                      (obj, item) =>
                        Object.assign(obj, {
                          [item.key]: item.percentage / 100,
                        }),
                      {}
                    );

                    let newPartition = partitions[activePartition];
                    newPartition["ratios"] = newRatios;
                    setPartitions({
                      ...partitions,
                      [activePartition]: newPartition,
                    });
                    setPartitionEdit(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}

          <div className={styles.calcButton}>
            <button
              onClick={() => {
                setShowStatisticsWin(true);
              }}
            >
              Calculate Chart
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from "./App";


ReactDOM.render(
  <React.StrictMode>
    {/* <WrapperStyle> */}
      <App />
    {/* </WrapperStyle> */}
  </React.StrictMode>,

  document.getElementById("root")
);

import {
  polygonLayer,
  pointLayer,
  iconLayer,
  cubeLayer,
  lineLayer,
} from "../../../common/common-sublayers";
import { GeoJsonLayer } from "@deck.gl/layers";
import _ from "lodash";
import chroma from "chroma-js";

//------------------ VARIABLES ------------------

// const scale = chroma.scale(["blue", "orange", "red","purple"]);
// const scale = chroma.scale(["#370617", "#0096c7", "#cdb4db", "#d62828"]);
// const scale = chroma.scale(["#3a86ff", "#ffbe0b"]);
const scale = chroma.scale(["#444", "#3a86ff", "#ffbe0b", "#eee"]);
const brewer = chroma.brewer.YlGn.map((el) => chroma(el).rgb()).reverse();

const hoverOptions = {
  power_grid: ["__typename"],
  ev_stations: ["index", "name", "__typename"],
};

//------------------ FUNCTIONS ------------------
const handleHover = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "hover",
      ..._.omit(obj, ["geom"]),
      // ..._.pick(obj, options),
    });
};
const handleClick = (obj, setter, options) => {
  obj &&
    setter({
      EventMode: "click",
      ..._.omit(obj, ["geom"]),
    });
};
//------------------ Layer Specific Options ------------------

const iconLayerOptions = {
  getPosition: (d) => [...d.geom.coordinates, 5],
  sizeMinPixels: 5,
  sizeMaxPixels: 40,
  getSize: (d) => 25,
  sizeScale: 1,
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
      anchorY: 16,
      mask: true,
    },
  },
};
const lineLayerOptions = {
  id: "power_grid_line",
  getColor: (d) =>
    d.voltage
      ? brewer[Math.round((d.voltage / 750000) * 8)]
      : [111, 111, 111, 255],
};
const polyLayerOptions = {};
const cubeLayerOptions = {};
const pointLayerOptions = {};

//------------------ Functions ------------------

export const getBuildings = (
  data,
  selectedItem,
  setHoveredBuildings,
  pickable = false
) => {
  console.log("sublayers ", selectedItem);
  return {
    aaservices_retrofit_3dbldg: polygonLayer(
      data.building_footprint_year_built_height_max.Polygon,
      {
        ...polyLayerOptions,
        id: "00services_retrofit_3dbldg",
        wireframe: false,
        uniqueIdProperty: "index",
        // highlightedFeatureId: selectedItem && selectedItem,
        onHover: ({ x, y, object }) => {
          object
            ? setHoveredBuildings(_.pick(object, ["__typename", "height_max", "index"]))
            : null;
          // object &&
          //   setHoveredBuildings(_.pick(object, ["__typename", "height", "index"]));
        },
        onClick: ({ x, y, object }) => {
          // object && setSelect(object.index);
          // object ? setHoveredBuildings(_.pick(object, ["__typename", "height", "index"])) : null ;
          // object &&
          //   setHoveredBuildings(_.pick(object, ["__typename", "height", "index"]));
        },
        updateTriggers: {
          getFillColor: selectedItem,
        },
        highlightColor: [255, 255, 255, 255],
        autoHighlight: true,
        pickable: pickable,
        opacity: 0.99,
        stroked: true,
        filled: true,
        extruded: true,
        lineWidthMinPixels: 2,
        getElevation: (f) => Math.sqrt(f.height_max) * 5,
        getLineColor: [255, 255, 255],
        // getFillColor: (f) => scale(0.01 * f.properties.height).rgb(),
        getFillColor: (f) => {
          if (parseInt(f.index) === parseInt(selectedItem)) {
            return [255, 255, 255, 255];
          }
          let h = f.height_max;
          return h < 1
            ? [20, 20, 20, 122]
            : h < 20
            ? scale(-0.1 + 0.035 * h).rgb()
            : h < 100
            ? scale(0.7 + 0.0025 * (h - 20)).rgb()
            : scale(0.9 + 0.0005 * (h - 100)).rgb();
        },
      }
    ),
  };
};

import { useState, useEffect, useRef } from "react";
import {
  NEVER,
  interval,
  of,
  from,
  fromEvent,
  BehaviorSubject,
  Subject,
  zip,
  combineLatest,
  lastValueFrom,
} from "rxjs";
import {
  map,
  filter,
  delay,
  delayWhen,
  concatMap,
  switchMap,
  exhaustMap,
  mergeMap,
  mergeScan,
  switchScan,
  withLatestFrom,
  scan,
  take,
  tap,
  bufferCount,
  combineLatestWith,
  debounceTime,
  pairwise,
} from "rxjs/operators";
import _ from "lodash";

import { CONSTANTS } from "../../../../common/settings";

let floatData = {};
// costum hook for stablishing socket connection with server
export const useFetchData = (
  dataSetter,
  bufferPercentageSetter,
  setIsLoading,
  dataIndex,
  socketInstance,
  dataSpeed,
  options,
) => {
  const [buffer$] = useState(new BehaviorSubject({}));
  const [buffer, setBuffer] = useState({});
  const [batchReceived, setBatchReceived] = useState(true);
  const [lastFetchedExtremums, setLastFetchedExtremums] = useState([0, 0]);
  const {channelReq, channelRes, historyLen, emissionIds} = options

  useEffect(() => {
    socketInstance.on(channelRes, (payload) => {
      floatData = payload;
      setBatchReceived(true);
    });

    const buffSub = buffer$
      .pipe(
        tap(dataSetter),
        map((x) => Object.keys(x)),
      )
      .subscribe();
    return () => {
      buffSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    batchReceived ? setIsLoading('transportBuffer', false) : setIsLoading('transportBuffer', true);
  }, [batchReceived]);

  useEffect(() => {
    // console.info("dataIndex: ", dataIndex);
    let buffMax = Math.max(...Object.keys(buffer));
    // console.info("buffMax: ", buffMax - dataIndex);
    bufferPercentageSetter((buffMax - dataIndex) / CONSTANTS.MIN_BUFFER_SIZE);
    if (buffMax - dataIndex < CONSTANTS.MIN_BUFFER_SIZE) {
      batchReceived &&
      socketInstance.emit(
        channelReq,
          (function () {
            setBatchReceived(false);
            return [
              lastFetchedExtremums[1] > 0 ? lastFetchedExtremums[1] : dataIndex,
              Math.round(Math.sqrt(dataSpeed + 8)) *
                CONSTANTS.INDEX_STRIDE *
                CONSTANTS.INDEX_STEP,
                emissionIds,
            ];
          })()
        );
    }
    let newData = { ...buffer, ...floatData };
    newData = _.pick(
      newData,
      Object.keys(newData).filter((e) => e >= dataIndex - historyLen*CONSTANTS.INDEX_STEP)
    ); // keep only data after seeker
    setLastFetchedExtremums([
      Math.min(...Object.keys(newData)),
      Math.max(...Object.keys(newData)),
    ]);
    setBuffer(newData);
  }, [dataIndex]);

  useEffect(() => {
    buffer$.next(buffer);
  }, [buffer]);
};

export const useProcessFloatingData = (data, newDataSetter, dataIndex) => {
  const [index$] = useState(new BehaviorSubject(dataIndex));
  const [data$] = useState(new BehaviorSubject(data));

  useEffect(() => {
    const processSub = index$
      .pipe(
        pairwise(),
        withLatestFrom(data$),
        // tap(console.info),
        scan((acc, el) => {
          // console.info(el)
          let currIndex = el[0][0];
          let nextIndex = el[0][1];
          let currData = el[1][currIndex] ? el[1][currIndex] : [];
          let nextData = el[1][nextIndex] ? el[1][nextIndex] : [];
          let newAgentIds = {
            ...currData.map((el) => el.vname),
            ...nextData.map((el) => el.vname),
          };
          // console.info(nextData)
          return _.values(
            _.merge(
              _.keyBy(currData, "vname"),
              _.keyBy(
                nextData.map((i) => ({
                  vlonNext: i.vlon,
                  vlatNext: i.vlat,
                  vname: i.vname,
                  vclass: i.vname.slice(0, i.vname.search(/\d/)),
                })),
                "vname"
              )
            )
          );
        }),
        tap(newDataSetter)
      )
      .subscribe();
    return () => {
      processSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    index$.next(dataIndex);
  }, [dataIndex]);

  useEffect(() => {
    data$.next(data);
  }, [data]);
};

export const useDataStats = (
  currentFloatData,
  newDataSetter,
  agentIsShown,
  setAgentCount
) => {
  useEffect(() => {
    let newData = currentFloatData.filter((agent) =>
      agentIsShown.has(agent.vclass)
    );
    let newStats = _.countBy(newData, (el) => {
      return el.vclass;
    });
    setAgentCount({ ...newStats, total: newData.length });
    newDataSetter(newData);
  }, [currentFloatData, agentIsShown.size]);
};

export const useDelayLoadingBadge = (flag) => {
  const action$ = useRef(new BehaviorSubject(false)).current;
  const [state, setState] = useState();

  useEffect(() => {
    // use the callback to create the new state$ observable
    const sub = action$.pipe(debounceTime(500), tap(setState)).subscribe();
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    action$.next(flag);
  }, [flag]);

  return state;
};

export const useEmissionData = (
  dataSetter,
  historyLength,
  setIsLoading,
  dataIndex,
  socketInstance
) => {
  const [buffer$] = useState(new BehaviorSubject({}));

  useEffect(() => {
    socketInstance.on("float_res", (payload) => {
      floatData = payload;
      // setBatchReceived(true);
      buffer$.next(payload);
    });

    const buffSub = buffer$
      .pipe(
        tap(console.info)
        // tap(dataSetter),
        // map((x) => Object.keys(x))
      )
      .subscribe();
    return () => {
      buffSub.unsubscribe();
    };
  }, []);
};

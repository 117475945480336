import React from "react";
import { panelsStore } from "../../../../../../../store/store";
import EmisssionChart from "./timeseries";
import EnergyChart from "./energy";
import styles from "./index.module.less";
import Selection from "../selections/agents";

export default function index() {
  // Shared variables
  const activePanels = panelsStore((state) => state.activePanels);
  const setActivePanels = panelsStore((state) => state.setActivePanels);

  return (
    <div className={styles.wrapper}>
      {activePanels.timeseriesPanel && (
        <div className={styles.timeseriesPanel}>
          <Selection />
          <EmisssionChart />
        </div>
      )}
      {activePanels.energyPanel && (
        <div className={styles.energyPanel}>
          <EnergyChart />
        </div>
      )}
    </div>
  );
}

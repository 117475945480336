import React from "react";
import { Card, Typography, Button, Divider, Row, Col } from "antd";
import { statsStore } from "../../../../../../../store/store";
import styles from "./index.module.less";
import { GiFinishLine } from "react-icons/gi";
import { HiOutlineTruck } from "react-icons/hi";
import { GrBus } from "react-icons/gr";
import { GrCar } from "react-icons/gr";
import { MdPedalBike } from "react-icons/md";

const agents = {
  veh: { name: "Car", icon: <GrCar size={22} /> },
  moto: { name: "Bike", icon: <MdPedalBike size={22} /> },
  bus: { name: "Bus", icon: <GrBus size={22} /> },
  truck: { name: "Truck", icon: <HiOutlineTruck size={22} /> },
  null: { name: "Ended", icon: <GiFinishLine size={22} /> },
};

const style = { background: "#0092ff", padding: "8px 0" };

export default function stats() {
  //// LOCAL STATE VARIABLES
  //// SHARED STATE VARIABLES
  const agentCount = statsStore((state) => state.count);
  // const setAgentCount = statsStore(state => state.setCount)
  const agentColor = statsStore((state) => state.color);
  // const setAgentColor = statsStore(state => state.setColor)
  const agentIsShown = statsStore((state) => state.isShown);
  const setAgentIsShown = statsStore((state) => state.setIsShown);
  // const agentSize = statsStore(state => state.size)
  // const setAgentSize = statsStore(state => state.setSize)

  return (
    <div className={styles.wrapper}>
      <Card title='' bordered={false}>
        {/* <Typography.Text italic>click to toggle</Typography.Text> */}
        <Divider orientation='left'>click to toggle</Divider>
        {Object.keys(agents).map((key, ix) => {
          return (
            <Row className={styles.row}>
              <Col className={styles.agentName} span={8}>
                <Typography.Text
                  className={!agentIsShown.has(key) && styles.btnActive}
                >
                  {agents[key].name}
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Button
                  className={!agentIsShown.has(key) && styles.btnActive}
                  style={{ backgroundColor: agentColor[key] }}
                  icon={agents[key].icon}
                  // ghost={agentIsShown.has(key) ? "false" : true}
                  disabled={key === "null" ? true : false}
                  onClick={(e) => {
                    setAgentIsShown(key, !agentIsShown.has(key));
                  }}
                ></Button>
              </Col>
              <Col className={styles.agentCount} span={8}>
                <Typography.Text
                  className={!agentIsShown.has(key) && styles.btnActive}
                >
                  {agentCount[key] ? agentCount[key] : 0}
                </Typography.Text>
              </Col>
            </Row>
          );
        })}
      </Card>
    </div>
  );
}

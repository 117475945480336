import Canvas from "./components/canvas";
import { ApolloProvider } from "@apollo/client";
import { Client } from "./lib/apolloClient";
import { SocketContext, socket } from "./lib/socketClient";
import "./app.less";

function App() {
  return (
    <>
      <SocketContext.Provider value={socket}>
        <ApolloProvider client={Client}>
          <Canvas />
        </ApolloProvider>
      </SocketContext.Provider>
    </>
  );
}

export default App;

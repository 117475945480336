import React, { useState } from "react";
import styles from "./index.module.less";
import Playback from "./playback";
import Stats from "./stats";
import Charts from "./charts";
import {controlStore, panelsStore} from '../../../../../../store/store'

export default function index() {
  const [showStats, setShowStats] = useState(true);
  const [showPlayback, setShowPlayback] = useState(true);

  const layersShown = controlStore((state) => state.layersShown);
  const activePanels = panelsStore((state) => state.activePanels);
  const showServicesPanels = panelsStore((state) => state.showServicesPanels)

  return (
    <div className={showServicesPanels ? styles.wrapper + " " + styles.servicesIsActive : styles.wrapper}>
      {activePanels.timeseriesPanel && <Charts />}
      {showPlayback && <Playback />}
      {showStats && <Stats />}
    </div>
  );
}

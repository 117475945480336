/* global window */
import React, { useState, useEffect, useCallback, useRef } from "react";
import DeckGL from "@deck.gl/react";
import { MapView, FlyToInterpolator, LinearInterpolator } from "@deck.gl/core";
import ReactMapGL, { ScaleControl, NavigationControl } from "react-map-gl";
import Map from "react-map-gl";
import _ from "lodash";
import {
  controlStore,
  systemStore,
  toolboxStore,
  panelsStore,
} from "../../store/store";
import * as layersSettings from "./common/settings";
import * as layersInteraction from "./common/interaction";
import Toolbox from "./draw-toolbox/toolbox";
import CameraSwitch from "../canvas/camera-animation";

import TransportLayer from "./transport-layer";
import BuildingsLayer from "./buildings-layer";
import EnergyLayer from "./energy-layer";
import GreeneryLayer from "./ecosystem-layer";
import WasteLayer from "./waste-layer";

const MAPBOX_TOKEN = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

//
//
//

export default function Layers({ children }) {
  //// LOCAL STATE VARIABLES
  const [trafficLayer, setTrafficLayer] = useState(null);
  const [buildingLayer, setBuildingLayer] = useState(null);
  const [energyLayer, setEnergyLayer] = useState(null);
  const [greeneryLayer, setGreeneryLayer] = useState(null);
  const [wasteLayer, setWasteLayer] = useState(null);
  const [toolboxLayer, setToolboxLayer] = useState(null);
  const [layersStack, setLayersStack] = useState([]);
  // const [initialViewState, setInitialViewState] = useState(
  //   layersSettings.initialViewState
  // );

  // const [viewSt, setViewSt] = useState({
  //   latitude: 45.5088722439033,
  //   longitude: -73.5660288811407,
  //   pitch: 0,
  //   bearing: 0,
  //   zoom: 10,
  //   // latitude={47.7577}
  //   // longitude={-73.4376}
  //   // zoom={14}
  // });

  //// SHARED STATE VARIABLES
  const layersShown = controlStore((state) => state.layersShown);
  const setLayersShown = controlStore((state) => state.setLayersShown);
  const baseMapStyle = systemStore((state) => state.baseMapStyle);
  const viewState = systemStore((state) => state.viewState);
  const setViewState = systemStore((state) => state.setViewState);
  const activeServices = panelsStore((state) => state.activeServices);
  // const toolboxLayer = toolboxStore((state) => state.toolboxLayer);

  useEffect(() => {
    console.log('buildingLayer', buildingLayer )
    console.log('trafficLayer', trafficLayer )
    console.log('energyLayer', energyLayer )
    console.log('greeneryLayer', greeneryLayer )
    console.log('wasteLayer', wasteLayer )
    console.log('toolboxLayer', toolboxLayer )
    const lyrs = [
      ...(layersShown.built ? buildingLayer : []),
      ...(layersShown.transport ? trafficLayer : []),
      ...(layersShown.energy ? energyLayer : []),
      ...(layersShown.ecosystem ? greeneryLayer : []),
      ...(layersShown.waste ? wasteLayer : []),
      ...(activeServices.retrofitAnalysis ? [toolboxLayer] : []),
    ];
    setLayersStack(lyrs);
  }, [
    layersShown,
    activeServices,
    buildingLayer,
    energyLayer,
    trafficLayer,
    greeneryLayer,
    wasteLayer,
    toolboxLayer,
  ]);

  // const onViewStateChange = ({ viewState }) => {
  //   console.log("viewState", viewState);
  //   // Manipulate view state
  //   // Save the view state and trigger rerender
  //   setViewState(viewState);
  // }

  useEffect(() => {
    layersShown.built ? setViewState(() => ({ ...viewState, zoom: 13 })) : null;
    // console.log("viewState", viewState);
  }, [layersShown]);

  const rotateCamera = useCallback(() => {
    setViewState((viewState) => {
      let newViewState = {
        ...viewState,
        transitionDuration: 1000,
        transitionInterpolator: new LinearInterpolator({
          transitionProps: [
            "bearing",
            "zoom",
            "longitude",
            "latitude",
            "pitch",
          ],
        }),
        onTransitionEnd: rotateCamera,
      };

      newViewState = { ...newViewState, bearing: viewState.bearing + 10 };

      if (viewState.zoom < 14 && viewState.pitch < 60) {
        newViewState = { ...newViewState, zoom: viewState.zoom + 1 };
      }
      if (viewState.pitch < 60) {
        newViewState = { ...newViewState, pitch: viewState.pitch + 5 };
      }

      if (viewState.pitch === 60 && viewState.zoom > 13) {
        if (!layersShown.built_buildingsmodel) {
          setLayersShown("built", true);
          setLayersShown("built_buildingsmodel", true);
        }
        newViewState = { ...newViewState, zoom: viewState.zoom - 0.1 };
      }

      return newViewState;
    });
  }, [layersShown]);

  const layerFilter = useCallback(({ layer, viewport }) => {
    var props;
    props = layer.parent ? layer.parent.props : layer.props;
    if (viewport.id === "minimap") {
      return false;
    }
    if (props.viewProps !== undefined) {
      const { zoomMin, zoomMax, viewIds } = props.viewProps;

      if (viewport.zoom < zoomMin && viewIds.includes(viewport.id)) {
        return false;
      }
      if (viewport.zoom > zoomMax && viewIds.includes(viewport.id)) {
        return false;
      }
    }

    return true;
  });

  return (
    <>
      <DeckGL
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        layerFilter={layerFilter}
        views={[
          new MapView({ id: "main", controller: true, orthographic: false }),
          // new MapView({
          //   id: "minimap",
          //   // style: {
          //   //   border: "3px solid white"
          //   // },
          //   border: "3px solid white",
          //   x: 40,
          //   y: 10,
          //   width: "20%",
          //   height: "20%",
          //   controller: true,
          // }),
        ]}
        // onViewStateChange={onViewStateChange}
        getTooltip={layersInteraction.getTooltip}
        layers={layersStack}
        effects={layersSettings.theme.effects}
        // initialViewState={layersSettings.initialViewState}
        getCursor={() => "arrow"}
        pickingRadius={10}
        controller={{ doubleClickZoom: false, inertia: true }}
        // viewState={viewState}
        initialViewState={viewState}
        // controller={true}
      >
        {/* <BuildingsLayer setLayer={setBuildingLayer} /> */}
        {/* <TransportLayer setLayer={setTrafficLayer} />
        <BuildingsLayer setLayer={setBuildingLayer} />
        <EnergyLayer setLayer={setEnergyLayer} />
        <GreeneryLayer setLayer={setGreeneryLayer} />
        <WasteLayer setLayer={setWasteLayer} /> */}
        <Map
          // {...viewState}
          width={400}
          height={400}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={layersSettings.MAP_STYLES[baseMapStyle]}
          // onViewportChange={(viewport) => {
          //   // const { width, height, latitude, longitude, zoom } = viewport;
          //   console.log("viewport   ", viewport);
          //   // call `setState` and use the state to update the map.
          // }}
        ></Map>
      </DeckGL>
      {/* {true && <Toolbox setter={setToolboxLayer} />} */}
      <TransportLayer setLayer={setTrafficLayer} />
      <BuildingsLayer setLayer={setBuildingLayer} />
      <EnergyLayer setLayer={setEnergyLayer} />
      <GreeneryLayer setLayer={setGreeneryLayer} />
      <WasteLayer setLayer={setWasteLayer} />
      {/* <CameraSwitch rotateCamera={rotateCamera} /> */}
      {activeServices.retrofitAnalysis && <Toolbox setter={setToolboxLayer} />}
    </>
  );
}

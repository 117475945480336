import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { controlStore, systemStore, statsStore, dataStore } from "../../../../store/store";
import {
  getPowerGrid,
  getEvStations,
  getGasStations,
  getOilGas,
} from "./sublayers";

import { useLazyQuery } from "@apollo/client";
import { dataSplitbyGeom } from "../../helpers";
import { EVQuery, GasQuery, powerGridQuery, oilGasQuery } from "../../queries";

export default function energyObject(props) {
  //------------------ LOCAL STATES ------------------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [layers, setLayers] = useState({});

  //------------------ SHARED STATES ------------------
  const layersPickable = controlStore((state) => state.layersPickable);
  const layersShown = controlStore((state) => state.layersShown);
  const setHoveredBuildings = statsStore((state) => state.setHoveredBuildings);
  const setIsLoading = systemStore((state) => state.setIsLoading);
  //------------------ FUNCTIONS ------------------

  //------------------ FUNCTIONS ------------------
  //------------------ HOOKS ------------------
  const [fetchFuncEV, fetchObjEv] = useLazyQuery(EVQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
  });
  const [fetchFuncGas, fetchObjGas] = useLazyQuery(GasQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
  });
  const [fetchFuncGrid, fetchObjGrid] = useLazyQuery(powerGridQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
  });
  const [fetchFuncOil, fetchObjOil] = useLazyQuery(oilGasQuery, {
    fetchPolicy: "cache-first", // checks cache before making a network request
  });

  //------------------ SIDE EFFECTS ------------------
  useEffect(() => {
    if (
      fetchObjEv.loading ||
      fetchObjGas.loading ||
      fetchObjGrid.loading ||
      fetchObjOil.loading
    ) {
      setIsLoading("main", true);
    } else {
      setIsLoading("main", false);
    }
  }, [
    fetchObjEv.loading,
    fetchObjGas.loading,
    fetchObjGrid.loading,
    fetchObjOil.loading,
  ]);

  useEffect(() => {
    if (!fetchObjEv.data) {
      if (layersShown["energy_evstations"]) {
        console.log("fetchFuncEV Triggered");
        fetchFuncEV();
      }
    }
    if (!fetchObjGas.data) {
      if (layersShown["energy_gasstations"]) {
        console.log("fetchFuncGas Triggered");
        fetchFuncGas();
      }
    }
    if (!fetchObjGrid.data) {
      if (layersShown["energy_powergrid"]) {
        console.log("fetchFuncGrid Triggered");
        fetchFuncGrid();
      }
    }
    if (!fetchObjOil.data) {
      if (layersShown["energy_oilgas"]) {
        console.log("fetchFuncOil Triggered");
        fetchFuncOil();
      }
    }
  }, [layersShown]);

  useEffect(() => {
    let layersObj = {};
    if (fetchObjEv.data && layersShown["energy_evstations"]) {
      let dataObj = dataSplitbyGeom(fetchObjEv.data);
      layersObj = {
        ...layersObj,
        ...getEvStations(
          dataObj,
          setHoveredBuildings,
          layersPickable["energy_evstations"]
        ),
      };
    }
    if (fetchObjGas.data && layersShown["energy_gasstations"]) {
      let dataObj = dataSplitbyGeom(fetchObjGas.data);
      layersObj = {
        ...layersObj,
        ...getGasStations(
          dataObj,
          setHoveredBuildings,
          layersPickable["energy_gasstations"]
        ),
      };
    }
    if (fetchObjGrid.data && layersShown["energy_powergrid"]) {
      let dataObj = dataSplitbyGeom(fetchObjGrid.data);
      layersObj = {
        ...layersObj,
        ...getPowerGrid(
          dataObj,
          setHoveredBuildings,
          layersPickable["energy_powergrid"]
        ),
      };
    }
    if (fetchObjOil.data && layersShown["energy_oilgas"]) {
      let dataObj = dataSplitbyGeom(fetchObjOil.data);
      layersObj = {
        ...layersObj,
        ...getOilGas(dataObj, setHoveredBuildings, layersPickable["energy_oilgas"]),
      };
    }

    // update layers on any change
    props.setLayer(_.values(layersObj));
    // setLayers_({...layers_, ...layersObj})
    // props.setLayer(_.values(expandSublayers(layers)));
  }, [
    fetchObjEv.data,
    fetchObjGas.data,
    fetchObjGrid.data,
    fetchObjOil.data,
    layersShown,
    layersPickable,
  ]);

  return null;
}

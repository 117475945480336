import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";

export const CONSTANTS = {
    MIN_BUFFER_SIZE: 200,
    INDEX_STEP: 5,
    INDEX_STRIDE: 10,
    INDEX_LOOP_LENGTH: 4000,
}

const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 1.5,
  });
  
  const pointLight = new PointLight({
    color: [255, 255, 255],
    intensity: 1.0,
    position: [-73.6154222, 45.486973, 8000],
  });
  

// export const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/voyager-nolabels-gl-style/style.json';
// export const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';
// export const MAP_STYLE =
//   "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";
// export const MAP_STYLE = 'mapbox://styles/miladnyu/ckiazf01c092e19s1k7fjbor4/draft';

export const MAP_STYLES = {
  dark1: 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json',
  light1: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
  dark2: 'mapbox://styles/miladnyu/ckiazf01c092e19s1k7fjbor4/draft',
  light2: 'https://basemaps.cartocdn.com/gl/voyager-nolabels-gl-style/style.json',
}


export const theme = {
  buildingColor: [7, 59, 76, 255],
  buildingOpacity: 0.99,
  material: {
    ambient: 0.1,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [0, 64, 70],
  },
  effects: [new LightingEffect({ ambientLight, pointLight })],
};
import React, { useState } from "react";
import RetrofitPanels from "./building-retrofit";
import { panelsStore, toolboxStore, controlStore } from "../../store/store";
import Sidebar from "./sidebar";

export default function Panels() {
	const layersShown = controlStore(state => state.layersShown);
	const activeServices = panelsStore(state => state.activeServices);
	const toolboxShowGraph = toolboxStore(state => state.toolboxShowGraph);

	return (
		<>
			{activeServices.retrofitAnalysis && toolboxShowGraph && (
				<RetrofitPanels />
			)}
			{!activeServices.retrofitAnalysis && <Sidebar />}
		</>
	);
}

import React, { useState, useEffect } from "react";
import { Card, Typography, Button, Divider, Tag, Progress } from "antd";
import styles from "./index.module.less";
import { BsPauseBtn } from "react-icons/bs";
import { BsPlayBtn } from "react-icons/bs";
import { BsSkipForwardBtn } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { SyncOutlined } from "@ant-design/icons";
import {
  handleClickReverse,
  handleClickPlay,
  handleClickPause,
  handleClickFaster,
} from "../../../../../../../utils/control-handlers";
import { dataStore, controlStore, systemStore } from "../../../../../../../store/store";
import { useDelayLoadingBadge } from "../../hooks/hooks";

const progressbarColors = (percent) => {
  if (percent > 80) {
    return "#04ff00";
  } else if (percent > 60) {
    return "#aeff00";
  } else if (percent > 30) {
    return "#ffa600";
  } else {
    return "#ff3c00";
  }
};

const BUFFER_SCALE = 40;
export default function playback() {
  //// LOCAL STATE VARIABLES
  const [clicked, setClicked] = useState({
    reverse: false,
    play: true,
    pause: false,
    faster: false,
  });
  const [lastState, setLastState] = useState({
    speed: 1,
    clicked: { reverse: false, play: true, pause: false, faster: false },
  });

  //// SHARED STATE VARIABLES
  const dataSpeed = controlStore((state) => state.speed);
  const setDataSpeed = controlStore((state) => state.setSpeed);
  const dataIndex = dataStore((state) => state.index);
  const setDataIndex = dataStore((state) => state.setIndex);
  const bufferSize = systemStore((state) => state.bufferSize);
  const setBufferSize = systemStore((state) => state.setBufferSize);
  const isLoading = systemStore((state) => state.isLoading);

  //// HOOKS
  const isLoadingFlag = useDelayLoadingBadge(isLoading.transportBuffer);

  const fasterFunc = () =>
    handleClickFaster(
      dataSpeed,
      clicked,
      setDataSpeed,
      setClicked,
      setLastState
    );
  const pauseFunc = () =>
    handleClickPause(
      dataSpeed,
      clicked,
      lastState,
      setDataSpeed,
      setClicked,
      setLastState
    );
  const playFunc = () =>
    handleClickPlay(dataSpeed, clicked, setDataSpeed, setClicked, setLastState);
  const resetFunc = () =>
    handleClickReverse(
      dataSpeed,
      clicked,
      lastState,
      setDataIndex,
      setDataSpeed,
      setClicked,
      setLastState
    );

  //// SIDE EFFECTS

  return (
    <div className={styles.wrapper}>
      <Card title='' bordered={false}>
        <div className={styles.firstRow}>
          <Typography.Text>Elapsed Time</Typography.Text>
          <Typography.Text>{dataIndex.toString().toHHMMSS()}</Typography.Text>
        </div>
        <div className={styles.secondRow}>
          <Typography.Text>
            speed {dataSpeed < 0 ? "Reverse" : dataSpeed + "x"}
          </Typography.Text>
        </div>
        <div className={styles.thirdRow}>
          <Typography.Text>buffer</Typography.Text>
          <Progress
            steps={20}
            size='small'
            strokeColor={progressbarColors(
              bufferSize * BUFFER_SCALE > 100 ? 100 : bufferSize * BUFFER_SCALE
            )}
            strokeWidth={4}
            percent={
              bufferSize * BUFFER_SCALE > 100 ? 100 : bufferSize * BUFFER_SCALE
            }
          ></Progress>
          <Tag
            style={isLoadingFlag ? {} : { visibility: "hidden" }}
            icon={<SyncOutlined spin />}
          ></Tag>
        </div>

        <div className={styles.lastRow}>
          <Button
            className={clicked.reverse && styles.btnActive}
            icon={<BiReset size={20} />}
            onClick={resetFunc}
          ></Button>
          <Button
            className={clicked.pause && styles.btnActive}
            icon={<BsPauseBtn size={20} />}
            onClick={pauseFunc}
          ></Button>
          <Button
            className={clicked.play && styles.btnActive}
            icon={<BsPlayBtn size={20} />}
            onClick={playFunc}
          ></Button>
          <Button
            className={clicked.faster && styles.btnActive}
            icon={<BsSkipForwardBtn size={20} />}
            ghost={true}
            onClick={fasterFunc}
          ></Button>
        </div>
      </Card>
    </div>
  );
}

// Helper Func
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

import React, { useState } from "react";
import { InputNumber, Tooltip } from "antd";
import styles from "./number.module.less";

const INIT_VAL = 60;
const inputStep = 5;
export default function number(props) {
  const [inputValue, setInputValue] = useState(INIT_VAL);

  const onInputChange = (value) => {
    props.historyLenSetter(value);
    setInputValue(value)
  };

  return (
    <div className={styles.wrapper}>
      <Tooltip
        mouseEnterDelay={0.3}
        mouseLeaveDelay={0.5}
        placement='bottom'
        title={"length of history (seconds)"}
      >
        <InputNumber
          size='small'
          formatter={(num) => num + " seconds"}
          parser={(num) => num.match(/\d+/g)[0]}
          min={1}
          max={100000}
          defaultValue={INIT_VAL}
          onChange={onInputChange}
          value={inputValue}
          onStep={(e) => setInputValue(inputValue + inputStep)}
          onPressEnter={e=>console.info(e)}
        />
      </Tooltip>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import styles from "./row.module.less";
import { Button } from "antd";
import { GrSelect } from "react-icons/gr";
import { GrFormView, GrFormViewHide } from "react-icons/gr";
import { GrFormClose } from "react-icons/gr";
import { panelsStore, controlStore } from "../../store/store";


export default function Row(props) {

  const handleButtonClick = useCallback((target, key) => {
    props.setClicked({ target, key });
  }, []);

  return (
    <div className={styles.row}>
      <div className={styles.rowTitle}>{props.obj.text}</div>
      <div className={styles.rowButtons}>
        <Button onClick={() => handleButtonClick("show", props.obj.key)}>
          {props.obj.show ? <GrFormView /> : <GrFormViewHide />}
        </Button>
        {props.obj.isPickableShown && (
          <Button onClick={() => handleButtonClick("pickable", props.obj.key)}>
            <GrSelect
              style={props.obj.pickable ? {} : { filter: "invert(40%)" }}
            />
          </Button>
        )}
        {props.obj.isCloseShown && (
          <Button onClick={() => handleButtonClick("close", props.obj.key)}>
            <GrFormClose />
          </Button>
        )}
      </div>
    </div>
  );
}

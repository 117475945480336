import { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import proj4 from "proj4";
import { extrudePolygon } from "geometry-extrude";
import styles from "./styles.module.less";

const ThreeCanvas = ({ selection }) => {
  const canvasRef = useRef(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [geometry, setGeometry] = useState(null);

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      setHeight(canvasRef.current.offsetHeight);
      setWidth(canvasRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (selection.target && selection.target.coords) {
      const geometryProjected = selection.target.coords[0].map((coord) =>
        proj4("EPSG:4326", "EPSG:3857", coord)
      );
      const { indices, position, uv, normal } = extrudePolygon(
        [[geometryProjected]],
        {
          depth: 1,
          fitRect: { x: -1, y: -1, width: 2, height: 2 },
          excludeBottom: false,
        }
      );
      const geometryObj = new THREE.BufferGeometry();
      geometryObj.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(position, 3)
      );
      geometryObj.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(normal, 3)
      );
      geometryObj.setAttribute("uv", new THREE.Float32BufferAttribute(uv, 2));
      geometryObj.setIndex(new THREE.Uint16BufferAttribute(indices, 1));
      setGeometry(geometryObj);
    }
  }, [selection]);

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      var scene = new THREE.Scene();
      scene.background = new THREE.Color(0xfafafa);

      const axesHelper = new THREE.AxesHelper(2);
      scene.add(axesHelper);

      var camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
      camera.up = new THREE.Vector3(0, 0, 1);

      var renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
      renderer.setSize(width, width);
      renderer.domElement.id = "scene-box";

      canvasRef.current && canvasRef.current.appendChild(renderer.domElement);

      var boom = new THREE.Group();
      var ambLight = new THREE.AmbientLight(0xffffff, 0.2);
      boom.add(ambLight);

      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(3, 3, 3);
      pointLight.castShadow = true;
      boom.add(pointLight);

      const sphereSize = 1;
      const pointLightHelper = new THREE.PointLightHelper(
        pointLight,
        sphereSize
      );
      boom.add(pointLightHelper);

      scene.add(boom);
      var material = new THREE.MeshPhongMaterial({
        ambient: 0xffffff,
        color: 0xccccff,
        specular: 0xffffff,
        shininess: 20,
      });

      const plane = new THREE.Mesh(
        new THREE.PlaneGeometry(5, 5),
        new THREE.MeshStandardMaterial({
          color: 0x00ff00,
        })
      );
      plane.receiveShadow = true;
      plane.position.x = 1;
      plane.position.y = 1;
      plane.position.z = 0;
      plane.rotation.x = 0;
      plane.rotation.y = 0;
      plane.rotation.z = 0;
      scene.add(plane);

      if (geometry) {
        var model = new THREE.Mesh(geometry, material);
        model.castShadow = true;
        // model.receiveShadow = false;
        scene.add(model);
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enablePan = false;
        // controls.update();
        camera.position.x = 5;
        camera.position.y = 5;
        camera.position.z = 5;

        camera.lookAt([0, 0, 0]);
        var animate = function () {
          // model.rotation.x += 0.01;
          // model.rotation.z += 0.03;
          boom.rotation.z += 0.01;
          // camera.rotation.z += 0.3;
          // model.position.y += 0.01;
          controls.update();
          renderer.render(scene, camera);
          requestAnimationFrame(animate);
        };
        animate();
      }
    }
    return () => {
      // remove all appended childrens upon unmounting
      if (canvasRef.current) {
        while (canvasRef.current.firstChild) {
          canvasRef.current.removeChild(canvasRef.current.firstChild);
        }
      }
    };
  }, [geometry]);

  return (
    <div id='canvas-three' ref={canvasRef}>
      {!geometry && "Please Select a Building"}
    </div>
  );
};

export default ThreeCanvas;

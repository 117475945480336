import React, { useEffect } from "react";
import MapLayer from "./layer";
import Panels from "./panels";

export default function index({setLayer}) {
  return (
    <>
      <MapLayer setLayer={setLayer} />
      <Panels />
    </>
  );
}

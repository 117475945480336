//////// SERVICES STORE
export const retrofitSelectedData = []
export const selectedBuildings = { target: undefined, adjacent: undefined }

//////// TOOLBOX STORE
export const toolboxLayer = []
export const toolboxGeoJson = {
  type: 'FeatureCollection',
  features: [],
}
export const toolboxSelectApply = false
export const toolboxCalcGraph = false
export const toolboxShowGraph = false

//////// DATA STORE
export const index_val = 0
export const floatingData = []
export const settingsData = {}
export const layers = {}

//////// CONTROL TOOLS STORE
export const speed = 1
export const layersShown = {}
export const layersPickable = {}
export const hideAll = false

//////// AGENTS STATS STORE
export const count = { veh: 0, moto: 0, bus: 0, truck: 0, null: 0 }
export const color = {
  veh: '#e41749',
  moto: '#FFD523',
  bus: '#00ff00',
  truck: '#19D3DA',
  null: '#fff',
}
export const isShown = new Set(['veh', 'moto', 'bus', 'truck'])
export const selectedAgents = new Set([])
export const hoveredBuildings = []
export const selectedItems = []
export const chartHoveredItems = undefined
export const size = { veh: 50, moto: 20, bus: 80, truck: 140, null: 140 }

//////// SYSTEM TOOLS STORE
export const isLoading = { main: false, transportBuffer: false }
export const viewState = {
  latitude: 45.5088722439033,
  longitude: -73.5660288811407,
  pitch: 40,
  bearing: 0,
  zoom: 14,
}
export const mouseState = {}
export const bufferSize = 0
export const baseMapStyle = 'dark2'

//////// PANELS & CHARTS STORE
export const showServicesPanels = true
export const activePanels = {
  timeseriesPanel: false,
  energyPanel: false,
  settingsPanel: false,
  ODPanel: false,
  settingsPanel: false,
}
export const activeServices = {
  retrofitAnalysis: false,
  eplusTool: false,
  wasteOptimization: false,
}
export const timeseriesData = {}
export const mainLayerOptions = [
  {
    name: 'built',
    titleText: 'Built Env',
    key: 'built',
    path: '1',
    id: '1',
  },
  {
    name: 'building_model',
    titleText: 'Buildings 3D Model',
    key: 'built_buildingsmodel',
    path: '1.1',
    id: '2',
  },
  {
    name: 'building_footprint',
    titleText: 'Buildings Footprint',
    key: 'built_buildingsfootprint',
    path: '1.2',
    id: '3',
  },
  {
    name: 'transport',
    titleText: 'Transport',
    key: 'transport',
    path: '2',
    id: '4',
  },
  {
    name: 'traffic_flow',
    titleText: 'Traffic Flow',
    key: 'transport_flow',
    path: '2.1',
    id: '5',
  },
  {
    name: 'bus_network',
    titleText: 'Bus Network',
    key: 'transport_bus_network',
    path: '2.2',
    id: '6',
  },
  {
    name: 'bus_routes',
    titleText: 'Bus Routes',
    key: 'transport_bus_routes',
    path: '2.2.1',
    id: '7',
  },
  {
    name: 'bus_stations',
    titleText: 'Bus Stations',
    key: 'transport_bus_stations',
    path: '2.2.2',
  },
  {
    name: 'subway_network',
    titleText: 'Subway Network',
    key: 'transport_subway_network',
    path: '2.3',
    id: '9',
  },
  {
    name: 'subway_lines',
    titleText: 'Subway Lines',
    key: 'transport_subway_lines',
    path: '2.3.1',
    id: '10',
  },
  {
    name: 'subway_stations',
    titleText: 'Subway Stations',
    key: 'transport_subway_stations',
    path: '2.3.2',
    id: '11',
  },
  {
    name: 'energy',
    titleText: 'Energy',
    key: 'energy',
    path: '3',
    id: '12',
  },
  {
    name: 'ev_stations',
    titleText: 'EV Stations',
    key: 'energy_evstations',
    path: '3.1',
    id: '13',
  },
  {
    name: 'gas_stations',
    titleText: 'Gas Stations',
    key: 'energy_gasstations',
    path: '3.2',
    id: '14',
  },
  {
    name: 'power_grid',
    titleText: 'Power Grid',
    key: 'energy_powergrid',
    path: '3.3',
    id: '15',
  },
  {
    name: 'oilgas_infra',
    titleText: 'Oil & Gas Installments',
    key: 'energy_oilgas',
    path: '3.4',
    id: '16',
  },
  {
    name: 'waste',
    titleText: 'Waste',
    key: 'waste',
    path: '4',
    id: '17',
  },
  {
    name: 'collection_zones',
    titleText: 'Collection Zones',
    key: 'waste_collection',
    path: '4.1',
    id: '18',
  },
  {
    name: 'waste_sites',
    titleText: 'Waste Sites',
    key: 'waste_sites',
    path: '4.2',
    id: '19',
  },
  {
    name: 'ecosystem',
    titleText: 'Ecosystem',
    key: 'ecosystem',
    path: '5',
    id: '20',
  },
  {
    name: 'public_trees',
    titleText: 'Public Trees',
    key: 'ecosystem_publictrees',
    path: '5.1',
    id: '21',
  },
  {
    name: 'air_quality',
    titleText: 'Air Quality Index',
    key: 'ecosystem_airqualityindex',
    path: '5.2',
    id: '22',
  },
  {
    name: 'bike_network',
    titleText: 'Bike Network',
    key: 'transport_bikenetwork',
    path: '2.4',
    id: '23',
  },
  {
    name: 'bike_lanes',
    titleText: 'Bike Lanes',
    key: 'transport_bikenetwork_lanes',
    path: '2.4.1',
    id: '24',
  },
  {
    name: 'bike_stations',
    titleText: 'Bike Stations',
    key: 'transport_bikenetwork_stations',
    path: '2.4.2',
    id: '25',
  },
  {
    name: 'road_collision',
    titleText: 'Road Collision',
    key: 'transport_roadcollision',
    path: '2.5',
    id: '26',
  },
]

export const customLayerOptions = [
  {
    id: '1',
    key: 'services',
    name: 'services',
    path: '1',
    titleText: 'Services',
    show: false,
  },
  {
    id: '2',
    key: 'built_eplustool',
    name: 'building_eplus_tool',
    path: '1.1',
    titleText: 'Buildings Energy+',
    show: false,
  },
  {
    id: '3',
    key: 'built_buildingsretrofit',
    name: 'building_retrofit',
    path: '1.2',
    titleText: 'Buildings Retrofit',
    show: false,
  },
  {
    id: '4',
    key: 'waste_optimization',
    name: 'waste_optimization',
    path: '1.3',
    titleText: 'Waste Optimization',
    show: false,
  },
]

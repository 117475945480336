


export const tooltipLoadBox = (obj) => {
  // var imgHandler = document.getElementById("img");
  let p = document.createElement("p");
  p.setAttribute("id", "indicator");
  p.innerText = obj.pos;
  p.style.cssText =
    "position:relative;background-color:gray;color:white;width:20vw,height:15vh";
  document.body.appendChild(p);

  let toolt = $(".deck-tooltip");
  console.log(toolt);
  toolt[0].style.cssText = "background-color:white;";
}

export const tooltipUnloadBox = () => {
  setTimeout(function () {
    document.getElementById("indicator").remove();
  }, 10);
}

export const getTooltip = ({ object }) => {
  // console.log(object)
  // return (
  //   object && {
  //     body: `
  //      <div id="infobox"
  //           src="/index.png"
  //           onload="${tooltipLoadBox(object)}"
  //           onunload="${tooltipUnloadBox()}"
  //           height=111
  //           width=222
  //           >
  //     `,
  //     style: {
  //       height: '20px',
  //       width: '30px',
  //       // backgroundImage: 'url(/index.png)',
  //       // position: 'fixed',
  //       // left: '0px',
  //       // top: '0px',
  //       // color: 'white',
  //     }
  //   }
  // );
};



import React from "react";
import MapLayer from "./map";
// import Panels from "./panels";

export default function index(props) {
  return (
    <>
      <MapLayer setLayer={props.setLayer}/>
      {/* {props.shown && <Panels/>} */}
    </>
  );
}

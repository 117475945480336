import React, { useState, useMemo, useEffect, useRef } from "react";
import { ScatterplotLayer } from "@deck.gl/layers";
import { DataFilterExtension, BrushingExtension } from "@deck.gl/extensions";
import RangeInput from "./slider-input";
import AnalysisPanel from "./analysis-panel";
import styles from "./styles.module.less";
import { useLoadByREST } from "../hooks";
import { controlStore, systemStore } from "../../../../../store/store";
import chroma from "chroma-js";
import { WebMercatorViewport } from "deck.gl";

//------------------ VARIABLES ------------------

const scale = chroma.scale(["#58f", "#ee2", "#f35"]);

// Source data GeoJSON
const DATA_URL =
  "https://raw.githubusercontent.com/uber-web/kepler.gl-data/master/earthquakes/data.csv"; // eslint-disable-line

const queryOptions = {
  baseURL: "https://www.donneesquebec.ca/recherche/api/3/action",
  resource_id: "05deae93-d9fc-4acb-9779-e0942b5e962f",
  limit: 5e5,
};

const TIME_DIVIDER = 3600000;
const BRUSH_OPTIONS = { defaultValue: 2000, min: 0, max: 10000 };

const dataBrush = new BrushingExtension();
const dataFilter = new DataFilterExtension({
  filterSize: 1,
  fp64: false,
});

function formatLabel(t) {
  const date = new Date(t * TIME_DIVIDER);
  return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${
    date.getUTCDate() + 1
  }
   : ${date.getUTCHours()}`;
  //   return `${date}`;
}

const parseDateTime = (d) => {
  const str = d.HEURE_ACCDN.includes("-")
    ? d.DT_ACCDN + " " + d.HEURE_ACCDN.split("-")[0]
    : d.DT_ACCDN;
  return Date.parse(str) / TIME_DIVIDER;
};

function getTimeRange(data) {
  if (!data) {
    return null;
  }
  return data.reduce(
    (range, d) => {
      const t = parseDateTime(d);
      range[0] = Math.min(range[0], t);
      range[1] = Math.max(range[1], t);
      return range;
    },
    [Infinity, -Infinity]
  );
}

function getTooltip({ object }) {
  return (
    object &&
    `\
    Time: ${new Date(object.timestamp).toUTCString()}
    Magnitude: ${object.magnitude}
    Depth: ${object.depth}
    `
  );
}

const layerProps = {
  getPosition: (d) => {
    return [parseFloat(d.LOC_LONG), parseFloat(d.LOC_LAT)];
  },
  opacity: 1.0,
  wrapLongitude: true,
  viewProps: { zoomMin: 3, zoomMax: 24, viewIds: ["main"] },
  getFillColor: (d) => [
    ...scale(
      Number(d.VITESSE_AUTOR) < 30 ? 0.1 : (Number(d.VITESSE_AUTOR) - 20) / 30
    ).rgb(),
    148,
  ],
  // getRadius: (d) => {
  //   return Number(d.VITESSE_AUTOR) < 30
  //     ? 1
  //     : (Number(d.VITESSE_AUTOR) - 20) / 3;
  // },
  getRadius: 10,
  radiusScale: 1,
  radiusMinPixels: 1.5,
  radiusMaxPixels: 10,
  stroked: false,
  lineWidthMinPixels: 0,
  lineWidthMaxPixels: 1,
  getLineColor: [255, 255, 255, 55],
};
export default function Index({ setLayer }) {
  const [filter, setFilter] = useState(null);
  const [filterValue, setFilterValue] = useState([0, 0]);
  const [data, setData] = useState(null);
  const [toggleBrush, setToggleBrush] = useState(false);
  const [brushRadius, setBrushRadius] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(30);
  const [hoveredCoords, setHoveredCoords] = useState(null);

  const mouseState = systemStore((state) => state.mouseState);
  const viewState = systemStore((state) => state.viewState);
  const layersPickable = controlStore((state) => state.layersPickable);

  const timeRange = useMemo(() => getTimeRange(data), [data]);
  const res = useLoadByREST(queryOptions);

  useEffect(() => {
    const [x, y] = [mouseState.x, mouseState.y];
    // const [x, y] = [mouseState.screenX, mouseState.screenY];
    // const [x, y] = [mouseState.clientX, mouseState.clientY];
    if (x && y) {
      const viewport = new WebMercatorViewport(viewState); // viewState is retrieved via deck's onViewStateChange prop
      const LatLon = viewport.unproject([x, y]);
    }
  }, [mouseState]);

  useEffect(() => {
    res.data && setData(res.data.records);
  }, [res]);

  useEffect(() => {
    data && setFilterValue(filter || timeRange);
    setLayer(layer);
  }, [filter, toggleBrush, brushRadius]);

  const layer = [
    !toggleBrush &&
      data &&
      filterValue &&
      new ScatterplotLayer({
        ...layerProps,
        id: "transport-collision-slider",
        data: data,
        pickable: layersPickable.transport_roadcollision,
        getFilterValue: parseDateTime,
        filterRange: [filterValue[0], filterValue[1]],
        // filterRange: [filterValue, filterValue+100],
        filterSoftRange: [
          filterValue[0] * 0.8 + filterValue[1] * 0.2,
          filterValue[0] * 0.2 + filterValue[1] * 0.8,
        ],
        extensions: [dataFilter],
      }),
    toggleBrush &&
      data &&
      filterValue &&
      new ScatterplotLayer({
        ...layerProps,
        id: "transport-collision-brushed",
        data: data,
        pickable: layersPickable.transport_roadcollision,

        brushingRadius: brushRadius,
        brushingEnabled: true,
        extensions: [dataBrush],
        brushingTarget: "custom",
        getBrushingTarget: (d) => {
          // console.log(d);
          return [
            parseFloat(d.LOC_LONG),
            // + (d.VITESSE_AUTOR ? 0.001 * (Number(d.VITESSE_AUTOR) - 20) : 0)
            parseFloat(d.LOC_LAT),
          ];
        },
      }),
  ];

  return (
    <div>
      {timeRange && (
        <AnalysisPanel
          toggleBrush={toggleBrush}
          setToggleBrush={setToggleBrush}
          brushRadius={brushRadius}
          setBrushRadius={setBrushRadius}
          brushOptions={BRUSH_OPTIONS}
        />
      )}
      {timeRange && filterValue && (
        <RangeInput
          min={timeRange[0]}
          max={timeRange[1]}
          value={filterValue}
          animationSpeed={playSpeed}
          playSpeed={playSpeed}
          setPlaySpeed={setPlaySpeed}
          formatLabel={formatLabel}
          onChange={setFilter}
          show={!toggleBrush}
        />
      )}
    </div>
  );
}

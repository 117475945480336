import React, { useEffect } from 'react'
import { statsStore, servicesStore } from '../../../store/store'
import styles from './styles.module.less'

export default function InfoPanel() {
  //------------------ SHARED STATES ------------------
  const selectedBuildings = statsStore((state) => state.selectedBuildings)

  console.log('selectedBuildings.target ', selectedBuildings.target)

  //------------------ SIDE EFFECTS ------------------


  return selectedBuildings.target ? (
    <div className={styles.container}>
      <div className={styles.containerBody}>
        <table>
          {Object.keys(selectedBuildings.target).map((key, index) => {
            if (['layerName', 'centroid', 'coords'].includes(key)) return
            const val = Math.round((selectedBuildings.target[key] + Number.EPSILON) * 100) / 100;
            return (
              <tr>
                <td key={`col-1-row-${index}`}>{key}</td>
                <td key={`col-2-row-${index}`}>{val}</td>
              </tr>
            )
          })}
        </table>
      </div>
    </div>
  ) : (
    <h4 className={styles.title}>select a building via clicking</h4>
  )
}

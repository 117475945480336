import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as init from './initials'

//////// TOOLBOX STORE
let _toolboxStore = (set) => ({
  toolboxLayer: init.toolboxLayer,
  setToolboxLayer: (val) => set((state) => ({ toolboxLayer: val })),
  toolboxGeoJson: init.toolboxGeoJson,
  setToolboxGeoJson: (val) => set((state) => ({ toolboxGeoJson: val })),
  toolboxSelectApply: init.toolboxSelectApply,
  setToolboxSelectApply: (val) => set((state) => ({ toolboxSelectApply: val })),
  toolboxShowGraph: init.toolboxShowGraph,
  setToolboxShowGraph: (val) => set((state) => ({ toolboxShowGraph: val })),
  toolboxCalcGraph: init.toolboxCalcGraph,
  setToolboxCalcGraph: (val) => set((state) => ({ toolboxCalcGraph: val })),
})
export const toolboxStore = create(devtools(_toolboxStore))

//////// SERVICES STORE
let _servicesStore = (set) => ({
  retrofitSelectedData: init.retrofitSelectedData,
  setRetrofitSelectedData: (val) => set((state) => ({ retrofitSelectedData: val })),
  selectedBuildings: init.selectedBuildings,
  setSelectedBuildings: (val) => set((state) => ({ selectedBuildings: val })),
})
export const servicesStore = create(devtools(_servicesStore))

//////// DATA STORE
let _dataStore = (set) => ({
  layers: init.layers,
  setLayers: (val) => set((state) => ({ layers: val })),
  index: init.index_val,
  setIndex: (val) => set((state) => ({ index: val })),
  floatingData: init.floatingData,
  setFloatingData: (val) => set((state) => ({ floatingData: val })),
  settingsData: init.settingsData,
  setSettingsData: (val) => set((state) => ({ settingsData: val })),
})
export const dataStore = create(devtools(_dataStore))

//////// CONTROL TOOLS STORE
let _controlStore = (set) => ({
  speed: init.speed,
  setSpeed: (val) => set((state) => ({ speed: val })),
  layersShown: init.layersShown,
  setLayersShown: (layerName, val) =>
    set((state) => ({
      layersShown: { ...state.layersShown, [layerName]: val },
    })),
  layersPickable: init.layersPickable,
  setLayersPickable: (layerName, val) =>
    set((state) => ({
      layersPickable: { ...state.layersPickable, [layerName]: val },
    })),
  hideAll: init.hideAll,
  setHideAll: (val) => set((state) => ({ hideAll: val })),
})
export const controlStore = create(devtools(_controlStore))

//////// STATS STORE
let _statsStore = (set) => ({
  count: init.count,
  setCount: (val) => set((state) => ({ count: val })),
  // setCount: (agentType, val) => set((state) => ({count: { ...state.count, [agentType]: val }})),
  color: init.color,
  setColor: (agentType, val) => set((state) => ({ color: { ...state.color, [agentType]: val } })),
  isShown: init.isShown,
  setIsShown: (agentType, val) =>
    set((state) => (val ? state.isShown.add(agentType) : state.isShown.delete(agentType))),
  selectedAgents: init.selectedAgents,
  setSelectedAgents: (agentName, val) =>
    set((state) => (val ? state.selectedAgents.add(agentName) : state.selectedAgents.delete(agentName))),
  hoveredBuildings: init.hoveredBuildings,
  setHoveredBuildings: (val) => set((state) => ({ hoveredBuildings: val })),
  selectedBuildings: init.selectedBuildings,
  setSelectedBuildings: (val) => set((state) => ({ selectedBuildings: val })),
  chartHoveredItems: init.chartHoveredItems,
  setChartHoveredItems: (val) => set((state) => ({ chartHoveredItems: val })),
  size: init.size,
  setSize: (agentType, val) => set((state) => ({ size: { ...state.size, [agentType]: val } })),
})
export const statsStore = create(devtools(_statsStore))

//////// SYSTEM TOOLS STORE
let _systemStore = (set) => ({
  isLoading: init.isLoading,
  setIsLoading: (key, val) => {
    console.log(key, val)
    return set((state) => ({ isLoading: { ...state.isLoading, [key]: val } }))
  },
  viewState: init.viewState,
  setViewState: (obj) => set((state) => ({ viewState: { ...state.viewState, ...obj } })),
  mouseState: init.mouseState,
  setMouseState: (obj) => set((state) => ({ mouseState: obj })),
  bufferSize: init.bufferSize,
  setBufferSize: (val) => set((state) => ({ bufferSize: val })),
  baseMapStyle: init.baseMapStyle,
  setBaseMapStyle: (basemapName) => set((state) => ({ baseMapStyle: basemapName })),
})
export const systemStore = create(devtools(_systemStore))

//////// PANELS & CHARTS STORE
let _panelsStore = (set) => ({
  showServicesPanels: init.showServicesPanels,
  setShowServicesPanels: (showBoolean) => set((state) => ({ showServicesPanels: showBoolean })),
  activePanels: init.activePanels,
  setActivePanels: (key, val) => set((state) => ({ activePanels: { ...state.activePanels, [key]: val } })),
  activeServices: init.activeServices,
  setActiveServices: (key, val) =>
    set((state) => ({
      activeServices: { ...state.activeServices, [key]: val },
    })),
  timeseriesData: init.timeseriesData,
  setEmissionData: (key, index, val) =>
    set((state) => {
      return {
        timeseriesData: {
          ...state.timeseriesData,
          [key]: { index: index, values: val },
        },
      }
    }),
  mainLayers: init.mainLayerOptions,
  setMainLayers: (val) => set((state) => ({ mainLayers: val })),
  customLayers: init.customLayerOptions,
  setCustomLayers: (val) => set((state) => ({ customLayers: val })),
})
export const panelsStore = create(devtools(_panelsStore))
